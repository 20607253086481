import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// colour design tokens
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
      black: "#000",
      white: "#fff",
      grey: {
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
      },
      primary: {
        100: "#cccccc",
        200: "#999999",
        300: "#666666",
        400: "#333333",
        500: "#000000",
        600: "#000000",
        700: "#000000",
        800: "#000000",
        900: "#000000",
      },
      blueAccent: {
        100: "#cee0f3",
        200: "#9dc2e7",
        300: "#6ca3da",
        400: "#3b85ce",
        500: "#0a66c2",
        600: "#08529b",
        700: "#063d74",
        800: "#04294e",
        900: "#021427",
      },
      orangeAccent: {
        100: "#faddcd",
        200: "#f5bb9b",
        300: "#ef9a68",
        400: "#ea7836",
        500: "#e55604",
        600: "#b74503",
        700: "#893402",
        800: "#5c2202",
        900: "#2e1101",
      },
    }
    : {
      black: "#000",
      white: "#fff",
      grey: {
        100: "#141414",
        200: "#292929",
        300: "#3d3d3d",
        400: "#525252",
        500: "#666666",
        600: "#858585",
        700: "#a3a3a3",
        800: "#c2c2c2",
        900: "#e0e0e0",
      },
      primary: {
        100: "#000000",
        200: "#000000",
        300: "#000000",
        400: "#e0e0e0",
        500: "#000000",
        600: "#333333",
        700: "#666666",
        800: "#999999",
        900: "#cccccc",
      },
      blueAccent: {
        100: "#021427",
        200: "#04294e",
        300: "#063d74",
        400: "#08529b",
        500: "#0a66c2",
        600: "#3b85ce",
        700: "#6ca3da",
        800: "#9dc2e7",
        900: "#cee0f3",
      },
      orangeAccent: {
        100: "#2e1101",
        200: "#5c2202",
        300: "#893402",
        400: "#b74503",
        500: "#e55604",
        600: "#ea7836",
        700: "#ef9a68",
        800: "#f5bb9b",
        900: "#faddcd",
      },
    }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    direction: "rtl",
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          primary: {
            main: colors.orangeAccent[500],
          },
          secondary: {
            main: colors.blueAccent[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: colors.primary[500],
          },
        }
        : {
          primary: {
            main: colors.blueAccent[500],
          },
          secondary: {
            main: colors.orangeAccent[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: "#fcfcfc",
          },
        }),
    },
    typography: {
      fontFamily: ["shabnam", "sans-serif"].join(","),
      fontSize: 14,
      h1: {
        fontFamily: ["shabnam", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["shabnam", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["shabnam", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["shabnam", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["shabnam", "sans-serif"].join(","),
        fontSize: 18,
      },
      h6: {
        fontFamily: ["shabnam", "sans-serif"].join(","),
        fontSize: 16,
      },
    },
  };
};

// context for colour mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { },
});

export const useMode = () => {
  const PREFERENCE_COOKIE_NAME = 'theme-preference';
  const themePreference = window.localStorage.getItem(PREFERENCE_COOKIE_NAME);
  const [mode, setMode] = useState(themePreference || "light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => (prev === "light" ? "dark" : "light"));
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(PREFERENCE_COOKIE_NAME, mode === "light" ? "dark" : "light");
        }
      },
    }),
    [mode],
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
