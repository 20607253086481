import React from "react";
import {
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { separateNumbers } from "../../components/PriceFormat";

const InvoiceItems = ({ courses }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return courses?.map(function (course, index) {
        return (
            <React.Fragment key={index}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            دوره
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                دوره
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={course?.course_name ?? ""}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            استاد
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                استاد
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={course?.user?.full_name ?? ""}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            قیمت (تومان)
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                قیمت (تومان)
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={separateNumbers(course?.pivot?.price) ?? ""}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            درصد تخفیف
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                تخفیف
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={course?.pivot?.discount + " %" ?? ""}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            جمع دوره (تومان)
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                جمع دوره (تومان)
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={separateNumbers(course?.pivot?.total) ?? ""}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
            </React.Fragment>
        )
    })
}

export default InvoiceItems
