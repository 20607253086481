import { useNavigate } from "react-router-dom";
import { Typography, Box, useTheme, IconButton, Container } from "@mui/material";
import { tokens } from "../theme";
import { makeStyles } from "@mui/styles";
import { ArrowForwardIos } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  iconButton: {
    "& .MuiIconButton-root": {
      backgroundColor: `${tokens(theme.palette.mode).grey[800]}`,
      marginRight: "10px",
    }
  },
  svgIcon: {
    "&:hover": {
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.light,
      }
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.light,
    },
  },
}));

const Header = ({ title, subtitle, backToPage }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();

  return (
    <Box
      sx={{
        paddingTop: "110px",
        bgcolor: colors.grey[900],
        width: "100vw"
      }}
      className="container-fluid"
    >
      <Container>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="h3"
            color={colors.blueAccent[500]}
            fontWeight="bold"
            sx={{ mb: "10px" }}
            className="scale_animation"
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            color={colors.orangeAccent[500]}
            className="scale_animation"
          >
            {subtitle}
          </Typography>
        </Box>
        <Box className={classes.iconButton}
          sx={{
            paddingTop: "80px",
            paddingBottom: "30px",
          }}
        >
          {backToPage && (
            <>
              <IconButton
                className={classes.svgIcon}
                onClick={() => navigate(backToPage)}
              >
                <ArrowForwardIos />
              </IconButton>
              <Typography
                sx={{
                  display: "inline-block",
                  fontWeight: "700",
                  color: theme.palette.mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500]
                }}
              >
                بازگشت
              </Typography>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
