import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import useServices from "../../utils/useServices";
import { tokens } from "../../theme";
import { useDialogContext } from "../../context/DialogContext";
import UsersContext from "../../context/UsersContext";
import CanContext from "../../context/CanContext";
import SettingsContext from "../../context/SettingsContext";
import AdminUsersMenu from "./AdminUsersMenu";
import ShowAvatar from "../../components/ShowAvatar";
import {
    Avatar,
    Box,
    Card,
    useTheme,
    IconButton,
    Skeleton,
    CardHeader,
    Stack,
    styled,
    Badge,
    CardContent,
    Typography,
} from "@mui/material";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const UsersPost = ({ user }) => {
    const { putMethod } = useServices();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { handleOpenDialog } = useDialogContext();
    const { baseProfileUrl, loading } = useContext(UsersContext);
    const { adminUsers } = useContext(CanContext);
    const { getCounts } = useContext(SettingsContext);

    async function updateIsReadUser() {
        if (user?.id !== undefined && user?.is_read === 0) {
            try {
                await putMethod(
                    "/update_user_count",
                    user?.id,
                );
                getCounts();
            } catch (ex) {

            }
        }
    }

    return (
        <Box className="col-lg-3 col-md-6 my-animation">
            <Fade>
                <Card sx={{ marginBottom: "30px" }}>
                    <CardHeader
                        avatar={
                            loading ? (
                                <Skeleton
                                    animation="wave"
                                    variant="circular"
                                    width={40}
                                    height={40}
                                />
                            ) : (
                                <Stack direction="row" spacing={2}>
                                    <Fade direction="up" triggerOnce={true}>
                                        <IconButton onClick={() => handleOpenDialog(
                                            "xs",
                                            "",
                                            <ShowAvatar avatar={user?.profile} fullName={user?.full_name} />
                                        )}>
                                            <StyledBadge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                                variant={user?.role_id === 1 ? "standard" : "dot"}
                                            >
                                                <Avatar
                                                    sx={{
                                                        bgcolor: theme.palette.mode === "light" ?
                                                            colors.blueAccent[500]
                                                            :
                                                            colors.orangeAccent[500]
                                                    }}
                                                    src={baseProfileUrl + user?.profile}
                                                />
                                            </StyledBadge>
                                        </IconButton>
                                    </Fade>
                                </Stack>
                            )
                        }
                        action={
                            loading ? null : (
                                adminUsers === 1 && (
                                    <AdminUsersMenu user={user} />
                                )
                            )
                        }
                        title={
                            loading ? (
                                <Skeleton variant="text" height={15} width="80%" />
                            ) : (
                                <Fade direction="left" triggerOnce={true}>
                                    {user?.is_read === 0 ? (
                                        <Link to={`/admin-user/show/${user?.id}`}>
                                            <strong onClick={updateIsReadUser}
                                                style={{
                                                    color: theme.palette.mode === "light" ?
                                                        colors.black
                                                        :
                                                        colors.white
                                                }}
                                            >
                                                {user?.full_name}
                                            </strong>
                                        </Link>
                                    ) : (
                                        <Link to={`/admin-user/show/${user?.id}`}>
                                            <span
                                                style={{
                                                    color: theme.palette.mode === "light" ?
                                                        colors.black
                                                        :
                                                        colors.white
                                                }}>
                                                {user?.full_name}
                                            </span>
                                        </Link>
                                    )}
                                </Fade>
                            )
                        }
                        subheader={
                            loading ? (
                                <Skeleton variant="text" height={10} width="40%" />
                            ) : (
                                <Fade direction="left" delay={300} triggerOnce={true}>
                                    {user?.role_id === 1 ? (
                                        <span>آفلاین</span>
                                    ) : (
                                        <span>آنلاین</span>
                                    )}
                                </Fade>
                            )
                        }
                    />
                    {loading ? (
                        <CardContent>
                            <Box>
                                <Skeleton variant="text" height={10} width="100%" />
                            </Box>
                            <Box>
                                <Skeleton variant="text" height={10} width="100%" />
                            </Box>
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Fade direction="left" triggerOnce={true}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography sx={{ fontWeight: 700 }}>
                                        نقش:
                                    </Typography>
                                    <Box>
                                        {user?.roles.map((role, index) => (
                                            index + 1 === user?.roles?.length ? (
                                                <Typography style={{ display: 'inline-block' }} key={index}>
                                                    {role?.role_name}
                                                </Typography>
                                            ) : (
                                                <Typography style={{ display: 'inline-block' }} key={index}>
                                                    {role?.role_name}،&nbsp;
                                                </Typography>
                                            )
                                        ))}
                                    </Box>
                                </Box>
                            </Fade>
                            <Fade direction="right" delay={300} triggerOnce={true}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography sx={{ fontWeight: 700 }}>
                                        نام کاربری:
                                    </Typography>
                                    <Typography>
                                        {user?.username}
                                    </Typography>
                                </Box>
                            </Fade>
                            <Fade direction="left" delay={350} triggerOnce={true}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography sx={{ fontWeight: 700 }}>
                                        حساب کاربری:
                                    </Typography>
                                    <Typography>
                                        {user?.active_account === 0 ? "غیر فعال" : "فعال"}
                                    </Typography>
                                </Box>
                            </Fade>
                            <Fade direction="right" delay={400} triggerOnce={true}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography sx={{ fontWeight: 700 }}>
                                        تعلیق:
                                    </Typography>
                                    <Typography>
                                        {user?.is_suspended === 0 ? "غیر فعال" : "فعال"}
                                    </Typography>
                                </Box>
                            </Fade>
                            <Fade direction="left" delay={450} triggerOnce={true}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography sx={{ fontWeight: 700 }}>
                                        مسدود:
                                    </Typography>
                                    <Typography>
                                        {user?.is_blocked === 0 ? "غیر فعال" : "فعال"}
                                    </Typography>
                                </Box>
                            </Fade>
                        </CardContent>
                    )}
                </Card>
            </Fade>
        </Box>
    )
}

export default UsersPost
