import Header from '../../components/Header';
import { Box } from "@mui/material";
import TransactionsTable from "./TransactionsTable";

const AdminTransactions = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل تراکنش ها" subtitle="مدیریت پنل" backToPage="/" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <TransactionsTable />
      </Box>
    </>
  );
};

export default AdminTransactions;
