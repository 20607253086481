import { useState } from "react";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
  Box,
  useTheme
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SendOutlined, FormatColorText, AttachFile, InsertLink, EmojiEmotionsOutlined } from "@mui/icons-material";
import EmojiPicker, { Categories } from "emoji-picker-react"; // Emoji
import ToolBox from "../../components/Toolbox";
import AutocompleteSearch from "../../components/AutocompleteSearch";
import useServices from "../../utils/useServices";

const Compose = (props) => {
  const { handleOpenSnackbar } = useSnackbarContext();
  const { postMethod } = useServices();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [to, setTo] = useState("");
  const [toError, setToError] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [inboxMessage, setInboxMessage] = useState("");
  const [inboxMessageError, setInboxMessageError] = useState("");
  const [isPickerVisible, setPickerVisible] = useState(false);
  const [isToolBoxVisible, setToolBoxVisible] = useState(false);
  // const [emoji, setEmoji] = useState(null);
  const [searchUserArray, setSearchUserArray] = useState([]);

  const addEmoji = (e) => {
    setInboxMessage(prevInput => prevInput + e.emoji);
    setPickerVisible(false);
  }

  const handleStore = async () => {
    setLoadingStoreButton(false);

    if (searchUserArray.length === 0 || to.length === 0) {
      setToError("الزامی است To تکمیل گزینه");
    } else {
      try {
        const obj = {
          to: to,
          subject: subject,
          message: inboxMessage,
        };
        await postMethod("/inbox", obj);
        handleOpenSnackbar(
          "bottom",
          "left",
          "success",
          "ایمیل شما با موفقیت ارسال شد"
        );
        handleResetItems();
      } catch (ex) {
        if (ex.response && ex.response.status === 422) {
          setToError(ex.response.data.errors.to);
          setSubjectError(ex.response.data.errors.subject);
          setInboxMessageError(ex.response.data.errors.message);
        }
      }
    }

    setLoadingStoreButton(true);
  };

  const handleResetItems = () => {
    props?.setOpenDialog(false);
    setSearchUserArray([]);
    setTo("");
    setToError("");
    setSubject("");
    setSubjectError("");
    setInboxMessage("");
    setInboxMessageError("");
  };

  const handleToChange = async (e) => {
    const currentValue = e.target.value;
    if (!preventLetters(currentValue)) {
      setToError("موبایل گیرنده را وارد کنید");
    } else {
      if (currentValue?.length === 0) {
        setToError("الزامی است To تکمیل گزینه");
      } else {
        setToError("");
        if (currentValue?.length === 11) {
          const obj = {
            to: currentValue,
          };
          const { data: findUser } = await postMethod("/user/search", obj);

          if (Object.keys(findUser).length > 0) {
            const array = [];
            array.push({ id: findUser.id, tag_name: findUser.username, full_name: findUser.full_name });
            setSearchUserArray(array);
          }
        }
        else {
          setSearchUserArray([]);
        }
      }
    }
  };

  const preventLetters = (to) => {
    const toRegex = /^[0-9]*$/;
    return toRegex.test(to);
  };

  const handleSubjectChange = (e) => {
    const currentValue = e.target.value;
    setSubject(currentValue);
  };

  const handleInboxMessageChange = (e) => {
    const currentValue = e.target.value;
    setInboxMessage(currentValue);
    if (currentValue?.length === 0) {
      setInboxMessageError("تکمیل گزینه پیام الزامی است");
    } else {
      setInboxMessageError("");
    }
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <AutocompleteSearch
            options={searchUserArray}
            freeSolo={true} // باعث می شود "کاربری یافت نشد" را نمایش ندهد
            noOptionsText="کاربری یافت نشد"
            forcePopupIcon={false}
            variant="standard"
            placeholder="To"
            value={to || []}
            setValue={setTo}
            onChange={handleToChange}
            onFocus={() => setPickerVisible(false)}
            required={true}
            error={Boolean(toError)}
            helperText={
              toError
            }
          />
          <TextField
            fullWidth
            variant="standard"
            type="text"
            value={subject ?? ""}
            onChange={handleSubjectChange}
            onFocus={() => setPickerVisible(false)}
            autoComplete="off"
            placeholder="موضوع"
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 200 }}
            error={Boolean(subjectError)}
            helperText={subjectError ? subjectError : `${subject.length}/200`}
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={inboxMessage ?? ""}
            onChange={handleInboxMessageChange}
            onFocus={() => setPickerVisible(false)}
            autoComplete="off"
            multiline
            rows={8}
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 600 }}
            error={Boolean(inboxMessageError)}
            helperText={inboxMessageError ? inboxMessageError : `${inboxMessage.length}/600`}
          />
        </Stack>
      </DialogContent>
      <Box className={isToolBoxVisible ? "d-block" : "d-none"}>
        <ToolBox />
      </Box>
      {/* html */}
      {/* <h1>&#x1f383;</h1> */}
      {/* <Emoji size={30} unified={emoji && emoji.unified} /> */}
      {/* {emoji && emoji.emoji} */}
      {/* {emoji && <a href={emoji.getImageUrl()}>delicious</a>} */}
      <Box position="fixed" bottom="95px"
        className={isPickerVisible ? "d-block" : "d-none"}
      >
        <EmojiPicker
          searchPlaceHolder="جستجو ..."
          searchDisabled={false}
          lazyLoadEmojis={false}
          theme={mode}
          emojiStyle="apple" // apple google facebook twitter native
          previewConfig={{
            showPreview: true,
            // defaultEmoji: "2764-fe0f",
            // defaultCaption: "Heart"
          }}
          onEmojiClick={addEmoji}
          categories={[
            {
              name: "آدمک ها",
              category: Categories.SMILEYS_PEOPLE
            },
            {
              name: "فعالیت ها",
              category: Categories.ACTIVITIES
            },
          ]}
        />
      </Box>
      <DialogActions>
        <IconButton size="small" onClick={() => setPickerVisible(!isPickerVisible)}>
          <EmojiEmotionsOutlined
            fontSize="small"
          />
        </IconButton>
        <IconButton size="small">
          <InsertLink fontSize="small" />
        </IconButton>
        <IconButton size="small">
          <AttachFile fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={() => setToolBoxVisible(!isToolBoxVisible)}>
          <FormatColorText fontSize="small" />
        </IconButton>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingStoreButton}
          disabled={
            to.length === 0 ||
            toError.length > 0 ||
            searchUserArray.length === 0 ||
            !inboxMessage
          }
          startIcon={<SendOutlined />}
          onClick={handleStore}
        >
          ارسال
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default Compose;
