import { useContext, useEffect, useState } from "react";
import { useDialogContext } from "../../context/DialogContext";
import PrivacyContext from "../../context/PrivacyContext";
import { Button, Stack } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import { Add, Edit, Delete } from "@mui/icons-material";
import CreatePrivacy from "./CreatePrivacy";
import EditPrivacy from "./EditPrivacy";
import DeletePrivacy from "./DeletePrivacy";

const PrivacyTable = () => {
    const { handleOpenDialog } = useDialogContext();
    const [selectedRow, setSelectedRow] = useState("");
    const {
        getPrivacy,
        privacies,
        loading,
        page, setPage,
        rows, setRows,
        from,
        to,
        total,
    } = useContext(PrivacyContext);

    const columns = [
        {
            field: "id",
            headerName: "ردیف",
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "title",
            headerName: "عنوان",
            flex: 1,
        },
        {
            field: "law",
            headerName: "قانون",
            flex: 1,
        },
    ];

    useEffect(() => {
        getPrivacy();
        // eslint-disable-next-line
    }, [rows, total, page]);

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "افزودن قانون",
                            <CreatePrivacy
                                getPrivacy={getPrivacy}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Add />}
                >
                    افزودن
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "ویرایش قانون",
                            <EditPrivacy
                                params={selectedRow}
                                getPrivacy={getPrivacy}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Edit />}
                    disabled={!selectedRow}
                >
                    ویرایش
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "",
                            <DeletePrivacy
                                params={selectedRow}
                                getPrivacy={getPrivacy}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Delete />}
                    disabled={!selectedRow}
                >
                    حذف
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={privacies}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
            />
        </>
    );
};

export default PrivacyTable;
