import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import PropTypes from 'prop-types';
import { useDialogContext } from "../../context/DialogContext";
import UsersContext from "../../context/UsersContext";
import UserInfo from './UserInfo';
import CoursesPost from "../courses/CoursesPost";
import ShowAvatar from "../../components/ShowAvatar";
import {
    Avatar,
    Box,
    CardMedia,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, minHeight: "450px" }}>
                    <Box sx={{ textAlign: "justify" }}>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ShowUserInfo = () => {
    const { id: userId } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [value, setValue] = useState(0);
    const { handleOpenDialog } = useDialogContext();
    const {
        baseProfileUrl,
        baseInstructorUrl,
        getUserById,
        userLoading,
        user,
        userCourses,
        isInstructor
    } = useContext(UsersContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getUserById(userId);
        // eslint-disable-next-line
    }, [userId]);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Fade direction="up" triggerOnce={true}>
                    {userLoading ? (
                        <Box display="flex" justifyContent="center">
                            <Skeleton
                                sx={{ width: "200px", height: "200px" }}
                                animation="wave"
                                variant="circular"
                            />
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="center">
                            <IconButton onClick={() => handleOpenDialog(
                                "xs",
                                "",
                                <ShowAvatar avatar={user?.profile} fullName={user?.full_name} />
                            )}>
                                <Avatar
                                    sx={{ width: "200px", height: "200px" }}
                                    src={baseProfileUrl + user?.profile}
                                />
                            </IconButton>
                        </Box>
                    )}
                </Fade>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                <Box sx={{ margin: isMobile ? "20px 0 0 30px" : "0 0 0 30px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="scrollable force tabs example">
                            <Tab label="مشخصات" {...a11yProps(0)} />
                            <Tab label="زندگی نامه" {...a11yProps(1)} />
                            {isInstructor &&
                                <Tab label="تصویر استاد" {...a11yProps(2)} />
                            }
                            {isInstructor &&
                                <Tab label="لیست دوره های استاد" {...a11yProps(3)} />
                            }
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Stack direction="column" spacing={2}>
                            <UserInfo title="نام و نام خانوادگی:" value={user?.full_name} />
                            {user?.roles?.map((role, index) => (
                                <UserInfo key={index} title={`نقش ${index + 1}:`} value={role?.role_name} />
                            ))}
                            <UserInfo title="استان:" value={user?.province?.province_name} />
                            <UserInfo title="شهرستان:" value={user?.city?.city_name} />
                            <UserInfo title="آدرس:" value={user?.address} />
                            <UserInfo title="کد پستی:" value={user?.zip_code} />
                            <UserInfo title="نام کاربری:" value={user?.username} />
                        </Stack>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Fade direction="up" triggerOnce={true}>
                            {user?.biography ? (
                                <Typography>{user?.biography}</Typography>
                            ) : (
                                <Skeleton variant="text" height={20} width="100%" />
                            )}
                        </Fade>
                    </CustomTabPanel>
                    {isInstructor &&
                        <>
                            <CustomTabPanel value={value} index={2}>
                                <Fade triggerOnce={true}>
                                    {user?.cover ? (
                                        <CardMedia
                                            component="img"
                                            image={baseInstructorUrl + user?.cover}
                                            alt={user?.full_name}
                                            sx={{ width: 400, height: 400 }}
                                        />
                                    ) : (
                                        <Skeleton
                                            sx={{ width: 400, height: 400 }}
                                            animation="wave"
                                            variant="rectangular"
                                        />
                                    )}
                                </Fade>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <Fade triggerOnce={true}>
                                    <Box sx={{ marginTop: "30px" }}>
                                        <Grid container spacing={4}>
                                            {userCourses?.map((course, index) => (
                                                <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <CoursesPost course={course} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Fade>
                            </CustomTabPanel>
                        </>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default ShowUserInfo
