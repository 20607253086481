import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import SeasonContext from "../../context/SeasonContext";
import SeasonPost from "./SeasonPost";
import { Box, Grid } from "@mui/material";
import CreateSeasons from "./CreateSeasons";

const AdminSeasons = () => {
  const { id: courseId } = useParams();
  const {
    getSeasonsByCourseId,
    seasons,
  } = useContext(SeasonContext);
  const sentence = `/course/show/${courseId}`;

  useEffect(() => {
    getSeasonsByCourseId(courseId);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل مدیریت فصل ها" subtitle="مدیریت پنل" backToPage={sentence} />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <CreateSeasons />
        <Grid container spacing={2}>
          {seasons?.map((season, index) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={3} xl={3}>
              <SeasonPost season={season} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default AdminSeasons;
