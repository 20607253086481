import { useState } from "react";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import useServices from "../../utils/useServices";

const CreateEpisode = ({ season }) => {
  const { postMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [episodeName, setEpisodeName] = useState("");
  const [episodeNameError, setEpisodeNameError] = useState("");

  const handleEpisodeNameChange = (e) => {
    const currentValue = e.target.value;
    setEpisodeName(currentValue);
    if (currentValue?.length === 0) {
      setEpisodeNameError("تکمیل گزینه نام بخش الزامی است");
    } else {
      setEpisodeNameError("");
    }
  };

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        season_id: season?.id,
        episode_name: episodeName,
      };

      await postMethod("/episodes", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "بخش با موفقیت ثبت شد"
      );

      setEpisodeName("");
      setEpisodeNameError("");

    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          "رکورد مورد نظر قبلاً حذف شده است"
        );
      }
      if (ex.response && ex.response.status === 422) {
        setEpisodeNameError(ex.response.data.errors.episode_name);
      }
    }

    setLoadingStoreButton(true);
  };

  const handleResetForm = () => {
    setEpisodeName("");
    setEpisodeNameError("");
    handleCloseDialog();
  }

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={episodeName ?? ""}
            onChange={handleEpisodeNameChange}
            autoComplete="off"
            label="نام بخش"
            required
            inputProps={{ maxLength: 100 }}
            error={Boolean(episodeNameError)}
            helperText={episodeNameError}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleResetForm}
        >
          انصراف
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingStoreButton}
          disabled={!episodeName}
          startIcon={<SaveOutlined />}
          onClick={handleStore}
        >
          ذخیره
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreateEpisode;
