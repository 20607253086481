import Header from '../../components/Header';
import { Box } from "@mui/material";
import InstructorsInvoicesTable from "./InstructorsInvoicesTable";

const AdminInstructorsInvoices = () => {
    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="فاکتورهای استاد" subtitle="پنل تراکنش ها" backToPage="/" />
            </Box>
            <Box mt="50px" mb="50px" className="container">
                <InstructorsInvoicesTable />
            </Box>
        </>
    );
};

export default AdminInstructorsInvoices;