import Header from "../../components/Header";
import { Box } from "@mui/material";
import InvoicesTable from "./InvoicesTable";

const Invoices = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="فاکتورهای مشتری" subtitle="کتابخانه من" backToPage="/" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <InvoicesTable />
      </Box>
    </>
  );
};

export default Invoices;
