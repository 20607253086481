import Header from '../../components/Header';
import { Box } from "@mui/material";
import FaqTable from "./FaqTable";

const AdminFaq = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل سوالات متداول" subtitle="مدیریت پنل" backToPage="/faq" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <FaqTable />
      </Box>
    </>
  );
};

export default AdminFaq;
