import React, { useState } from "react";
import { tokens } from "../../theme";
import { useDialogContext } from "../../context/DialogContext";
import {
    Box,
    IconButton,
    useTheme,
    Menu,
    MenuItem,
    Tooltip,
    ListItemIcon,
} from "@mui/material";
import { MoreVert, Delete } from "@mui/icons-material";
import DeleteAnswerBlog from "./DeleteAnswerBlog";

const AdminAnswerBlogMenu = (props) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const { handleOpenDialog } = useDialogContext();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="مدیریت پاسخ">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'answer-blog-settings-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVert sx={{ color: mode === "light" ? colors.black : colors.white }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="answer-blog-settings-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem onClick={() =>
                    handleOpenDialog(
                        "sm", // xs sm md lg xl
                        "حذف",
                        <DeleteAnswerBlog
                            params={props?.answerBlog}
                        />
                    )
                }>
                    <ListItemIcon>
                        <Delete fontSize="medium"
                            sx={{
                                color: theme.palette.mode === "light" ?
                                    colors.blueAccent[500]
                                    :
                                    colors.orangeAccent[500]
                            }} />
                    </ListItemIcon>
                    حذف
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default AdminAnswerBlogMenu;
