import React, { useEffect, useState } from "react";
import useServices from "../../utils/useServices";
import { accessibility } from "./../../config/Accessibility";
import { useDialogContext } from "../../context/DialogContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";

const EditRoles = (props) => {
  const { putMethod } = useServices();
  const { handleCloseDialog } = useDialogContext();
  const { handleOpenSnackbar } = useSnackbarContext();
  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [roleName, setRoleName] = useState("");
  const [roleNameError, setRoleNameError] = useState("");
  const [roleKey, setRoleKey] = useState("");
  const [roleKeyError, setRoleKeyError] = useState("");
  const [selectedAll, setSelectedAll] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [adminUsers, setAdminUsers] = useState(false);
  const [adminEvents, setAdminEvents] = useState(false);
  const [adminInstructors, setAdminInstructors] = useState(false);
  const [adminGallery, setAdminGallery] = useState(false);
  const [adminFaq, setAdminFaq] = useState(false);
  const [adminBlog, setAdminBlog] = useState(false);
  const [adminAnswerBlog, setAdminAnswerBlog] = useState(false);
  const [adminTags, setAdminTags] = useState(false);
  const [adminContact, setAdminContact] = useState(false);
  const [adminPrivacy, setAdminPrivacy] = useState(false);
  const [adminCategories, setAdminCategories] = useState(false);
  const [adminCourses, setAdminCourses] = useState(false);
  const [adminTransactions, setAdminTransactions] = useState(false);
  const [adminUserBankAccounts, setAdminUserBankAccounts] = useState(false);
  const [adminReceipt, setAdminReceipt] = useState(false);
  const [adminInstructorsInvoices, setAdminInstructorsInvoices] = useState(false);
  const [adminSettings, setAdminSettings] = useState(false);
  const [adminRoles, setAdminRoles] = useState(false);

  useEffect(() => {
    fillItems();
    // eslint-disable-next-line
  }, [props?.params]);

  function fillItems() {
    setRoleName(props?.params?.role_name);
    setRoleKey(props?.params?.role_key);
    setAdmin(props?.params?.admin === 0 ? false : true);
    setAdminUsers(props?.params?.admin_users === 0 ? false : true);
    setAdminEvents(props?.params?.admin_events === 0 ? false : true);
    setAdminInstructors(props?.params?.admin_instructors === 0 ? false : true);
    setAdminGallery(props?.params?.admin_gallery === 0 ? false : true);
    setAdminFaq(props?.params?.admin_faq === 0 ? false : true);
    setAdminBlog(props?.params?.admin_blog === 0 ? false : true);
    setAdminAnswerBlog(props?.params?.admin_answer_blog === 0 ? false : true);
    setAdminTags(props?.params?.admin_tags === 0 ? false : true);
    setAdminContact(props?.params?.admin_contact === 0 ? false : true);
    setAdminPrivacy(props?.params?.admin_privacy === 0 ? false : true);
    setAdminCategories(props?.params?.admin_categories === 0 ? false : true);
    setAdminCourses(props?.params?.admin_courses === 0 ? false : true);
    setAdminTransactions(props?.params?.admin_transactions === 0 ? false : true);
    setAdminUserBankAccounts(props?.params?.admin_user_bank_accounts === 0 ? false : true);
    setAdminReceipt(props?.params?.admin_receipt === 0 ? false : true);
    setAdminInstructorsInvoices(props?.params?.admin_instructors_invoices === 0 ? false : true);
    setAdminSettings(props?.params?.admin_settings === 0 ? false : true);
    setAdminRoles(props?.params?.admin_roles === 0 ? false : true);
  }

  useEffect(() => {
    checkedAllInvestigation();
    // eslint-disable-next-line
  }, [
    admin,
    adminUsers,
    adminEvents,
    adminInstructors,
    adminGallery,
    adminFaq,
    adminBlog,
    adminAnswerBlog,
    adminTags,
    adminContact,
    adminPrivacy,
    adminCategories,
    adminCourses,
    adminTransactions,
    adminUserBankAccounts,
    adminReceipt,
    adminInstructorsInvoices,
    adminSettings,
    adminRoles,
  ]);

  function checkedAllInvestigation() {
    if (
      admin &&
      adminUsers &&
      adminEvents &&
      adminInstructors &&
      adminGallery &&
      adminFaq &&
      adminBlog &&
      adminAnswerBlog &&
      adminTags &&
      adminContact &&
      adminPrivacy &&
      adminCategories &&
      adminCourses &&
      adminTransactions &&
      adminUserBankAccounts &&
      adminReceipt &&
      adminInstructorsInvoices &&
      adminSettings &&
      adminRoles
    ) {
      setSelectedAll(true);
    } else {
      setSelectedAll(false);
    }
  }

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    try {
      const obj = {
        role_name: roleName,
        role_key: roleKey,
        admin: admin === true ? 1 : 0,
        admin_users: adminUsers === true ? 1 : 0,
        admin_events: adminEvents === true ? 1 : 0,
        admin_instructors: adminInstructors === true ? 1 : 0,
        admin_gallery: adminGallery === true ? 1 : 0,
        admin_faq: adminFaq === true ? 1 : 0,
        admin_blog: adminBlog === true ? 1 : 0,
        admin_answer_blog: adminAnswerBlog === true ? 1 : 0,
        admin_tags: adminTags === true ? 1 : 0,
        admin_contact: adminContact === true ? 1 : 0,
        admin_privacy: adminPrivacy === true ? 1 : 0,
        admin_categories: adminCategories === true ? 1 : 0,
        admin_courses: adminCourses === true ? 1 : 0,
        admin_transactions: adminTransactions === true ? 1 : 0,
        admin_user_bank_accounts: adminUserBankAccounts === true ? 1 : 0,
        admin_receipt: adminReceipt === true ? 1 : 0,
        admin_instructors_invoices: adminInstructorsInvoices === true ? 1 : 0,
        admin_settings: adminSettings === true ? 1 : 0,
        admin_roles: adminRoles === true ? 1 : 0,
      };

      await putMethod(
        "/roles",
        props?.params.id,
        obj
      );

      props?.getRoles();
      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "نقش و سطح دسترسی با موفقیت ویرایش شد"
      );
      handleCloseDialog();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          "رکورد مورد نظر قبلاً حذف شده است"
        );
      }
      if (ex.response && ex.response.status === 422) {
        setRoleNameError(ex.response.data.errors.role_name);
        setRoleKeyError(ex.response.data.errors.role_key);
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleRoleNameChange = (e) => {
    const currentValue = e.target.value;
    setRoleName(currentValue);
    if (currentValue?.length === 0) {
      setRoleNameError("تکمیل گزینه نام نقش الزامی است");
    } else {
      setRoleNameError("");
    }
  };

  const handleRoleKeyChange = (e) => {
    const currentValue = e.target.value;
    setRoleKey(currentValue);
    if (currentValue?.length === 0) {
      setRoleKeyError("تکمیل گزینه شناسه نقش الزامی است");
    } else {
      setRoleKeyError("");
    }
  };

  const handleChange = (id) => {
    switch (id) {
      case 0: {
        setSelectedAll(!selectedAll);
        setAdmin(!selectedAll);
        setAdminUsers(!selectedAll);
        setAdminEvents(!selectedAll);
        setAdminInstructors(!selectedAll);
        setAdminGallery(!selectedAll);
        setAdminFaq(!selectedAll);
        setAdminBlog(!selectedAll);
        setAdminAnswerBlog(!selectedAll);
        setAdminTags(!selectedAll);
        setAdminContact(!selectedAll);
        setAdminPrivacy(!selectedAll);
        setAdminCategories(!selectedAll);
        setAdminCourses(!selectedAll);
        setAdminTransactions(!selectedAll);
        setAdminUserBankAccounts(!selectedAll);
        setAdminReceipt(!selectedAll);
        setAdminInstructorsInvoices(!selectedAll);
        setAdminSettings(!selectedAll);
        setAdminRoles(!selectedAll);
        break;
      }
      case 1: {
        setAdmin(!admin);
        break;
      }
      case 2: {
        setAdminUsers(!adminUsers);
        break;
      }
      case 3: {
        setAdminEvents(!adminEvents);
        break;
      }
      case 4: {
        setAdminInstructors(!adminInstructors);
        break;
      }
      case 5: {
        setAdminGallery(!adminGallery);
        break;
      }
      case 6: {
        setAdminFaq(!adminFaq);
        break;
      }
      case 7: {
        setAdminBlog(!adminBlog);
        break;
      }
      case 8: {
        setAdminAnswerBlog(!adminAnswerBlog);
        break;
      }
      case 9: {
        setAdminTags(!adminTags);
        break;
      }
      case 10: {
        setAdminContact(!adminContact);
        break;
      }
      case 11: {
        setAdminPrivacy(!adminPrivacy);
        break;
      }
      case 12: {
        setAdminCategories(!adminCategories);
        break;
      }
      case 13: {
        setAdminCourses(!adminCourses);
        break;
      }
      case 14: {
        setAdminTransactions(!adminTransactions);
        break;
      }
      case 15: {
        setAdminUserBankAccounts(!adminUserBankAccounts);
        break;
      }
      case 16: {
        setAdminReceipt(!adminReceipt);
        break;
      }
      case 17: {
        setAdminInstructorsInvoices(!adminInstructorsInvoices);
        break;
      }
      case 18: {
        setAdminSettings(!adminSettings);
        break;
      }
      case 19: {
        setAdminRoles(!adminRoles);
        break;
      }
      default: {
        console.log("default");
      }
    }
  };

  const handleChecked = (id) => {
    switch (id) {
      case 0: {
        return (selectedAll);
      }
      case 1: {
        return (admin);
      }
      case 2: {
        return (adminUsers);
      }
      case 3: {
        return (adminEvents);
      }
      case 4: {
        return (adminInstructors);
      }
      case 5: {
        return (adminGallery);
      }
      case 6: {
        return (adminFaq);
      }
      case 7: {
        return (adminBlog);
      }
      case 8: {
        return (adminAnswerBlog);
      }
      case 9: {
        return (adminTags);
      }
      case 10: {
        return (adminContact);
      }
      case 11: {
        return (adminPrivacy);
      }
      case 12: {
        return (adminCategories);
      }
      case 13: {
        return (adminCourses);
      }
      case 14: {
        return (adminTransactions);
      }
      case 15: {
        return (adminUserBankAccounts);
      }
      case 16: {
        return (adminReceipt);
      }
      case 17: {
        return (adminInstructorsInvoices);
      }
      case 18: {
        return (adminSettings);
      }
      case 19: {
        return (adminRoles);
      }
      default: {
        console.log("default");
      }
    }
  };

  return (
    <React.Fragment>
      <DialogContent>
        <Grid container spacing={1} marginTop={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={roleName ?? ""}
              onChange={handleRoleNameChange}
              autoComplete="off"
              label="نام نقش"
              required
              inputProps={{ style: { textAlign: "center" }, maxLength: 100 }}
              error={Boolean(roleNameError)}
              helperText={roleNameError}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={roleKey ?? ""}
              onChange={handleRoleKeyChange}
              autoComplete="off"
              label="شناسه نقش"
              required
              inputProps={{ style: { textAlign: "center" }, maxLength: 100 }}
              error={Boolean(roleKeyError)}
              helperText={roleKeyError}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={handleChecked(0)}
                  onChange={() => handleChange(0)}
                />
              }
              label="انتخاب همه"
              labelPlacement="end"
            />
          </Grid>

          {/* {props?.pages?.map((parent, index1) => (
            parent?.path === "admin" && (
              <React.Fragment key={index1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={handleChecked(parent?.id)}
                        onChange={() => handleChange(parent?.id)}
                      />
                    }
                    label={parent?.title}
                    labelPlacement="end"
                  />
                </Grid>
                {parent?.children?.map((child, index2) => (
                  <Grid key={index2} item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          checked={handleChecked(child?.id)}
                          onChange={() => handleChange(child?.id)}
                        />
                      }
                      label={child?.title}
                      labelPlacement="end"
                    />
                  </Grid>
                ))}
              </React.Fragment>
            )
          ))} */}

          {accessibility?.map((access, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={handleChecked(access?.id)}
                      onChange={() => handleChange(access?.id)}
                    />
                  }
                  label={access?.title}
                  labelPlacement="end"
                />
              </Grid>
            </React.Fragment>
          ))}

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleCloseDialog}
        >
          انصراف
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingUpdateButton}
          disabled={!roleName}
          startIcon={<SaveOutlined />}
          onClick={handleUpdate}
        >
          ذخیره
        </LoadingButton>
      </DialogActions>
    </React.Fragment>
  );
};

export default EditRoles;
