import { useState, useContext } from "react";
import { Fade } from "react-awesome-reveal";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import CategoryContext from "../../context/CategoryContext";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    TextField
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";

const CreateCategories = () => {
    const { handleOpenSnackbar } = useSnackbarContext();
    const { getCategories } = useContext(CategoryContext);
    const { postMethod } = useServices();
    const [loadingStoreButton, setLoadingStoreButton] = useState(true);
    const [categoryName, setCategoryName] = useState("");
    const [categoryNameError, setCategoryNameError] = useState("");

    const handleStore = async () => {
        setLoadingStoreButton(false);

        try {
            const obj = {
                category_name: categoryName,
            };
            await postMethod("/categories", obj);

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                "دسته بندی دوره ها با موفقیت ثبت شد"
            );

            getCategories();

        } catch (ex) {
            if (ex.response && ex.response.status === 422) {
                setCategoryNameError(ex.response.data.errors.category_name);
            }
        }

        setLoadingStoreButton(true);
    };

    const handleCategoryNameChange = (e) => {
        const currentValue = e.target.value;
        setCategoryName(currentValue);
        if (currentValue?.length === 0) {
            setCategoryNameError("تکمیل گزینه نام دسته بندی الزامی است");
        } else {
            setCategoryNameError("");
        }
    };

    return (
        <Box mb="50px" mt="50px" className="container">
            <Fade triggerOnce={true}>
                <Card>
                    <CardHeader title="افزودن دسته بندی" />
                    <CardContent>
                        <Grid container rowSpacing={2} columnSpacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    value={categoryName ?? ""}
                                    onChange={handleCategoryNameChange}
                                    autoComplete="off"
                                    label="نام دسته بندی"
                                    required
                                    inputProps={{ maxLength: 40 }}
                                    error={Boolean(categoryNameError)}
                                    helperText={
                                        categoryNameError
                                            ? categoryNameError
                                            : `${categoryName.length}/40`
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <LoadingButton
                                    fullWidth
                                    variant="outlined"
                                    loadingPosition="start"
                                    loading={!loadingStoreButton}
                                    disabled={!categoryName}
                                    startIcon={<SaveOutlined />}
                                    onClick={handleStore}
                                    sx={{ height: "56px" }}
                                >
                                    ذخیره
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Fade>
        </Box>
    )
}

export default CreateCategories
