import React, { useContext } from "react";
import { Fade } from "react-awesome-reveal";
import CategoryContext from "../../context/CategoryContext";
import AdminCategoriesMenu from "./AdminCategoriesMenu";
import {
    Box,
    Card,
    Skeleton,
    CardHeader,
    CardContent,
    Typography,
    CardActionArea,
} from "@mui/material";

const CategoryPost = ({ category }) => {
    const { loading } = useContext(CategoryContext);

    return (
        <Box className="my-animation">
            <Card>
                {loading ? (
                    <>
                        <CardHeader />
                        <CardContent>
                            <Skeleton variant="text" width="200px" height="20px" />
                        </CardContent>
                    </>
                ) : (
                    <>
                        <CardHeader
                            action={
                                loading ? null : (
                                    <AdminCategoriesMenu category={category} />
                                )
                            }
                        />
                        <CardActionArea>
                            <CardContent>
                                <Fade direction="up" triggerOnce={true}>
                                    {category ? (
                                        <Typography sx={{ textAlign: "justify" }}>{category.category_name}</Typography>
                                    ) : (
                                        <Skeleton variant="text" height={20} width="50%" />
                                    )}
                                </Fade>
                            </CardContent>
                        </CardActionArea>
                    </>
                )}
            </Card>
        </Box>
    );
};

export default CategoryPost;
