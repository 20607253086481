import Header from "../../components/Header";
import { Box } from "@mui/material";
import ChangePasswordPost from "./ChangePasswordPost";

const ChangePassword = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="تغییر رمز عبور" subtitle="تنظیمات کاربری" backToPage="" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <ChangePasswordPost />
      </Box>
    </>
  );
};

export default ChangePassword;
