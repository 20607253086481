import Header from '../../components/Header';
import CreateEvents from './CreateEvents';
import { Box } from "@mui/material";

const AdminEvents = () => {

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل رویدادها" subtitle="مدیریت پنل" backToPage="/events" />
      </Box>
      <CreateEvents />
    </>
  );
};

export default AdminEvents;
