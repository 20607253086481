import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import RoleContext from "../../context/RoleContext";
import SettingsContext from "../../context/SettingsContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
    Container,
    IconButton,
    Stack,
    InputAdornment,
    Avatar,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    useTheme,
    FormControl,
    InputLabel,
    OutlinedInput,
    MenuItem,
    Select,
    CircularProgress,
    FormHelperText,
} from "@mui/material";
import { Call, LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { tokens } from "../../theme";
import LoadingButton from "@mui/lab/LoadingButton";
import { persian2english } from "../../components/ConvertDigits";

const boxStyle = (theme) => ({
    width: {
        xs: '90%',   // در اندازه موبایل (xs)، عرض 90 درصد
        sm: '70%',   // در اندازه‌های کوچک (sm)، عرض 70 درصد
        md: '50%',   // در اندازه‌های متوسط (md)، عرض 50 درصد
        lg: '35%',   // در اندازه‌های بزرگ (lg)، عرض 35 درصد
        xl: '35%',   // در اندازه‌های بسیار بزرگ (xl)، عرض 35 درصد
    },
    maxWidth: 'sm', // می‌توانید این مقدار را بر اساس نیاز تنظیم کنید
    padding: '20px',
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    background: theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.8)",
    backdropFilter: theme.palette.mode === "light" ? "blur(5px)" : "blur(3px)",
    border: theme.palette.mode === "light" ? "1px solid rgba(0, 0, 0, 0.2)" : "1px solid rgba(255, 255, 255, 0.8)",
});

const Login = () => {
    const { baseImageUrl, settings } = useContext(SettingsContext);
    const { login } = useContext(AuthContext);
    const { handleOpenSnackbar } = useSnackbarContext();
    const {
        getAllRoles,
        allRoles,
        allRoleStatus,
        allRolesLoading
    } = useContext(RoleContext);
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const navigate = useNavigate();
    const isMounted = useRef(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [remember, setRemember] = useState(JSON.parse(localStorage.getItem("remember")) ?? false); // JSON.parse important
    const [username, setUsername] = useState(localStorage.getItem("username") ?? "");
    const [usernameError, setUsernameError] = useState("");
    const [password, setPassword] = useState(localStorage.getItem("password") ?? "");
    const [passwordError, setPasswordError] = useState("");
    const [loadingLoginButton, setLoadingLoginButton] = useState(true);
    const [role, setRole] = useState(localStorage.getItem("role") ?? 2);
    const [roleError, setRoleError] = useState("");

    useEffect(() => {
        if (!isMounted.current) {
            getAllRoles();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    const handleLogin = async () => {
        setLoadingLoginButton(false);

        try {
            await login(username, password, role);
            if (remember === true) {
                localStorage.setItem("remember", true);
                localStorage.setItem("username", username);
                localStorage.setItem("password", password);
                localStorage.setItem("role", role);
            } else {
                localStorage.removeItem("remember");
                localStorage.removeItem("username");
                localStorage.removeItem("password");
                localStorage.removeItem("role");
            }
        } catch (ex) {
            if (ex.response && ex.response?.status === 422) {
                if (ex.response.data.errors.username &&
                    ex.response.data.errors.username?.length > 1) {
                    setUsernameError(ex.response.data.errors.username[0]);
                } else {
                    setUsernameError(ex.response.data.errors.username);
                }
                setPasswordError(ex.response.data.errors.password);
                setRoleError(ex.response.data.errors.role_id);
            }
            if (ex.response?.data?.errors?.popup) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    ex.response.data.errors.popup[0]
                );
            }
        }

        setLoadingLoginButton(true);
    };

    const handleUsernameChange = (e) => {
        const currentValue = e.target.value;
        setUsername(persian2english(currentValue));
        if (currentValue?.length === 0) {
            setUsernameError("تکمیل گزینه موبایل الزامی است");
        } else {
            setUsernameError("");
        }
    };

    const handlePasswordChange = (e) => {
        const currentValue = e.target.value;
        setPassword(persian2english(currentValue));
        if (currentValue?.length === 0) {
            setPasswordError("تکمیل گزینه رمز عبور الزامی است");
        } else {
            setPasswordError("");
        }
    };

    const handleRoleChange = (e) => {
        const currentValue = e.target.value;
        setRole(currentValue);
        if (currentValue?.length === 0) {
            setRoleError("تکمیل گزینه نقش الزامی است");
        } else {
            setRoleError("");
        }
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${baseImageUrl + settings.main_background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={boxStyle} borderRadius="20px">
                <Grid container className="top_to_bottom_animation">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box
                            component="div"
                            style={{
                                marginTop: "20px",
                                margin: "20px",
                            }}
                        >
                            <Container maxWidth="sm">
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Avatar>
                                        <LockOutlined sx={{
                                            color: mode === "light"
                                                ? colors.black
                                                : colors.white,
                                        }} />
                                    </Avatar>
                                </Box>
                                <Box
                                    sx={{
                                        marginTop: "4px",
                                        marginBottom: "35px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography component="h1" variant="h4" sx={{
                                        color: mode === "light"
                                            ? colors.black
                                            : colors.white,
                                    }}>
                                        ورود به حساب کاربری
                                    </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl fullWidth variant="outlined" error={Boolean(usernameError)} required>
                                            <InputLabel htmlFor="username">موبایل</InputLabel>
                                            <OutlinedInput
                                                autoFocus
                                                value={username ?? ""}
                                                onChange={handleUsernameChange}
                                                autoComplete="off"
                                                placeholder="موبایل را وارد کنید"
                                                inputProps={{
                                                    min: 0,
                                                    style: { textAlign: "center" },
                                                    maxLength: 11,
                                                }}
                                                type="text"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <Call />
                                                    </InputAdornment>
                                                }
                                                label="موبایل"
                                            />
                                            <FormHelperText>{usernameError ? usernameError : `${username.length}/11`}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl fullWidth variant="outlined" error={Boolean(passwordError)} required>
                                            <InputLabel htmlFor="password">رمز عبور</InputLabel>
                                            <OutlinedInput
                                                value={password ?? ""}
                                                onChange={handlePasswordChange}
                                                autoComplete="off"
                                                placeholder="رمز عبور را وارد کنید"
                                                inputProps={{
                                                    min: 0,
                                                    style: { textAlign: "center" },
                                                }}
                                                type={showPassword ? "text" : "password"}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="رمز عبور"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl required fullWidth>
                                            {allRolesLoading ? (
                                                <Select value="">
                                                    <MenuItem>
                                                        <CircularProgress />
                                                    </MenuItem>
                                                </Select>
                                            ) : (
                                                <>
                                                    <Select
                                                        value={allRoleStatus === 200 ? role : ""}
                                                        onChange={handleRoleChange}
                                                    >
                                                        {allRoles?.map((item, index) => (
                                                            <MenuItem key={index} value={item?.id}>
                                                                {item?.role_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{roleError}</FormHelperText>
                                                </>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <FormControlLabel
                                                value="end"
                                                control={
                                                    <Checkbox
                                                        checked={remember}
                                                        onChange={() => setRemember(!remember)}
                                                    />
                                                }
                                                label="مرا به خاطر بسپار"
                                                labelPlacement="end"
                                                sx={{ marginLeft: "5px" }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Box
                                                component="div"
                                                sx={{
                                                    float: {
                                                        xs: "none",
                                                        sm: "right",
                                                        md: "right",
                                                        lg: "right",
                                                        xl: "right",
                                                    },
                                                    marginTop: {
                                                        xs: "none",
                                                        sm: "8px",
                                                        md: "8px",
                                                        lg: "8px",
                                                        xl: "8px",
                                                    },
                                                    marginLeft: { xs: "16px" }
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        navigate("/recovery-password");
                                                    }}>
                                                    رمز عبور را فراموش کردم
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <LoadingButton
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            sx={{
                                                borderRadius: 28,
                                                color: colors.white,
                                            }}
                                            loading={!loadingLoginButton}
                                            disabled={!username || !password || !role}
                                            onClick={handleLogin}
                                        >
                                            ورود
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Stack direction="row" spacing={2}>
                                            <Typography
                                                variant="body1"
                                                component="span"
                                            >
                                                ثبت نام نکرده اید؟&nbsp;&nbsp;
                                                <span
                                                    style={{
                                                        color: mode === "light"
                                                            ? colors.blueAccent[500]
                                                            : colors.blueAccent[300],
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        navigate("/register");
                                                    }}
                                                >
                                                    ایجاد حساب کاربری
                                                </span>
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
};

export default Login;
