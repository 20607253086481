import Header from "../../components/Header";
import { Box } from "@mui/material";
import ProfilePost from "./ProfilePost";

const Profile = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پروفایل" subtitle="تنظیمات کاربری" backToPage="/" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <ProfilePost />
      </Box>
    </>
  );
};

export default Profile;
