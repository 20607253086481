import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import SettingsContext from "../../context/SettingsContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
  Container,
  Stack,
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
  TextField,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  Tooltip,
  styled,
  Fade,
} from "@mui/material";
import { AppRegistration, Call, Visibility, VisibilityOff } from "@mui/icons-material";
import { tokens } from "../../theme";
import LoadingButton from "@mui/lab/LoadingButton";
import { persian2english } from "../../components/ConvertDigits";

const boxStyle = (theme) => ({
  width: {
    xs: '90%',   // در اندازه موبایل (xs)، عرض 90 درصد
    sm: '70%',   // در اندازه‌های کوچک (sm)، عرض 70 درصد
    md: '50%',   // در اندازه‌های متوسط (md)، عرض 50 درصد
    lg: '35%',   // در اندازه‌های بزرگ (lg)، عرض 35 درصد
    xl: '35%',   // در اندازه‌های بسیار بزرگ (xl)، عرض 35 درصد
  },
  maxWidth: 'sm', // می‌توانید این مقدار را بر اساس نیاز تنظیم کنید
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  /* From https://css.glass */
  background: theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.8)",
  backdropFilter: theme.palette.mode === "light" ? "blur(5px)" : "blur(3px)",
  border: theme.palette.mode === "light" ? "1px solid rgba(0, 0, 0, 0.2)" : "1px solid rgba(255, 255, 255, 0.8)",
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 1500 }} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    maxWidth: 400,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginTop: "3px !important",
    marginBottom: "3px !important",
  },
}));

const Register = () => {
  const { register } = useContext(AuthContext);
  const { baseImageUrl, settings } = useContext(SettingsContext);
  const { handleOpenSnackbar } = useSnackbarContext();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const [privacy, setPrivacy] = useState(false);
  const navigate = useNavigate();
  const [loadingRegisterButton, setLoadingRegisterButton] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleShowPassword = () => setShowPassword((showPass) => !showPass);
  const handleShowPasswordConfirm = () => setShowPasswordConfirm((showPassConfirm) => !showPassConfirm);

  const handleRegister = async () => {
    setLoadingRegisterButton(false);

    try {
      await register(firstName, lastName, username, password, passwordConfirm);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response && ex.response.status === 422) {
        setFirstNameError(ex.response.data.errors.first_name);
        setLastNameError(ex.response.data.errors.last_name);
        if (ex.response.data.errors.username &&
          ex.response.data.errors.username.length > 1) {
          setUsernameError(ex.response.data.errors.username[0]);
        } else {
          setUsernameError(ex.response.data.errors.username);
        }
        if (ex.response.data.errors.password &&
          ex.response.data.errors.password.length > 1) {
          setPasswordError(ex.response.data.errors.password[0]);
        } else {
          setPasswordError(ex.response.data.errors.password);
        }
        if (ex.response.data.errors.password_confirmation &&
          ex.response.data.errors.password_confirmation.length > 1) {
          setPasswordConfirmError(ex.response.data.errors.password_confirmation[0]);
        } else {
          setPasswordConfirmError(ex.response.data.errors.password_confirmation);
        }
      }
      if (ex.response?.data?.errors?.popup) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          ex.response.data.errors.popup[0]
        );
      }
    }

    setLoadingRegisterButton(true);
  };

  const handleFirstNameChange = (e) => {
    const currentValue = e.target.value;
    setFirstName(currentValue);
    if (currentValue?.length === 0) {
      setFirstNameError("تکمیل گزینه نام الزامی است");
    } else {
      setFirstNameError("");
    }
  };

  const handleLastNameChange = (e) => {
    const currentValue = e.target.value;
    setLastName(currentValue);
    if (currentValue?.length === 0) {
      setLastNameError("تکمیل گزینه نام خانوادگی الزامی است");
    } else {
      setLastNameError("");
    }
  };

  const handleUsernameChange = (e) => {
    const currentValue = e.target.value;
    setUsername(persian2english(currentValue));
    if (currentValue?.length === 0) {
      setUsernameError("تکمیل گزینه موبایل الزامی است");
    } else {
      setUsernameError("");
    }
  };

  const handlePasswordChange = (e) => {
    const currentValue = e.target.value;
    setPassword(persian2english(currentValue));
    if (currentValue?.length === 0) {
      setPasswordError("تکمیل گزینه رمز عبور الزامی است");
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordConfirmChange = (e) => {
    const currentValue = e.target.value;
    setPasswordConfirm(persian2english(currentValue));
    if (currentValue?.length === 0) {
      setPasswordConfirmError("تکمیل گزینه تکرار رمز عبور الزامی است");
    } else {
      setPasswordConfirmError("");
    }
  };

  const handleFocus = () => {
    setTooltipOpen(true);
  };

  const handleBlur = () => {
    setTooltipOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${baseImageUrl + settings.main_background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={boxStyle} borderRadius="20px">
        <Grid container className="top_to_bottom_animation">
          <Grid item xs={12} sm={12} lg={12}>
            <Box
              component="div"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <Container maxWidth="xs">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Avatar>
                    <AppRegistration sx={{
                      color: mode === "light"
                        ? colors.black
                        : colors.white,
                    }} />
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    marginTop: "4px",
                    marginBottom: "35px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography component="h1" variant="h4" sx={{
                    color: mode === "light"
                      ? colors.black
                      : colors.white,
                  }}>
                    عضویت
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      type="text"
                      required
                      autoFocus
                      value={firstName ?? ""}
                      onChange={handleFirstNameChange}
                      autoComplete="off"
                      fullWidth
                      label="نام"
                      placeholder="نام را وارد کنید"
                      variant="outlined"
                      inputProps={{
                        min: 0,
                        style: { textAlign: "center" },
                      }}
                      error={Boolean(firstNameError)}
                      helperText={firstNameError}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      type="text"
                      required
                      value={lastName ?? ""}
                      onChange={handleLastNameChange}
                      autoComplete="off"
                      fullWidth
                      label=" نام خانوادگی"
                      placeholder="نام خانوادگی را وارد کنید"
                      variant="outlined"
                      inputProps={{
                        min: 0,
                        style: { textAlign: "center" },
                      }}
                      error={Boolean(lastNameError)}
                      helperText={lastNameError}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl fullWidth variant="outlined" error={Boolean(usernameError)} required>
                      <InputLabel htmlFor="username">موبایل</InputLabel>
                      <OutlinedInput
                        value={username ?? ""}
                        onChange={handleUsernameChange}
                        autoComplete="off"
                        placeholder="موبایل را وارد کنید"
                        inputProps={{
                          min: 0,
                          style: { textAlign: "center" },
                          maxLength: 11,
                        }}
                        type="text"
                        endAdornment={
                          <InputAdornment position="end">
                            <Call />
                          </InputAdornment>
                        }
                        label="موبایل"
                      />
                      <FormHelperText>{usernameError ? usernameError : `${username.length}/11`}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CustomWidthTooltip
                      title={
                        <Box>
                          <Box>رمز عبور باید حداقل هشت کاراکتر باشد.</Box>
                          <Box>رمز عبور باید حداقل شامل یک حرف بزرگ باشد.</Box>
                          <Box>رمز عبور باید حداقل شامل یک حرف کوچک باشد.</Box>
                          <Box>رمز عبور باید حداقل شامل یک عدد باشد.</Box>
                          <Box>رمز عبور نباید شامل فاصله باشد.</Box>
                        </Box>
                      }
                      open={tooltipOpen}
                      placement="top-end"
                    >
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={Boolean(passwordError)}
                        required
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      >
                        <InputLabel htmlFor="password">رمز عبور</InputLabel>
                        <OutlinedInput
                          value={password ?? ""}
                          onChange={handlePasswordChange}
                          autoComplete="off"
                          placeholder="رمز عبور را وارد کنید"
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="رمز عبور"
                        />
                        {passwordError && <FormHelperText>{passwordError}</FormHelperText>}
                      </FormControl>
                    </CustomWidthTooltip>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl fullWidth variant="outlined" error={Boolean(passwordConfirmError)} required>
                      <InputLabel htmlFor="passwordConfirm">تکرار رمز عبور</InputLabel>
                      <OutlinedInput
                        value={passwordConfirm ?? ""}
                        onChange={handlePasswordConfirmChange}
                        autoComplete="off"
                        placeholder="تکرار رمز عبور را وارد کنید"
                        inputProps={{
                          min: 0,
                          style: { textAlign: "center" },
                        }}
                        type={showPasswordConfirm ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password confirm visibility"
                              onClick={handleShowPasswordConfirm}
                              edge="end"
                            >
                              {showPasswordConfirm ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="تکرار رمز عبور"
                      />
                      {passwordConfirmError && <FormHelperText>{passwordConfirmError}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControlLabel
                        sx={{
                          color: mode === "light"
                            ? colors.black
                            : colors.white,
                          marginLeft: "5px"
                        }}
                        control={
                          <Checkbox
                            checked={privacy}
                            onChange={() => setPrivacy(!privacy)}
                          />
                        }
                        label={
                          <div>
                            <Link to={"/privacy"} style={{
                              color: mode === "light"
                                ? colors.blueAccent[500]
                                : colors.blueAccent[300],
                            }}>قوانین و مقررات</Link>
                            <span> را می پذیرم</span>
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      size="large"
                      sx={{
                        borderRadius: 28,
                        color: colors.white,
                        minWidth: "170px",
                      }}
                      loading={!loadingRegisterButton}
                      disabled={
                        !firstName ||
                        !lastName ||
                        !username ||
                        !password ||
                        !passwordConfirm ||
                        !privacy
                      }
                      onClick={handleRegister}
                    >
                      ایجاد حساب کاربری
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{
                          color: mode === "light"
                            ? colors.black
                            : colors.white,
                        }}
                      >
                        جهت ورود به سیستم&nbsp;
                        <span
                          style={{
                            color: mode === "light"
                              ? colors.blueAccent[500]
                              : colors.blueAccent[300],
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/login");
                          }}
                        >
                          اینجا
                        </span>
                        &nbsp;کلیک کنید
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Register;
