import { useState, useEffect, useContext, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import Header from "../../components/Header";
import { useSnackbarContext } from "../../context/SnackbarContext";
import CategoryContext from "../../context/CategoryContext";
import CoursesContext from "../../context/CoursesContext";
import InstructorsContext from "../../context/InstructorsContext";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  useTheme,
  CircularProgress,
  Grid,
  CardHeader,
  CardContent,
  Card,
  CardActions,
  InputAdornment,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CloudUpload, SaveOutlined } from "@mui/icons-material";
import { separateNumbers } from "../../components/PriceFormat";
import { tokens } from "../../theme";
import useServices from "../../utils/useServices";
import UploadProgressBar from "../../components/UploadProgressBar";

const EditCourses = () => {
  const { id: courseId } = useParams();
  const { putMethod, uploadPostMethod } = useServices();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const { getAllCategories, allCategoriesLoading, allCategories } = useContext(CategoryContext);
  const { getCourseById, course } = useContext(CoursesContext);
  const { getAllInstructors, allInstructorsLoading, allInstructors } = useContext(InstructorsContext);
  const { handleOpenSnackbar } = useSnackbarContext();
  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [loadingCoverStore, setLoadingCoverStore] = useState(true);
  const [cover, setCover] = useState([]);
  const [coverError, setCoverError] = useState("");
  const [frontPagePercentage, setFrontPagePercentage] = useState("");
  const [frontPagePercentageError, setFrontPagePercentageError] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameError, setCategoryNameError] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseNameError, setCourseNameError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [instructor, setInstructor] = useState("");
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountError, setDiscountError] = useState("");
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getAllCategories();
      getAllInstructors();
      getCourseById(courseId);
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [courseId]);

  useEffect(() => {
    fillRow();
    // eslint-disable-next-line
  }, [course]);

  function fillRow() {
    setFrontPagePercentage(course?.front_page_percentage)
    setFrontPagePercentageError("");
    setCategoryName(course?.category_id);
    setCategoryNameError("");
    setCourseName(course?.course_name);
    setCourseNameError("");
    setDescription(course?.description);
    setDescriptionError("");
    setInstructor(course?.user_id);
    setPrice(course?.price);
    setPriceError("");
    setDiscount(course?.discount);
    setDiscountError("");
  }

  const handleCoverStore = async () => {
    setLoadingCoverStore(false);

    try {
      const obj = {
        cover: cover,
        course_id: courseId,
      };

      await uploadPostMethod("/course/cover", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "تصویر دوره با موفقیت ثبت شد"
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.cover &&
          ex.response.data.errors.cover.length > 1) {
          setCoverError(ex.response.data.errors.cover[0]);
        } else {
          setCoverError(ex.response.data.errors.cover);
        }
      }
    }

    setLoadingCoverStore(true);
  };

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    try {
      const obj = {
        front_page_percentage: frontPagePercentage,
        category_id: categoryName,
        course_name: courseName,
        description: description,
        instructor: instructor,
        price: price,
        discount: discount,
      };

      const { data: updatedProcess } = await putMethod(
        "/courses",
        course?.id,
        obj
      );

      updatedProcess.price = separateNumbers(updatedProcess.price);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "دوره با موفقیت ویرایش شد"
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          "رکورد مورد نظر قبلاً حذف شده است"
        );
      }
      if (ex.response && ex.response.status === 422) {
        if (ex.response && ex.response.status === 422) {
          setCategoryNameError(ex.response.data.errors.category_id);
          setCourseNameError(ex.response.data.errors.course_name);
          setDescriptionError(ex.response.data.errors.description);
          if (ex.response.data.errors.price &&
            ex.response.data.errors.price.length > 1) {
            setPriceError(ex.response.data.errors.price[0]);
          } else {
            setPriceError(ex.response.data.errors.price);
          }
          if (ex.response.data.errors.discount &&
            ex.response.data.errors.discount.length > 1) {
            setDiscountError(ex.response.data.errors.discount[0]);
          } else {
            setDiscountError(ex.response.data.errors.discount);
          }
          if (ex.response.data.errors.front_page_percentage &&
            ex.response.data.errors.front_page_percentage.length > 1) {
            setFrontPagePercentageError(ex.response.data.errors.front_page_percentage[0]);
          } else {
            setFrontPagePercentageError(ex.response.data.errors.front_page_percentage);
          }
        }
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setCover(currentValue);
    // console.log(cover);
  };

  const handleFrontPagePercentageChange = (e) => {
    const currentValue = e.target.value;
    setFrontPagePercentage(currentValue);
    if (currentValue?.length === 0) {
      setFrontPagePercentageError("تکمیل گزینه درصد صفحه اول الزامی است");
    } else {
      setFrontPagePercentageError("");
    }
  };

  const handleCategoryNameChange = (e) => {
    const currentValue = e.target.value;
    setCategoryName(currentValue);
    if (currentValue?.length === 0) {
      setCategoryNameError("تکمیل گزینه نام دسته بندی الزامی است");
    } else {
      setCategoryNameError("");
    }
  };

  const handleCourseNameChange = (e) => {
    const currentValue = e.target.value;
    setCourseName(currentValue);
    if (currentValue?.length === 0) {
      setCourseNameError("تکمیل گزینه نام دوره الزامی است");
    } else {
      setCourseNameError("");
    }
  };

  const handleDescriptionChange = (e) => {
    const currentValue = e.target.value;
    setDescription(currentValue);
  };

  const handlePriceChange = (e) => {
    const currentValue = e.target.value;
    setPrice(currentValue);
    if (currentValue?.length === 0) {
      setPriceError("تکمیل گزینه قیمت الزامی است");
    } else {
      setPriceError("");
    }
  };

  const handleDiscountChange = (e) => {
    const currentValue = e.target.value;
    setDiscount(currentValue);
    if (currentValue?.length === 0) {
      setDiscountError("تکمیل گزینه درصد تخفیف دوره الزامی است");
    } else {
      setDiscountError("");
    }
  };

  const handleInstructorChange = (e) => {
    const currentValue = e.target.value;
    setInstructor(currentValue);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ویرایش دوره" subtitle="مدیریت پنل" backToPage="/courses" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Box mb="50px">
          <Fade triggerOnce={true}>
            <Card>
              <CardHeader title="تغییر تصویر" />
              <CardContent>
                <Grid container rowSpacing={2} columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                    <TextField
                      fullWidth
                      type="file"
                      label="تصویر دوره"
                      onChange={handleCoverChange}
                      accept="image/*"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CloudUpload />
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(coverError)}
                      helperText={coverError ? coverError : "810px × 500px"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <LoadingButton
                      fullWidth
                      variant="outlined"
                      loadingPosition="start"
                      loading={!loadingCoverStore}
                      disabled={cover?.length === 0}
                      startIcon={<SaveOutlined />}
                      onClick={handleCoverStore}
                      sx={{ height: "56px" }}
                    >
                      ذخیره تصویر
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <UploadProgressBar />
              </CardActions>
            </Card>
          </Fade>
        </Box>
        <Fade triggerOnce={true}>
          <Card>
            <CardHeader title="ویرایش دوره" />
            <CardContent>
              <Grid container rowSpacing={4} columnSpacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ textAlign: "center" }}>
                    <Fade triggerOnce={true}>
                      <Typography
                        component="span"
                        variant="h6"
                      >
                        در صورتی که دسته بندی مورد نظر وجود ندارد
                        <NavLink to="/admin-categories">
                          <Typography
                            component="span"
                            variant="h6"
                            sx={{
                              fontWeight: 700,
                              color: mode === "light"
                                ? colors.blueAccent[500]
                                : colors.orangeAccent[500],
                            }}
                          >
                            &nbsp;اینجا&nbsp;
                          </Typography>
                        </NavLink>
                        کلیک کنید.
                      </Typography>
                    </Fade>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ textAlign: "center" }}>
                    <Fade triggerOnce={true}>
                      <Typography
                        component="span"
                        variant="h6"
                      >
                        در صورتی که استاد مورد نظر وجود ندارد
                        <NavLink to="/admin-users">
                          <Typography
                            component="span"
                            variant="h6"
                            sx={{
                              fontWeight: 700,
                              color: mode === "light"
                                ? colors.blueAccent[500]
                                : colors.orangeAccent[500],
                            }}
                          >
                            &nbsp;اینجا&nbsp;
                          </Typography>
                        </NavLink>
                        کلیک کنید.
                      </Typography>
                    </Fade>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {allCategoriesLoading ? (
                    <FormControl required fullWidth>
                      <InputLabel id="edit-category-loaing">نام دسته بندی</InputLabel>
                      <Select value="" labelId="edit-category-loaing" label="نام دسته بندی">
                        <MenuItem>
                          <CircularProgress />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl required fullWidth error={Boolean(categoryNameError)}>
                      <InputLabel id="edit-category-label">نام دسته بندی</InputLabel>
                      <Select
                        labelId="edit-category-label"
                        value={categoryName ?? ""}
                        label="نام دسته بندی"
                        onChange={handleCategoryNameChange}
                      >
                        {allCategories?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.category_name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{categoryNameError}</FormHelperText>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={courseName ?? ""}
                    onChange={handleCourseNameChange}
                    autoComplete="off"
                    label="نام دوره"
                    required
                    inputProps={{ maxLength: 100 }}
                    error={Boolean(courseNameError)}
                    helperText={
                      courseNameError ? courseNameError : `${courseName?.length}/100`
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={description ?? ""}
                    onChange={handleDescriptionChange}
                    autoComplete="off"
                    label="شرح"
                    multiline
                    rows={4}
                    inputProps={{ style: { textAlign: "justify" }, maxLength: 300 }}
                    error={Boolean(descriptionError)}
                    helperText={
                      descriptionError ? descriptionError : `${description?.length}/300`
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  {allInstructorsLoading ? (
                    <FormControl required fullWidth>
                      <InputLabel id="edit-instructor-loading">استاد</InputLabel>
                      <Select value="" labelId="edit-instructor-loading" label="استاد">
                        <MenuItem>
                          <CircularProgress />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl required fullWidth>
                      <InputLabel id="edit-instructor-label">استاد</InputLabel>
                      <Select
                        labelId="edit-instructor-label"
                        label="استاد"
                        value={instructor ?? ""}
                        onChange={handleInstructorChange}
                      >
                        {allInstructors?.map((item, index) => (
                          <MenuItem key={index} value={item?.id}>
                            {item?.full_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={price ?? ""}
                    onChange={handlePriceChange}
                    onKeyUp={() => setPrice(separateNumbers(price, "editCoursesNumToLetter"))}
                    autoComplete="off"
                    label="قیمت (تومان)"
                    required
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(priceError)}
                    helperText={priceError}
                  />
                  <Box
                    color={colors.blueAccent[500]}
                    id="editCoursesNumToLetter"
                    sx={{ textAlign: "justify", fontSize: "12px" }}
                  ></Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={discount ?? ""}
                    onChange={handleDiscountChange}
                    autoComplete="off"
                    label="درصد تخفیف دوره"
                    required
                    inputProps={{ maxLength: 3 }}
                    error={Boolean(discountError)}
                    helperText={discountError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={frontPagePercentage ?? ""}
                    onChange={handleFrontPagePercentageChange}
                    autoComplete="off"
                    label="درصد صفحه اول"
                    required
                    inputProps={{ maxLength: 3 }}
                    error={Boolean(frontPagePercentageError)}
                    helperText={frontPagePercentageError}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <LoadingButton
                variant="outlined"
                loadingPosition="start"
                size="large"
                loading={!loadingUpdateButton}
                disabled={
                  !categoryName ||
                  !courseName ||
                  !instructor ||
                  !price ||
                  !discount ||
                  !frontPagePercentage
                }
                startIcon={<SaveOutlined />}
                onClick={handleUpdate}
              >
                ذخیره
              </LoadingButton>
            </CardActions>
          </Card>
        </Fade>
      </Box>
    </>
  );
};

export default EditCourses;
