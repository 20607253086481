import { forwardRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Slide,
  IconButton,
  Tooltip,
  Box,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Close, OpenInFull } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = props => makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-container": {
      justifyContent: props?.maxWidthDialog === "sm"
        ? "flex-start !important"
        : "center !important",
      alignItems: "flex-end",
    },
  },
  styledHeader: {
    background: "#f2f2f2",
  },
  fullScreen: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ComposeDialog = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [screenTooltip, setScreenTooltip] = useState("تمام صفحه");

  const closeDialog = () => {
    props?.setOpenDialog(false);
    setScreenTooltip("تمام صفحه");
  };

  const handleOpenInFull = () => {
    if (props?.maxWidthDialog === "sm") {
      props?.setMaxWidthDialog("lg");
      setScreenTooltip("حالت عادی");
    } else {
      props?.setMaxWidthDialog("sm");
      setScreenTooltip("تمام صفحه");
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={props?.maxWidthDialog}
      open={props?.openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      className={classes.dialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} className={classes.styledHeader}>
        {props?.dialogTitle}
      </DialogTitle>
      <Tooltip title="بستن">
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      </Tooltip>
      {!isMobile &&
        <Box className={classes.fullScreen}>
          <Tooltip title={screenTooltip}>
            <IconButton
              onClick={handleOpenInFull}
              sx={{
                position: "absolute",
                right: 40,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <OpenInFull fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      }
      {props?.dialogActions}
    </Dialog>
  );
};

export default ComposeDialog;
