import { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import ComposeDialog from './../../components/ComposeDialog';
import { DeleteOutline, VisibilityOutlined } from "@mui/icons-material";
import ShowSentEmails from "./ShowSentEmails";
import DeleteSentEmails from "./DeleteSentEmails";
import useServices from "../../utils/useServices";

const SentEmailsTable = () => {
    const { postMethodByPageId } = useServices();
    const [selectedRow, setSelectedRow] = useState("");
    const [maxWidthDialog, setMaxWidthDialog] = useState("sm");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogActions, setDialogActions] = useState("");
    const [sentEmails, setSentEmails] = useState([]);
    const [status, setStatus] = useState(0);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState(5);
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getSentEmails();
        // eslint-disable-next-line
    }, [rows, total, page]);

    async function getSentEmails() {
        setLoading(true);

        try {
            const obj = {
                rows: rows,
            };

            const { data: paginateProcess } = await postMethodByPageId("/admin/sent_emails", page, obj);
            if (paginateProcess.from === null) {
                setFrom(0);
            } else {
                setFrom(paginateProcess.from);
            }
            if (paginateProcess.to === null) {
                setTo(0);
            } else {
                setTo(paginateProcess.to);
            }
            setTotal(paginateProcess.total);
            setSentEmails(paginateProcess.data);
            setStatus(status);
        } catch (ex) {

        }

        setLoading(false);
    }

    const handleOpenDialog = (widthDialog, title, content) => {
        setMaxWidthDialog(widthDialog);
        setDialogTitle(title);
        setDialogActions(content);
        setOpenDialog(true);
    };

    const columns = [
        {
            field: "id",
            headerName: "ردیف",
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "users",
            valueFormatter: (params) =>
                params.value !== undefined ?
                    params?.value?.map((item) => {
                        return " " + item?.full_name;
                    })
                    : "",
            headerName: "به",
            width: 250,
        },
        {
            field: "subject",
            headerName: "موضوع",
            width: 300,
        },
        {
            field: "message",
            headerName: "پیام",
            width: 445,
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "",
                            <DeleteSentEmails
                                params={selectedRow}
                                getSentEmails={getSentEmails}
                                setOpenDialog={setOpenDialog}
                            />
                        )
                    }
                    sx={{
                        borderRadius: "16px",
                        color: "#5f6368",
                        height: "56px",
                        padding: "0 24px 0 24px",
                        minWidth: "96px"
                    }}
                    variant="outlined"
                    startIcon={<DeleteOutline />}
                    disabled={!selectedRow}
                >
                    حذف
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "مشاهده پیام",
                            <ShowSentEmails params={selectedRow} />
                        )
                    }
                    sx={{
                        borderRadius: "16px",
                        color: "#5f6368",
                        height: "56px",
                        padding: "0 24px 0 24px",
                        minWidth: "96px"
                    }}
                    variant="outlined"
                    startIcon={<VisibilityOutlined />}
                    disabled={!selectedRow}
                >
                    مشاهده
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={sentEmails}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
                toolbarFilter={false}
                toolbarExport={false}
            />
            <ComposeDialog
                maxWidthDialog={maxWidthDialog} // xs sm md lg xl
                setMaxWidthDialog={setMaxWidthDialog} // xs sm md lg xl
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                dialogTitle={dialogTitle}
                dialogActions={dialogActions}
            />
        </>
    );
};

export default SentEmailsTable;
