import React, { useContext, useEffect } from "react";
import Header from "../../components/Header";
import TransactionsContext from "../../context/TransactionsContext";
import OrderStatusPost from "./OrderStatusPost";
import { Box, Grid } from "@mui/material";

const OrderStatus = () => {
    const {
        getUserTransactions,
        userTransactions,
        setPage,
        page
    } = useContext(TransactionsContext);

    useEffect(() => {
        setPage(1);
        getUserTransactions();
        // eslint-disable-next-line
    }, [page]);

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="وضعیت سفارش" subtitle="پیگیری سفارش" backToPage="/" />
            </Box>
            <Box mb="50px" mt="50px" className="container">
                <Grid container spacing={4}>
                    {userTransactions?.map((userTransaction, index) => (
                        <Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <OrderStatusPost userTransaction={userTransaction} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    )
}

export default OrderStatus
