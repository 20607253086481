import { useEffect } from "react";
import { Box, useTheme, LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { tokens } from "../theme";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill:
      theme.palette.mode === "light"
        ? tokens(theme.palette.mode).blueAccent[500]
        : "#434343",
  },
  "& .ant-empty-img-4": {
    fill:
      theme.palette.mode === "light"
        ? tokens(theme.palette.mode).white
        : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill:
      theme.palette.mode === "light"
        ? "#f5f5f5"
        : tokens(theme.palette.mode).grey[800],
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>داده ای وجود ندارد</Box>
    </StyledGridOverlay>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // "& .MuiDataGrid-row.Mui-my-css-bold": { // & .MuiDataGrid-row.Mui-my-css-bold:not(:hover)
    //   fontWeight: "700",
    // },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: tokens(theme.palette.mode).grey[800],
      borderBottom: "none",
      borderradius: "unset",
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: tokens(theme.palette.mode).grey[900],
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: tokens(theme.palette.mode).grey[800],
      borderTop: "none",
    },
    "& .MuiDataGrid-toolbarContainer": {
      backgroundColor: tokens(theme.palette.mode).grey[900],
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-cell:hover": {
      color:
        theme.palette.mode === "light"
          ? `${tokens(theme.palette.mode).black} !important`
          : `${tokens(theme.palette.mode).white} !important`,
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor:
        theme.palette.mode === "light"
          ? `${tokens(theme.palette.mode).blueAccent[800]} !important`
          : `${tokens(theme.palette.mode).blueAccent[500]} !important`,
      color:
        theme.palette.mode === "light"
          ? tokens(theme.palette.mode).black
          : tokens(theme.palette.mode).white,
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor:
        theme.palette.mode === "light"
          ? `${tokens(theme.palette.mode).blueAccent[800]} !important`
          : `${tokens(theme.palette.mode).blueAccent[500]} !important`,
      color:
        theme.palette.mode === "light"
          ? tokens(theme.palette.mode).black
          : tokens(theme.palette.mode).white,
    },
    "& .MuiDataGrid-row.Mui-selected:hover": {
      color:
        theme.palette.mode === "light"
          ? tokens(theme.palette.mode).black
          : tokens(theme.palette.mode).white,
    },
  },
}));

function myToolbar(
  toolbarColumns,
  toolbarFilter,
  toolbarDensity,
  toolbarExport) {
  return (
    <GridToolbarContainer>
      {toolbarColumns && <GridToolbarColumnsButton />}
      {toolbarFilter && <GridToolbarFilterButton />}
      {toolbarDensity && <GridToolbarDensitySelector />}
      {toolbarExport && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const CustomDataGrid = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();

  useEffect(() => {
    props?.setPage(1);
    props?.setRows(5);
    // eslint-disable-next-line
  }, []);

  return (
    <Box m="10px 0 0 0" height="58vh">
      <DataGrid
        localeText={{
          noRowsLabel: "داده ای وجود ندارد",
          noResultsOverlayLabel: "موردی یافت نشد",
          errorOverlayDefaultLabel: "string",
          toolbarDensity: "تراکم",
          toolbarDensityLabel: "string",
          toolbarDensityCompact: "فشرده",
          toolbarDensityStandard: "استاندارد",
          toolbarDensityComfortable: "راحت",
          toolbarColumns: "ستون ها",
          toolbarColumnsLabel: "string",
          toolbarFilters: "فیلتر",
          toolbarFiltersLabel: "string",
          toolbarFiltersTooltipHide: "فیلترها را پنهان کنید",
          toolbarFiltersTooltipShow: "نمایش فیلترها",
          // toolbarFiltersTooltipActive: (count: number) => "React.ReactNode",
          toolbarQuickFilterPlaceholder: "string",
          toolbarQuickFilterLabel: "string",
          toolbarQuickFilterDeleteIconLabel: "string",
          toolbarExport: "گزارش",
          toolbarExportLabel: "string",
          toolbarExportCSV: "فایل CSV",
          toolbarExportPrint: "پرینت",
          toolbarExportExcel: "string",
          columnsPanelTextFieldLabel: "جستجوی ستون",
          columnsPanelTextFieldPlaceholder: "عنوان ستون",
          columnsPanelDragIconLabel: "string",
          columnsPanelShowAllButton: "نمایش همه",
          columnsPanelHideAllButton: "همه مخفی شوند",
          filterPanelAddFilter: "React.ReactNode",
          filterPanelDeleteIconLabel: "بستن",
          filterPanelLinkOperator: "string",
          filterPanelOperators: "React.ReactNode",
          filterPanelOperatorAnd: "React.ReactNode",
          filterPanelOperatorOr: "React.ReactNode",
          filterPanelColumns: "ستون ها",
          filterPanelInputLabel: "مقدار",
          filterPanelInputPlaceholder: "مقدار فیلتر",
          filterPanelOperator: "عملیات",
          filterOperatorContains: "شامل",
          filterOperatorEquals: "برابر",
          filterOperatorStartsWith: "شروع شود با",
          filterOperatorEndsWith: "ختم شود به",
          filterOperatorIs: "وجود دارد",
          filterOperatorNot: "string",
          filterOperatorAfter: "string",
          filterOperatorOnOrAfter: "string",
          filterOperatorBefore: "string",
          filterOperatorOnOrBefore: "string",
          filterOperatorIsEmpty: "خالی است",
          filterOperatorIsNotEmpty: "خالی نیست",
          filterOperatorIsAnyOf: "هر یک از",
          filterValueAny: "همه مقادیر",
          filterValueTrue: "مقادیر true",
          filterValueFalse: "مقادیر false",
          columnMenuLabel: "منو",
          columnMenuShowColumns: "React.ReactNode",
          columnMenuSortAsc: "مرتب کردن ASC",
          columnMenuSortDesc: "مرتب کردن DESC",
          columnMenuFilter: "فیلتر",
          columnMenuHideColumn: "مخفی کردن ستون",
          columnMenuUnsort: "مرتب نشده",
          columnMenuManageColumns: "مدیریت ستون ها",
          // columnHeaderFiltersTooltipActive: (count: number) =>
          //   "React.ReactNode",
          columnHeaderFiltersLabel: "string",
          columnHeaderSortIconLabel: "مرتب سازی",
          // footerRowSelected: (count: number) => "ردیف انتخاب شده: ",
          footerTotalRows: "React.ReactNode",
          // footerTotalVisibleRows: (
          //   visibleCount: number,
          //   totalCount: number
          // ) => "React.ReactNode",
          checkboxSelectionHeaderName: "انتخاب کادر",
          checkboxSelectionSelectAllRows: "string",
          checkboxSelectionUnselectAllRows: "string",
          checkboxSelectionSelectRow: "string",
          checkboxSelectionUnselectRow: "string",
          booleanCellTrueLabel: "string",
          booleanCellFalseLabel: "string",
          actionsCellMore: "string",
          pinToLeft: "string",
          pinToRight: "string",
          unpin: "string",
          treeDataGroupingHeaderName: "string",
          treeDataExpand: "string",
          treeDataCollapse: "string",
          groupingColumnHeaderName: "string",
          // groupColumn: (name: string) => "string",
          // unGroupColumn: (name: string) => "string",
          detailPanelToggle: "string",
          expandDetailPanel: "string",
          collapseDetailPanel: "string",
          rowReorderingHeaderName: "string",
          aggregationMenuItemHeader: "string",
          aggregationFunctionLabelSum: "string",
          aggregationFunctionLabelAvg: "string",
          aggregationFunctionLabelMin: "string",
          aggregationFunctionLabelMax: "string",
          aggregationFunctionLabelSize: "string",

          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, total }) =>
              `${props?.from} - ${props?.to} از ${props?.total}`,
          },
        }}
        hideFooterPagination={props?.hideFooterPagination}
        hideFooterSelectedRowCount={props?.hideFooterSelectedRowCount}
        className={classes.root}
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: colors.blueAccent[500],
          },
        }}
        // getRowClassName={(params) =>
        //   params.row.pivot ? (
        //     params.row.pivot.is_read === 0 ? 'Mui-my-css-bold' : ''
        //   ) : (
        //     params.row.is_read === 0 ? 'Mui-my-css-bold' : ''
        //   )
        // }
        rowCount={props?.total}
        loading={props?.loading}
        paginationMode="server"
        onPaginationModelChange={(newPaginationModel) => {
          // fetch data from server
          props?.setPage(newPaginationModel.page + 1);
          props?.setRows(newPaginationModel.pageSize);
        }}
        rows={props?.myData}
        columns={props?.myColumns}
        onRowClick={(params, event) => {
          if (!event.ignore) {
            // console.log(params.row);
            props?.setSelectedRow(params.row);
          }
        }}
        slots={{
          toolbar: () => myToolbar(
            props?.toolbarColumns ?? true,
            props?.toolbarFilter ?? true,
            props?.toolbarDensity ?? true,
            props?.toolbarExport ?? true
          ),
          noRowsOverlay: CustomNoRowsOverlay,
          loadingOverlay: LinearProgress,
        }}
        density={props?.density ?? "standard"}
        {...props?.myData}
        initialState={{
          ...props?.myData?.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        componentsProps={{
          pagination: {
            labelRowsPerPage: "ردیف در هر صفحه:",
          },
        }}
        checkboxSelection={props?.checkboxSelection ? true : false}
        keepNonExistentRowsSelected // ردیف های غیر موجود را انتخاب شده نگه دارید
        onRowSelectionModelChange={(newSelectionModel) => {
          props?.setSelectedItems && props.setSelectedItems(newSelectionModel);
        }}
        rowSelectionModel={props?.selectedItems}
      />
    </Box>
  );
};

export default CustomDataGrid;
