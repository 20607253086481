import { useContext } from "react";
import useAxios from "./useAxios";
import ProgressBarContext from "../context/ProgressBarContext";

const baseURL = `${process.env.REACT_APP_DOMAIN_URL}api/v1`;
// const baseURL = `${process.env.REACT_APP_LOCALHOST_URL}api/v1`;

const useServices = () => {
    const http = useAxios();
    const { setProgress } = useContext(ProgressBarContext);

    function wait(duration) {
        return new Promise(resolve => setTimeout(resolve, duration));
    }

    async function getMethod(resourceName) {
        return http.get(baseURL + resourceName);
    }

    async function postMethodByPageId(resourceName, obj) {
        return http.post(baseURL + resourceName + "?page=" + obj.page, obj);
    }

    async function postMethod(resourceName, obj) {
        return http.post(baseURL + resourceName, obj);
    }

    async function uploadPostMethod(resourceName, obj) {
        return http.post(baseURL + resourceName, obj, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setProgress(percentCompleted);
                if (percentCompleted === 100) {
                    setProgress(0);
                }
            }
        });
    }

    async function putMethod(resourceName, id, obj) {
        return http.put(baseURL + resourceName + "/" + id, obj);
    }

    async function deleteMethod(resourceName, id) {
        return http.delete(baseURL + resourceName + "/" + id);
    }

    return {
        wait,
        getMethod,
        postMethodByPageId,
        postMethod,
        uploadPostMethod,
        putMethod,
        deleteMethod
    };
}

export default useServices;
