import Header from "../../components/Header";
import { Box } from "@mui/material";
import CartPost from './CartPost';

const Cart = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="کارت" subtitle="سبد خرید" backToPage="/courses" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <CartPost />
      </Box>
    </>
  );
};

export default Cart;
