import { useContext, useEffect, useRef } from "react";
import Header from "../../components/Header";
import UsersContext from "../../context/UsersContext";
import UsersPost from "./UsersPost";
import { Box, Pagination } from "@mui/material";

const AdminUsers = () => {
  const {
    getUsers,
    page,
    setPage,
    lastPage,
    users,
  } = useContext(UsersContext);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getUsers();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="کاربران" subtitle="لیست کاربران" backToPage="/admin-courses" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Box className="row">
          {users?.map((user, index) => (
            <UsersPost key={index} user={user} />
          ))}
        </Box>
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          {lastPage !== 1 && (
            <Pagination count={lastPage} onChange={(e, value) => setPage(value)} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminUsers;
