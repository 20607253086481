import { useContext, useEffect } from "react";
import Header from "../../components/Header";
import ContactContext from "../../context/ContactContext";
import ContactPost from "./ContactPost";
import { Box, Pagination } from "@mui/material";

const AdminContact = () => {
  const {
    getContacts,
    contacts,
    page, setPage,
    lastPage
  } = useContext(ContactContext);

  useEffect(() => {
    getContacts();
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل تماس با ما" subtitle="مدیریت پنل" backToPage="/contact" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        {contacts?.map((contact, index) => (
          <ContactPost key={index} contact={contact} />
        ))}
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          {lastPage !== 1 && (
            <Pagination count={lastPage} onChange={(e, value) => setPage(value)} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminContact;
