import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { useSnackbarContext } from "./SnackbarContext";

const baseURL = `${process.env.REACT_APP_DOMAIN_URL}api/v1`;
// const baseURL = `${process.env.REACT_APP_LOCALHOST_URL}api/v1`;
const tokenKey = "auth_token";
const dateKey = "refresh_token";

const AuthContext = createContext(null);
AuthContext.displayName = "AuthContext";

export default AuthContext;

export const AuthContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { handleOpenSnackbar } = useSnackbarContext();
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(() => localStorage.getItem(tokenKey) ? localStorage.getItem(tokenKey) : null);
    const [user, setUser] = useState(() => localStorage.getItem(tokenKey) ? jwtDecode(localStorage.getItem(tokenKey)) : null);
    const [refreshingTokenDT, setRefreshingTokenDT] = useState(() => localStorage.getItem(dateKey) ? localStorage.getItem(dateKey) : null);
    const [seconds, setSeconds] = useState(120);

    useEffect(() => {
        setLoading(false);
        // eslint-disable-next-line
    }, [token, loading]);

    const newActiveCode = async (username) => {
        try {
            const obj = {
                username: username,
            };

            const response = await axios.post(`${baseURL}/new/active_code`, obj);
            const data = await response?.data;

            if (data?.status === 200) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "success",
                    data.message
                );
                setSeconds(120);
            } else {
                alert("Something went wrong!");
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    "کاربر قبلاً حذف شده است"
                );
            }
            if (ex.response?.data?.errors?.popup) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    ex.response.data.errors.popup[0]
                );
            }
        }
    };

    const newTemporaryCode = async (username) => {
        try {
            const obj = {
                username: username,
            };

            const response = await axios.post(`${baseURL}/new/temporary_code`, obj);
            const data = await response?.data;

            if (data?.status === 200) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "success",
                    data.message
                );
                setSeconds(120);
            } else {
                alert("Something went wrong!");
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    "کاربر قبلاً حذف شده است"
                );
            }
            if (ex.response?.data?.errors?.popup) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    ex.response.data.errors.popup[0]
                );
            }
        }
    };

    const register = async (firstName, lastName, username, password, passwordConfirm) => {
        const response = await axios.post(`${baseURL}/register`, {
            'first_name': firstName,
            'last_name': lastName,
            'username': username,
            'password': password,
            'password_confirmation': passwordConfirm
        });

        const data = await response?.data;

        if (response?.status === 200) {
            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                data.message
            );
            navigate("/active-account/" + username);
        } else {
            alert("Something went wrong!");
        }
    }

    const login = async (username, password, role) => {
        const response = await axios.post(`${baseURL}/login`, {
            'username': username,
            'password': password,
            'role': role
        });

        const data = await response?.data;

        if (response?.status === 200) {
            setToken(data.token);
            setUser(jwtDecode(data.token));
            setRefreshingTokenDT(data.refreshingTokenDT);
            localStorage.setItem(tokenKey, data.token);
            localStorage.setItem(dateKey, data.refreshingTokenDT);
        } else {
            alert("Something went wrong!");
        }
    }

    const logout = async () => {
        await axios.post(`${baseURL}/logout`, {
            token: token
        });

        setToken(null);
        setUser(null);
        setRefreshingTokenDT(null);
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(dateKey);
        navigate("/login");
        // window.location.href = "/login";
    }

    const contextData = {
        baseURL,
        tokenKey,
        dateKey,
        user: user,
        setUser: setUser,
        token: token,
        setToken: setToken,
        refreshingTokenDT: refreshingTokenDT,
        setRefreshingTokenDT: setRefreshingTokenDT,
        newActiveCode: newActiveCode,
        newTemporaryCode: newTemporaryCode,
        seconds: seconds,
        setSeconds: setSeconds,
        register: register,
        login: login,
        logout: logout
    };

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
}
