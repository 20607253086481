import React, { useContext, useEffect } from "react";
import { useDialogContext } from "../../context/DialogContext";
import FaqContext from "../../context/FaqContext";
import { Button, Stack } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import { Add, Edit, Delete } from "@mui/icons-material";
import CreateFaq from "./CreateFaq";
import EditFaq from "./EditFaq";
import DeleteFaq from "./DeleteFaq";

const FaqTable = () => {
    const { handleOpenDialog } = useDialogContext();
    const {
        getFaqs,
        faqs,
        selectedRow, setSelectedRow,
        loading,
        page, setPage,
        rows, setRows,
        from,
        to,
        total
    } = useContext(FaqContext);

    const columns = [
        {
            field: "id",
            headerName: "ردیف",
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "question",
            headerName: "سوال",
            flex: 1,
        },
        {
            field: "answer",
            headerName: "جواب",
            flex: 1,
        },
    ];

    useEffect(() => {
        getFaqs();
        // eslint-disable-next-line
    }, [rows, total, page]);

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "افزودن سوال و جواب",
                            <CreateFaq
                                getFaqs={getFaqs}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Add />}
                >
                    افزودن
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "ویرایش سوال و جواب",
                            <EditFaq
                                params={selectedRow}
                                getFaqs={getFaqs}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Edit />}
                    disabled={!selectedRow}
                >
                    ویرایش
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "",
                            <DeleteFaq
                                params={selectedRow}
                                getFaqs={getFaqs}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Delete />}
                    disabled={!selectedRow}
                >
                    حذف
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={faqs}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
            />
        </>
    );
};

export default FaqTable;
