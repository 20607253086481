import { useContext } from "react";
import UsersContext from "../context/UsersContext";
import { useDialogContext } from "../context/DialogContext";
import { Stack, Avatar, Skeleton, DialogActions, Button } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";

const ShowAvatar = (props) => {
    const { baseProfileUrl } = useContext(UsersContext);
    const { handleCloseDialog } = useDialogContext();

    return (
        <>
            {props?.avatar ? (
                <Stack direction="row" justifyContent="center">
                    <Avatar
                        // alt={props?.fullName}
                        src={baseProfileUrl + props?.avatar}
                        sx={{ width: 300, height: 300, marginBottom: "20px" }}
                    />
                </Stack>
            ) : (
                <Stack direction="row" justifyContent="center">
                    <Skeleton
                        sx={{ width: "300px", height: "300px", marginBottom: "20px" }}
                        animation="wave"
                        variant="circular"
                    />
                </Stack>
            )}
            <DialogActions>
                <Button
                    variant="outlined"
                    startIcon={<CancelOutlined />}
                    onClick={handleCloseDialog}
                >
                    بستن
                </Button>
            </DialogActions>
        </>
    );
};

export default ShowAvatar;
