import { useState, useEffect, useContext } from "react";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import CategoryContext from "../../context/CategoryContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";

const EditCategories = (props) => {
  const { putMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const { getCategories } = useContext(CategoryContext);
  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameError, setCategoryNameError] = useState("");

  useEffect(() => {
    fillRow();
    // eslint-disable-next-line
  }, [props?.params]);

  function fillRow() {
    setCategoryName(props?.params?.category_name);
    setCategoryNameError("");
  }

  const handleCategoryNameChange = (e) => {
    const currentValue = e.target.value;
    setCategoryName(currentValue);
    if (currentValue?.length === 0) {
      setCategoryNameError("تکمیل گزینه نام دسته بندی الزامی است");
    } else {
      setCategoryNameError("");
    }
  };

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    try {
      const obj = {
        category_name: categoryName,
      };

      await putMethod(
        "/categories",
        props?.params?.id,
        obj
      );

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "دسته بندی دوره ها با موفقیت ویرایش شد"
      );

      getCategories();
      handleCloseDialog();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          "رکورد مورد نظر قبلاً حذف شده است"
        );
      }
      if (ex.response && ex.response.status === 422) {
        setCategoryNameError(ex.response.data.errors.category_name);
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleResetDialogItems = () => {
    handleCloseDialog();
    fillRow();
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={categoryName ?? ""}
            onChange={handleCategoryNameChange}
            autoComplete="off"
            label="نام دسته بندی"
            required
            inputProps={{ maxLength: 40 }}
            error={Boolean(categoryNameError)}
            helperText={
              categoryNameError
                ? categoryNameError
                : `${categoryNameError.length}/40`
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleResetDialogItems}
        >
          انصراف
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingUpdateButton}
          disabled={!categoryName}
          startIcon={<SaveOutlined />}
          onClick={handleUpdate}
        >
          ذخیره
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EditCategories;
