import { createContext, useState } from "react";
import useServices from './../utils/useServices';

const EpisodeContext = createContext(null);
EpisodeContext.displayName = "EpisodeContext";

export default EpisodeContext;

export const EpisodeContextProvider = ({ children }) => {
    const { postMethodByPageId } = useServices();
    const [episodes, setEpisodes] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(5);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    async function getEpisodesBySeasonId(seasonId) {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: rows,
                season_id: seasonId
            };

            const response = await postMethodByPageId("/episodes_by_season_id", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setEpisodes(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    const contextData = {
        getEpisodesBySeasonId,
        episodes, setEpisodes,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <EpisodeContext.Provider value={contextData}>
            {children}
        </EpisodeContext.Provider>
    );
}
