import { useMemo, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
  TextField,
  InputAdornment,
  Grid,
  CardActions,
  Card,
  CardHeader,
  CardContent,
  Box,
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined, CloudUpload } from "@mui/icons-material";
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { persian2english } from "../../components/ConvertDigits";
import useServices from "../../utils/useServices";

const CreateGallery = (props) => {
  const { handleOpenSnackbar } = useSnackbarContext();
  const { uploadPostMethod } = useServices();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [cover, setCover] = useState([]);
  const [coverError, setCoverError] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date() || null);
  const [selectedDateError, setSelectedDateError] = useState(null);

  const selectedDateMessageError = useMemo(() => {
    switch (selectedDateError) {
      case 'maxDate':
      case 'minDate': {
        return 'تاریخ وارد شده معتبر نمی باشد';
      }

      case 'invalidDate': {
        return 'فرمت تاریخ صحیح نمی باشد';
      }

      default: {
        return '';
      }
    }
  }, [selectedDateError]);

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        cover: cover,
        title: title,
        date: persian2english(selectedDate.toLocaleDateString('fa-IR')),
      };

      await uploadPostMethod("/gallery", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "گالری با موفقیت ثبت شد"
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        setCoverError(ex.response.data.errors.cover);
        setTitleError(ex.response.data.errors.title);
        setSelectedDateError(ex.response.data.errors.date);
      }
    }

    setLoadingStoreButton(true);
  };

  const handleCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setCover(currentValue);
    if (currentValue?.length === 0) {
      setCoverError("تکمیل گزینه تصویر الزامی است");
    } else {
      setCoverError("");
    }
  };

  const handleTitleChange = (e) => {
    const currentValue = e.target.value;
    setTitle(currentValue);
    if (currentValue?.length === 0) {
      setTitleError("تکمیل گزینه عنوان الزامی است");
    } else {
      setTitleError("");
    }
  };

  return (
    <Box mb="50px" mt="50px" className="container">
      <Fade triggerOnce={true}>
        <Card>
          <CardHeader title="افزودن گالری" />
          <CardContent>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <TextField
                  fullWidth
                  type="file"
                  label="تصویر گالری"
                  onChange={handleCoverChange}
                  accept="image/*"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CloudUpload />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(coverError)}
                  helperText={coverError ? coverError : "390px × 250px"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                  autoComplete="off"
                  label="عنوان"
                  required
                  inputProps={{ maxLength: 50 }}
                  error={Boolean(titleError)}
                  helperText={
                    titleError ? titleError : `${title?.length}/50`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <LocalizationProvider dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: "انصراف",
                    okButtonLabel: "تایید",
                    todayButtonLabel: "امروز",
                    clearButtonLabel: "پاک کردن"
                  }}
                >
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="تاریخ"
                    // minDate={new Date()}
                    // maxDate={new Date("2042-02-08")}
                    defaultValue={new Date() || null}
                    value={selectedDate}
                    inputFormat="YYYY/MM/DD"
                    views={["day", "month", "year"]}
                    onChange={(currentValue) => { setSelectedDate(currentValue) }}
                    onError={(newError) => setSelectedDateError(newError)}
                    slotProps={{
                      actionBar: { actions: ['clear', 'today'] },
                      textField: {
                        helperText: selectedDateMessageError,
                      },
                      field: { clearable: true },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{
            justifyContent: "flex-end",
          }}>
            <UploadProgressBar />
            <LoadingButton
              variant="outlined"
              loadingPosition="start"
              size="large"
              loading={!loadingStoreButton}
              disabled={!title || !selectedDate || !cover}
              startIcon={<SaveOutlined />}
              onClick={handleStore}
            >
              ذخیره
            </LoadingButton>
          </CardActions>
        </Card>
      </Fade>
    </Box>
  );
};

export default CreateGallery;
