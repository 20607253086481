import { useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import SettingsContext from "../context/SettingsContext";
import CoursesContext from "../context/CoursesContext";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, Button, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import { tokens } from "../theme";
import logo from "../images/Master-It.png";
import behpardakht from "../images/Behpardakht.png";

export default function Footer() {
  const { getSettings, settings } = useContext(SettingsContext);
  const { getFooterCourses, footerCourses } = useContext(CoursesContext);
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getSettings();
    getFooterCourses();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ background: "#02182d", padding: "30px 15px" }}>
      <Box mb="50px" mt="50px" className="container">
        <Grid container spacing={{ xs: 2, sm: 2, md: 4, lg: 6, xl: 6 }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Fade triggerOnce={true}>
              <Box
                sx={{
                  marginBottom: {
                    xs: "20px",
                    sm: "20px",
                    md: "40px",
                    lg: "40px",
                    xl: "40px",
                  },
                  textAlign: "center"
                }}
              >
                <Box sx={{ marginBottom: "10px", textAlign: "center" }}>
                  <Link to="/">
                    <img
                      src={logo}
                      alt="masterit-logo"
                      style={{ marginBottom: "10px", maxWidth: "200px" }}
                    />
                  </Link>
                  <Typography
                    sx={{
                      color: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500],
                      marginBottom: "30px",
                      fontWeight: "700",
                      fontSize: "20px"
                    }}
                  >
                    {settings.slogen || ""}
                  </Typography>
                  <Typography sx={{ color: "#a2a2a2" }}>
                    {settings.address || ""}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    marginBottom: {
                      xs: "30px",
                      sm: "30px",
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                  }}
                >
                  <Fab
                    sx={{
                      background: "#03213f",
                      color: "#fff",
                    }}
                    component={Link} to={settings.instagram || ""}
                    target="_blank"
                    size="medium"
                    color="primary"
                    aria-label="instagram"
                  >
                    <InstagramIcon />
                  </Fab>
                  <Fab
                    sx={{
                      background: "#03213f",
                      color: "#fff",
                      marginLeft: "15px",
                    }}
                    component={Link} to={settings.linkedin || ""}
                    target="_blank"
                    size="medium"
                    color="primary"
                    aria-label="linked-in"
                  >
                    <LinkedInIcon />
                  </Fab>
                </Box>
              </Box>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Fade triggerOnce={true}>
              <Box
                sx={{
                  marginBottom: {
                    xs: "20px",
                    sm: "20px",
                    md: "40px",
                    lg: "40px",
                    xl: "40px",
                  },
                }}
              >
                <Typography variant="h4" sx={{ color: colors.white, textAlign: "center" }}>
                  خدمات مشتریان
                </Typography>
                <ul style={{ textAlign: "center" }}>
                  <li style={{ marginBottom: "10px", marginTop: "30px" }}>
                    <NavLink to="/events" style={{ color: "#a2a2a2" }}>
                      رویدادها
                    </NavLink>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <NavLink to="/courses" style={{ color: "#a2a2a2" }}>
                      دوره های آموزشی
                    </NavLink>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <NavLink to="/faq" style={{ color: "#a2a2a2" }}>
                      سؤالات متداول
                    </NavLink>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <NavLink to="/privacy" style={{ color: "#a2a2a2" }}>
                      قوانین و مقررات
                    </NavLink>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <NavLink to="/contact" style={{ color: "#a2a2a2" }}>
                      تماس با ما
                    </NavLink>
                  </li>
                </ul>
              </Box>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Fade triggerOnce={true}>
              <Box
                sx={{
                  marginBottom: {
                    xs: "20px",
                    sm: "20px",
                    md: "40px",
                    lg: "40px",
                    xl: "40px",
                  },
                }}
              >
                <Typography variant="h4" sx={{ color: colors.white, textAlign: "center" }}>
                  مروری بر دوره ها
                </Typography>
                <ul style={{ textAlign: "center" }}>
                  {footerCourses?.map((course, index) => (
                    index === 0 ? (
                      <li key={index} style={{ marginBottom: "10px", marginTop: "30px" }}>
                        <Typography style={{ color: "#a2a2a2" }}>
                          {course.course_name}
                        </Typography>
                      </li>
                    ) : (
                      <li key={index} style={{ marginBottom: "10px" }}>
                        <Typography style={{ color: "#a2a2a2" }}>
                          {course.course_name}
                        </Typography>
                      </li>
                    )
                  ))}
                </ul>
              </Box>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Fade triggerOnce={true}>
              <Box
                sx={{
                  marginBottom: {
                    xs: "20px",
                    sm: "20px",
                    md: "40px",
                    lg: "40px",
                    xl: "40px",
                  },
                }}
              >
                <Typography variant="h4" sx={{ color: colors.white, textAlign: "center" }}>
                  اعتبار سایت
                </Typography>
                <Grid container rowSpacing={2} columnSpacing={1} sx={{ textAlign: "center", marginBottom: "10px", marginTop: "30px" }}>
                  <Grid item xs={6} sm={6} md={6} lg={12} xl={12}>
                    <NavLink referrerPolicy="origin" target="_blank" to="https://trustseal.enamad.ir/?id=511337&Code=ys1Nr8G4TcgDO3btVguvg4Wl91yHSfLa">
                      <img
                        referrerPolicy="origin"
                        src="https://trustseal.enamad.ir/logo.aspx?id=511337&Code=ys1Nr8G4TcgDO3btVguvg4Wl91yHSfLa"
                        alt="لوگو اینماد"
                        title="لوگو اینماد"
                        code="ys1Nr8G4TcgDO3btVguvg4Wl91yHSfLa"
                        style={{
                          width: isMobile ? "110px" : "120px",
                        }}
                      />
                    </NavLink>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={12} xl={12}>
                    <NavLink target="_blank" to="https://www.bankmellat.ir/">
                      <img
                        src={behpardakht}
                        title="بانک ملت"
                        alt="بانک ملت"
                        style={{
                          width: isMobile ? "110px" : "120px",
                        }}
                      />
                    </NavLink>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
          </Grid>
        </Grid>
        <Fade triggerOnce={true}>
          <Box
            sx={{
              background: "#03213f",
              borderradius: "10px 10px 0 0",
              padding: {
                xs: "30px 15px",
                sm: "30px 15px",
                md: "30px 20px",
                lg: "30px 20px",
                xl: "30px 20px",
              },
              marginTop: "20px",
            }}
          >
            <Box mb="50px" mt="50px" className="container">
              <Grid container spacing={{ xs: 2, sm: 2, md: 4, lg: 6, xl: 6 }}>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Box
                    sx={{
                      borderRight: {
                        xs: "none",
                        sm: "none",
                        md: `1px solid ${tokens(mode).grey[500]} !important`,
                        lg: `1px solid ${tokens(mode).grey[500]} !important`,
                        xl: `1px solid ${tokens(mode).grey[500]} !important`,
                      },

                      marginTop: { xs: "5px", sm: "5px", md: "5px", lg: "5px", xl: "5px" },
                      paddingTop: { xs: "5px", sm: "5px", md: "5px", lg: "5px", xl: "5px" },
                    }}
                  >
                    <Box
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "196px",
                          md: "196px",
                          lg: "196px",
                          xl: "196px",
                        },
                        position: "relative",
                        marginBottom: 0,
                      }}
                    >
                      <Typography sx={{ textAlign: "center", color: "#a2a2a2" }}>
                        2024 © کلیه حقوق مادی و معنوی این سایت محفوظ و متعلق به&nbsp;
                        <NavLink
                          to="https://www.masteriteducation.com/"
                          target="_blank"
                          style={{
                            color: colors.blueAccent[500],
                          }}
                        >
                          master it
                        </NavLink>
                        &nbsp;می باشد
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <Box
                    sx={{
                      borderRight: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: `1px solid ${tokens(mode).grey[500]} !important`,
                        xl: `1px solid ${tokens(mode).grey[500]} !important`,
                      },

                      marginTop: { xs: "5px", sm: "5px", md: "5px", lg: "5px", xl: "5px" },
                      paddingTop: { xs: "5px", sm: "5px", md: "5px", lg: "5px", xl: "5px" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        marginBottom: {
                          xs: "25px",
                          sm: "25px",
                          md: 0,
                          lg: 0,
                          xl: 0,
                        },
                        marginTop: { xs: "10px", sm: 0, md: 0, lg: 0, xl: 0 },
                      }}
                    >
                      <Box sx={{ marginRight: "20px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="43.945"
                          height="50"
                          viewBox="0 0 43.945 50"
                        >
                          <g id="headphones" transform="translate(-31)">
                            <g
                              id="Group_2171"
                              data-name="Group 2171"
                              transform="translate(36.859 20.508)"
                            >
                              <g id="Group_2170" data-name="Group 2170">
                                <path
                                  id="Path_2983"
                                  data-name="Path 2983"
                                  d="M95.395,210A4.4,4.4,0,0,0,91,214.395v11.914a4.395,4.395,0,1,0,8.789,0V214.395A4.4,4.4,0,0,0,95.395,210Z"
                                  transform="translate(-91 -210)"
                                  fill="#2467ec"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_2173"
                              data-name="Group 2173"
                              transform="translate(31 23.669)"
                            >
                              <g id="Group_2172" data-name="Group 2172">
                                <path
                                  id="Path_2984"
                                  data-name="Path 2984"
                                  d="M33.93,243.6a7.268,7.268,0,0,1,.125-1.234A4.386,4.386,0,0,0,31,246.529v6.055a4.386,4.386,0,0,0,3.054,4.163,7.268,7.268,0,0,1-.125-1.234Z"
                                  transform="translate(-31 -242.366)"
                                  fill="#2467ec"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_2175"
                              data-name="Group 2175"
                              transform="translate(48.578 20.508)"
                            >
                              <g id="Group_2174" data-name="Group 2174">
                                <path
                                  id="Path_2985"
                                  data-name="Path 2985"
                                  d="M227.113,210a4.4,4.4,0,0,0-4.395,4.395v11.914a4.4,4.4,0,0,0,4.395,4.395,4.335,4.335,0,0,0,1.259-.206,4.386,4.386,0,0,1-4.189,3.136h-4.664a4.395,4.395,0,1,0,0,2.93h4.664a7.333,7.333,0,0,0,7.324-7.324V214.395A4.4,4.4,0,0,0,227.113,210Z"
                                  transform="translate(-211 -210)"
                                  fill="#2467ec"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_2177"
                              data-name="Group 2177"
                              transform="translate(71.891 23.669)"
                            >
                              <g id="Group_2176" data-name="Group 2176">
                                <path
                                  id="Path_2986"
                                  data-name="Path 2986"
                                  d="M449.722,242.366a7.266,7.266,0,0,1,.125,1.234v11.914a7.266,7.266,0,0,1-.125,1.234,4.386,4.386,0,0,0,3.055-4.163v-6.055A4.386,4.386,0,0,0,449.722,242.366Z"
                                  transform="translate(-449.722 -242.366)"
                                  fill="#2467ec"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_2179"
                              data-name="Group 2179"
                              transform="translate(31)"
                            >
                              <g id="Group_2178" data-name="Group 2178">
                                <path
                                  id="Path_2987"
                                  data-name="Path 2987"
                                  d="M52.973,0A22,22,0,0,0,31,21.973v.037a7.253,7.253,0,0,1,3-1.361,19.02,19.02,0,0,1,37.952,0,7.256,7.256,0,0,1,3,1.361v-.037A22,22,0,0,0,52.973,0Z"
                                  transform="translate(-31)"
                                  fill="#2467ec"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Box>
                      <Box>
                        <Typography sx={{ color: "#a2a2a2" }}>
                          سوالی دارید؟ با ما تماس بگیرید 24/7
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            color: colors.white,
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "18px",
                              lg: "18px",
                              xl: "18px",
                            },
                            fontWeight: "700",
                            fontFamily: "Nunito Sans",
                          }}
                        >
                          <a href={"tel:" + settings.phone1 || ""} style={{ color: "#0a66c2" }}>
                            {settings.phone1 || ""}
                          </a>
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            color: colors.white,
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "18px",
                              lg: "18px",
                              xl: "18px",
                            },
                            fontWeight: "700",
                            fontFamily: "Nunito Sans",
                          }}
                        >
                          <a href={"tel:" + settings.phone2 || ""} style={{ color: "#0a66c2" }}>
                            {settings.phone2 || ""}
                          </a>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                  <Box
                    sx={{
                      position: "relative",
                      marginTop: 1,
                    }}
                  >
                    <TextField
                      fullWidth
                      placeholder="ایمیل خود را وارد کنید"
                      variant="outlined"
                      sx={{
                        background: "#02182d",
                        "& .MuiInputBase-input": {
                          color: "#a2a2a2",
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "70px",
                          width: "100%",
                          padding: "0 20px 0 150px",
                          border: 0,
                          color: colors.white,
                        },
                      }}
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        height: "50px",
                        width: {
                          xs: "130px",
                          sm: "130px",
                          md: "160px",
                          lg: "160px",
                          xl: "160px",
                        },
                        background: colors.blueAccent[500],
                        borderradius: "5px",
                        color: colors.white,
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      اشتراک
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Box>
  );
}
