import Header from '../../components/Header';
import { Box } from "@mui/material";
import ReceiptsTable from './ReceiptsTable';

const AdminReceipts = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل رسیدهای بانکی" subtitle="رسید بانکی اساتید" backToPage="/admin-transactions" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <ReceiptsTable />
      </Box>
    </>
  );
};

export default AdminReceipts;
