import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import AuthContext from "./../context/AuthContext";

const useAxios = () => {
    const navigate = useNavigate();
    const {
        baseURL,
        // tokenKey,
        // dateKey,
        // refreshingTokenDT,
        // setRefreshingTokenDT,
        token,
        // setToken,
        // setUser,
        logout
    } = useContext(AuthContext);

    const axiosInstance = axios.create({
        baseURL,
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });

    axiosInstance.interceptors.request.use(async config => {
        if (token) {
            const user = jwtDecode(token);
            // const isExpired = jwtDecoded.exp * 1000 < Date.now();
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

            if (isExpired) {
                logout();
                // Cancel all the requests to prevent 401 Error
                const controller = new AbortController();
                controller.abort();
            } else {
                // if (refreshingTokenDT) {
                //     const { data: currentDate } = await axios.get(`${baseURL}/current_date`);
                //     var startTime = new Date(refreshingTokenDT);
                //     var endTime = new Date(currentDate);
                //     // const hours = parseInt(Math.abs(endTime - startTime) / (1000 * 60 * 60) % 24);
                //     // console.log("hours", hours);
                //     // const minutes = parseInt(Math.abs(endTime.getTime() - startTime.getTime()) / (1000 * 60) % 60);
                //     // console.log("minutes", minutes);
                //     var seconds = parseInt(Math.abs(endTime.getTime() - startTime.getTime()) / (1000) % 60);

                //     if (seconds > 10) {
                //         try {
                //             const { data: response } = await axios.post(`${baseURL}/token/refresh`, {
                //                 token: token
                //             });

                //             setToken(response.token);
                //             setUser(jwtDecode(response.token));
                //             setRefreshingTokenDT(response.refreshingTokenDT);
                //             localStorage.setItem(tokenKey, response.token);
                //             localStorage.setItem(dateKey, response.refreshingTokenDT);

                //             config.headers.Authorization = `Bearer ${response.token}`;
                //         } catch (err) {
                //             if (err.response.status === 401) {
                //                 // console.log(err);
                //             }
                //         }
                //     }
                // } else {
                //     console.log("refreshingTokenDT is lost!");
                //     logout();
                // }
            }
        }

        return config;
    },
        (error) => {
            return Promise.reject(error);
        });

    axiosInstance.interceptors.response.use(null, (error) => {
        const expectedError =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500;

        if (!expectedError) { // ارورهای 500
            switch (error.response?.status) {
                case 500:
                    // window.location = '/error-500'
                    navigate('/error-500');
                    break;
                case 501:
                    // window.location = '/error-501'
                    navigate('/error-501');
                    break;
                case 502:
                    // window.location = '/error-502'
                    navigate('/error-502');
                    break;
                case 503:
                    // window.location = '/error-503'
                    navigate('/error-503');
                    break;
                case 504:
                    // window.location = '/error-504'
                    navigate('/error-504');
                    break;
                case 505:
                    // window.location = '/error-505'
                    navigate('/error-505');
                    break;
                default:
                    alert("خطای غیر منتظره");
            }
        } else {
            switch (error.response?.status) {
                case 400:
                    // window.location = '/error-400'
                    navigate('/error-400');
                    break;
                case 401:
                    // window.location = '/error-401'
                    navigate('/error-401');
                    break;
                case 402:
                    // window.location = '/error-402'
                    navigate('/error-402');
                    break;
                case 403:
                    // window.location = '/error-403'
                    navigate('/error-403');
                    break;
                case 404:
                    // window.location = '/error-404'
                    navigate('/error-404');
                    break;
                case 405:
                    // window.location = '/error-405'
                    navigate('/error-405');
                    break;
                case 429:
                    // window.location = '/error-429'
                    navigate('/error-429');
                    break;
                default:
                    return Promise.reject(error);
            }
        }
    });

    return axiosInstance;
}

export default useAxios;
