import { useState, useEffect, useContext } from "react";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import TagsContext from "../../context/TagsContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";

const EditTags = (props) => {
  const { putMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const { getTags } = useContext(TagsContext);
  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [tagName, setTagName] = useState("");
  const [tagNameError, setTagNameError] = useState("");

  useEffect(() => {
    fillRow();
    // eslint-disable-next-line
  }, [props?.params]);

  function fillRow() {
    setTagName(props?.params?.tag_name);
    setTagNameError("");
  }

  const handleTagNameChange = (e) => {
    const currentValue = e.target.value;
    setTagName(currentValue);
    if (currentValue?.length === 0) {
      setTagNameError("تکمیل گزینه نام تگ الزامی است");
    } else {
      setTagNameError("");
    }
  };

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    try {
      const obj = {
        tag_name: tagName,
      };

      await putMethod(
        "/tags",
        props?.params?.id,
        obj
      );

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "تگ با موفقیت ویرایش شد"
      );

      getTags();
      handleCloseDialog();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          "رکورد مورد نظر قبلاً حذف شده است"
        );
      }
      if (ex.response && ex.response.status === 422) {
        setTagNameError(ex.response.data.errors.tag_name);
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleResetDialogItems = () => {
    handleCloseDialog();
    fillRow();
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={tagName ?? ""}
            onChange={handleTagNameChange}
            autoComplete="off"
            label="نام تگ"
            required
            inputProps={{ maxLength: 40 }}
            error={Boolean(tagNameError)}
            helperText={
              tagNameError
                ? tagNameError
                : `${tagName.length}/40`
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleResetDialogItems}
        >
          انصراف
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingUpdateButton}
          disabled={!tagName}
          startIcon={<SaveOutlined />}
          onClick={handleUpdate}
        >
          ذخیره
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EditTags;
