import Header from "../../components/Header";
import { Box } from "@mui/material";
import InboxTable from "./InboxTable";

const Inbox = () => {

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="صندوق ورودی" subtitle="ایمیل های دریافت شده" backToPage="" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <InboxTable />
      </Box>
    </>
  );
};

export default Inbox;
