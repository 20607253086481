import { Fade } from "react-awesome-reveal";
import Header from "../../components/Header";
import ShowUserInfo from "../users/ShowUserInfo";
import CreateBankAccounts from "./CreateBankAccounts";
import BankAccountsTable from "./BankAccountsTable";
import { Box } from "@mui/material";

const BankAccounts = () => {
    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="حساب های بانکی استاد" subtitle="مدیریت پنل" backToPage="/instructors" />
            </Box>
            <Box mb="50px" mt="50px" className="container">
                <ShowUserInfo />
            </Box>
            <Box mb="50px" mt="50px" className="container">
                <Fade triggerOnce={true}>
                    <CreateBankAccounts />
                </Fade>
            </Box>
            <Box mb="50px" className="container">
                <Fade triggerOnce={true}>
                    <BankAccountsTable />
                </Fade>
            </Box>
        </>
    );
};

export default BankAccounts;
