import { useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import Header from "../../components/Header";
import { useDialogContext } from "../../context/DialogContext";
import CoursesContext from "./../../context/CoursesContext";
import BlogsContext from "../../context/BlogsContext";
import {
  Box,
  Typography,
  Pagination,
  Grid,
  Divider,
  useMediaQuery,
  Container,
} from "@mui/material";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";
import {
  LibraryAdd,
  LibraryBooks,
  List
} from "@mui/icons-material";
import CreatePost from "./CreatePost";
import { useTheme } from "@emotion/react";
import BlogPost from "./BlogPost";

const useStyles = makeStyles((theme) => ({
  mainbarContainer: {
    paddingTop: theme.spacing(5),
  },
  container: {
    height: "100vh",
    paddingTop: theme.spacing(5),
    color: theme.palette.mode === "light"
      ? `${tokens(theme.palette.mode).black} !important`
      : `${tokens(theme.palette.mode).white} !important`,
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    position: "sticky",
    top: 30,
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.mode === "light"
        ? `${tokens(theme.palette.mode).white} !important`
        : `${tokens(theme.palette.mode).black} !important`,
      color: `${tokens(theme.palette.mode).grey[500]}`,
      // borderRight: "1px solid #ece7e7"
      borderRight: theme.palette.mode === "light"
        ? `1px solid ${tokens(theme.palette.mode).grey[800]} !important`
        : `1px solid ${tokens(theme.palette.mode).grey[700]} !important`,
    }
  },
  info: { margin: "0px 0 10px 0 !important" },
  item: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2),
      cursor: "pointer"
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
    }
  },
  text: {
    fontWeight: "700 !important",
    [theme.breakpoints.down("md")]: {
      display: "none",
    }
  },
  rightBarContainer: {
    paddingTop: theme.spacing(5),
    // position: "sticky",
    // top: 30,
  },
  title: {
    fontSize: "16 !important",
    fontWeight: "700 !important",
    color: `${tokens(theme.palette.mode).grey[500]}`,
  },
}));

const Blog = (props) => {
  const { handleOpenDialog } = useDialogContext();
  const { getCourses, courses } = useContext(CoursesContext);
  const {
    getBlogs,
    getUserBlogs,
    blogs,
    page, setPage,
    from,
    to,
    total,
    lastPage,
    blogsStatus
  } = useContext(BlogsContext);
  const isMountedCourses = useRef(false);
  const classes = useStyles();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const showLeftSideBar = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (!isMountedCourses.current) {
      getCourses();
      isMountedCourses.current = true;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (blogsStatus === "getBlogs") {
      getBlogs();
    } else {
      getUserBlogs();
    }
    // eslint-disable-next-line
  }, [total, page]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="بلاگ" subtitle="سوالات درسی خود را به اشتراک بگذارید" backToPage="/" />
      </Box>
      <Box className="container">
        <Grid container>
          {props?.user ? (
            <>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Container className={classes.container}>
                  <Box className={classes.item} onClick={() => getBlogs()}>
                    <List className={classes.icon} />
                    <Typography className={classes.text}>پست&zwnj;ها</Typography>
                  </Box>
                  <Box className={classes.item} onClick={() => getUserBlogs()}>
                    <LibraryBooks className={classes.icon} />
                    <Typography className={classes.text}>پست&zwnj;های من</Typography>
                  </Box>
                  <Box className={classes.item}
                    onClick={() =>
                      handleOpenDialog(
                        "md", // xs sm md lg xl
                        "پست جدید",
                        <CreatePost getBlogs={getBlogs} />
                      )
                    }
                  >
                    <LibraryAdd className={classes.icon} />
                    <Typography className={classes.text}>پست جدید</Typography>
                  </Box>
                </Container>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={7} xl={7}>
                <Container className={classes.mainbarContainer}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.info}>
                    <Box
                      sx={{
                        backgroundColor: colors.grey[900],
                        padding: "12px",
                        borderRadius: "6px"
                      }}>
                      نمایش {to}-{from} از {total}
                    </Box>
                  </Grid>
                  {blogs?.data?.map((blog, index1) => (
                    <BlogPost key={index1} blog={blog} />
                  ))}
                  <Box sx={{ justifyContent: "center", display: "flex" }}>
                    {lastPage !== 1 && (
                      <Pagination count={lastPage} onChange={(e, value) => setPage(value)} />
                    )}
                  </Box>
                </Container>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
              <Container className={classes.mainbarContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.info}>
                  <Box
                    sx={{
                      backgroundColor: colors.grey[900],
                      padding: "12px",
                      borderRadius: "6px"
                    }}>
                    نمایش {to}-{from} از {total}
                  </Box>
                </Grid>
                {blogs?.data?.map((blog, index1) => (
                  <BlogPost key={index1} blog={blog} />
                ))}
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  {lastPage !== 1 && (
                    <Pagination count={lastPage} onChange={(e, value) => setPage(value)} />
                  )}
                </Box>
              </Container>
            </Grid>
          )}
          {showLeftSideBar && (
            <Grid item lg={3} xl={3}>
              <Container className={classes.rightBarContainer}>
                {courses?.length !== 0 &&
                  <>
                    <Typography className={classes.title} gutterBottom>دوره های آموزشی</Typography>
                    <Divider flexItem sx={{ margin: "5px 0 5px 0" }} />
                    {courses?.map((course, index3) => (
                      <Box key={index3}>
                        <NavLink to={`/course/show/${course?.id}`}>
                          <Fade triggerOnce={true}>
                            <Typography
                              variant="h6"
                              sx={{
                                color: mode === "light"
                                  ? colors.black
                                  : colors.white,
                              }}
                            >
                              {course?.course_name}
                            </Typography>
                          </Fade>
                        </NavLink>
                        <Divider flexItem sx={{ margin: "5px 0 5px 0" }} />
                      </Box>
                    ))}
                  </>
                }
              </Container>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Blog;
