import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import Header from "../../components/Header";
import SettingsContext from './../../context/SettingsContext';
import TransactionsContext from './../../context/TransactionsContext';
import AuthContext from "../../context/AuthContext";
import {
  Typography,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  Box
} from "@mui/material";
import logo from "./../../images/Master-It.png";
import { separateNumbers } from "../../components/PriceFormat";
import {
  Email,
  Instagram,
  LinkedIn,
  LocationOn,
  MobileFriendly,
  Phone,
} from "@mui/icons-material";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";
import InvoiceItems from "../invoices/InvoiceItems";

const useStyles = makeStyles((theme) => ({
  header: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 20px 30px",
    marginBottom: "25px"
  },
  content: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 30px 20px",
    marginBottom: "25px"
  },
  headerRightSidebar: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "left",
    },
    fontWeight: "700",
  },
  footer: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 0 30px",
  }
}));

const ShowTransactions = () => {
  const { id: transactionId } = useParams();
  const {
    getTransactionById,
    transaction
  } = useContext(TransactionsContext);
  const { getSettings, settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const classes = useStyles();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getSettings();
      getTransactionById(transactionId);
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [transactionId]);

  useEffect(() => {
    function calcTotalCourses() {
      var price = 0;
      var total = 0;
      transaction?.courses?.forEach((item) => {
        price = price + parseInt(item?.pivot?.price);
        total = total + parseInt(item?.pivot?.total);
      });
      setPrice(price);
      setTotal(total);
    }

    calcTotalCourses();
  }, [transaction]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="فاکتور فروش مشتری" subtitle="مشاهده فاکتور" backToPage={user?.role?.role_key === "admin" ? "/admin-transactions" : "/invoices"} />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Fade triggerOnce={true}>
          <Box>
            {isMobile ? (
              <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box
                    component="img"
                    sx={{
                      maxWidth: "150px",
                      display: "block",
                      margin: "0 auto 10px auto",
                    }}
                    alt="Master it"
                    src={logo}
                  />
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "700",
                      fontSize: "16px",
                      margin: "0 0 30px 0",
                      color: colors.orangeAccent[500]
                    }}>
                    {settings?.slogen}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box
                    component="div"
                    className={classes.headerRightSidebar}>
                    آقا/خانم: {transaction?.user?.full_name ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headerRightSidebar}>
                    شماره فاکتور: {transaction?.order_id ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headerRightSidebar}>
                    رسید پرداخت: {transaction?.payment_receipt ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headerRightSidebar}>
                    تاریخ: {transaction?.created_at ?? ""}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box
                    component="div"
                    className={classes.headerRightSidebar}>
                    آقا/خانم: {transaction?.user?.full_name ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headerRightSidebar}>
                    شماره فاکتور: {transaction?.order_id ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headerRightSidebar}>
                    رسید پرداخت: {transaction?.payment_receipt ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headerRightSidebar}>
                    تاریخ: {transaction?.created_at ?? ""}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box
                    component="img"
                    sx={{
                      maxWidth: "150px",
                      display: "block",
                      margin: "0 auto 10px auto",
                    }}
                    alt="Master it"
                    src={logo}
                  />
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "700",
                      fontSize: "16px",
                      margin: "0 0 30px 0",
                      color: colors.orangeAccent[500]
                    }}>
                    {settings?.slogen}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            )}
            <Grid container rowSpacing={1} columnSpacing={1} className={classes.content}>
              <InvoiceItems courses={transaction?.courses} />
            </Grid>
            <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {isMobile && (
                  <Typography
                    component="span"
                    variant="body1"
                    color="primary"
                    sx={{ fontWeight: "700" }}
                  >
                    جمع کل دوره ها (تومان)
                  </Typography>
                )}
                <TextField
                  label={!isMobile ? "جمع کل دوره ها (تومان)" : ""}
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={separateNumbers(price) + " تومان" ?? ""}
                  inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {isMobile && (
                  <Typography
                    component="span"
                    variant="body1"
                    color="primary"
                    sx={{ fontWeight: "700" }}
                  >
                    جمع نهایی (تومان)
                  </Typography>
                )}
                <TextField
                  label={!isMobile ? "جمع نهایی (تومان)" : ""}
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={separateNumbers(total) + " تومان" ?? ""}
                  inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={1} className={classes.footer}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <strong>آدرس مشتری:&nbsp;</strong>
                  {transaction?.user?.province?.province_name ?? ""}،&nbsp;
                  {transaction?.user?.city?.city_name ?? ""}،&nbsp;
                  {transaction?.user?.address ?? ""}،
                  کدپستی: {transaction?.user?.zip_code ?? ""}،
                  همراه: {transaction?.user?.username ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <LocationOn sx={{ marginRight: "20px" }} />
                  {settings?.address ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <Email sx={{ marginRight: "20px" }} />
                  {settings?.email1 + " | " + settings?.email2 ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <Instagram sx={{ marginRight: "20px" }} />
                  {settings?.instagram ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <LinkedIn sx={{ marginRight: "20px" }} />
                  {settings?.linkedin ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <MobileFriendly sx={{ marginRight: "20px" }} />
                  {settings?.phone1 + " | " + settings?.phone2 ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ marginBottom: "20px" }}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <Phone sx={{ marginRight: "20px" }} />
                  {settings?.tel ?? ""}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Box>
    </>
  );
};

export default ShowTransactions;
