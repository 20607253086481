import { DialogContent, Typography, TextField, Stack, Grid } from "@mui/material";
import { DoneAll } from "@mui/icons-material";

const ShowInbox = (props) => {

  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="row" spacing={1}>
              <DoneAll color="primary" />
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                از:
              </Typography>
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={props?.params?.sender?.full_name ?? ""}
              inputprops={{
                style: { textAlign: "justify" },
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="row" spacing={1}>
              <DoneAll color="primary" />
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                موضوع
              </Typography>
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={props?.params?.subject ?? ""}
              multiline
              rows={3}
              inputprops={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="row" spacing={1}>
              <DoneAll color="primary" />
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                پیام
              </Typography>
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={props?.params?.message ?? ""}
              multiline
              rows={5}
              sx={{ mb: "20px" }}
              inputprops={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default ShowInbox;
