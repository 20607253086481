import Header from '../../components/Header';
import CreateCourses from "./CreateCourses";
import { Box } from "@mui/material";

const AdminCourses = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل دوره ها" subtitle="مدیریت پنل" backToPage="/courses" />
      </Box>
      <CreateCourses />
    </>
  );
};

export default AdminCourses;
