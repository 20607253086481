import { useMemo, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  InputAdornment,
  Box,
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import { CloudUpload, SaveOutlined } from "@mui/icons-material";
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import { persian2english } from "../../components/ConvertDigits";
import useServices from "../../utils/useServices";

const CreateEvents = () => {
  const { handleOpenSnackbar } = useSnackbarContext();
  const { uploadPostMethod } = useServices();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [cover, setCover] = useState([]);
  const [coverError, setCoverError] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date() || null);
  const [selectedDateError, setSelectedDateError] = useState(null);
  const [selectedTime, setSelectedTime] = useState(dayjs() || null);
  const [day, setDay] = useState("");
  const [dayError, setDayError] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const selectedDateMessageError = useMemo(() => {
    switch (selectedDateError) {
      case 'maxDate':
      case 'minDate': {
        return 'تاریخ وارد شده معتبر نمی باشد';
      }

      case 'invalidDate': {
        return 'فرمت تاریخ صحیح نمی باشد';
      }

      default: {
        return '';
      }
    }
  }, [selectedDateError]);

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        cover: cover,
        date: persian2english(selectedDate.toLocaleDateString('fa-IR')),
        day: day,
        time: selectedTime.$H + ":" + selectedTime.$m,
        title: title,
        address: address,
      };

      await uploadPostMethod("/events", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "رویداد با موفقیت ثبت شد"
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.cover &&
          ex.response.data.errors.cover.length > 1) {
          setCoverError(ex.response.data.errors.cover[0]);
        } else {
          setCoverError(ex.response.data.errors.cover);
        }
        setSelectedDateError(ex.response.data.errors.date);
        setDayError(ex.response.data.errors.day);
        setTitleError(ex.response.data.errors.title);
        setAddressError(ex.response.data.errors.address);
      }
    }

    setLoadingStoreButton(true);
  };

  const handleCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setCover(currentValue);
    // console.log(cover);
  };

  const handleDayChange = (e) => {
    const currentValue = e.target.value;
    setDay(currentValue);
  };

  const handleTitleChange = (e) => {
    const currentValue = e.target.value;
    setTitle(currentValue);
    if (currentValue?.length === 0) {
      setTitleError("تکمیل گزینه عنوان الزامی است");
    } else {
      setTitleError("");
    }
  };

  const handleAddressChange = (e) => {
    const currentValue = e.target.value;
    setAddress(currentValue);
    if (currentValue?.length === 0) {
      setAddressError("تکمیل گزینه آدرس الزامی است");
    } else {
      setAddressError("");
    }
  };

  return (
    <Box mb="50px" mt="50px" className="container">
      <Fade triggerOnce={true}>
        <Card>
          <CardHeader title="افزودن رویداد" />
          <CardContent>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  fullWidth
                  type="file"
                  label="تصویر رویداد"
                  onChange={handleCoverChange}
                  accept="image/*"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CloudUpload />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(coverError)}
                  helperText={coverError ? coverError : "810px × 500px"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: "انصراف",
                    okButtonLabel: "تایید",
                    todayButtonLabel: "امروز",
                    clearButtonLabel: "پاک کردن"
                  }}
                >
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="تاریخ"
                    minDate={new Date()}
                    defaultValue={new Date() || null}
                    value={selectedDate}
                    inputFormat="YYYY/MM/DD"
                    views={["day", "month", "year"]}
                    onChange={(currentValue) => { setSelectedDate(currentValue) }}
                    onError={(newError) => setSelectedDateError(newError)}
                    slotProps={{
                      actionBar: { actions: ['clear', 'today'] },
                      textField: {
                        helperText: selectedDateMessageError,
                      },
                      field: { clearable: true },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}
                  localeText={{
                    cancelButtonLabel: "انصراف",
                    okButtonLabel: "تایید",
                    todayButtonLabel: "امروز",
                    clearButtonLabel: "پاک کردن",
                  }}
                >
                  <TimePicker
                    sx={{ width: "100%" }}
                    label="زمان"
                    defaultValue={dayjs() || null}
                    value={selectedTime}
                    onChange={(currentValue) => { setSelectedTime(currentValue) }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <FormControl required fullWidth error={Boolean(dayError)}>
                  <InputLabel id="create-day-label">روز</InputLabel>
                  <Select
                    labelId="create-day-label"
                    value={day}
                    label="روز"
                    onChange={handleDayChange}
                  >
                    <MenuItem value="شنبه">
                      شنبه
                    </MenuItem>
                    <MenuItem value="یکشنبه">
                      یکشنبه
                    </MenuItem>
                    <MenuItem value="دوشنبه">
                      دوشنبه
                    </MenuItem>
                    <MenuItem value="سه شنبه">
                      سه شنبه
                    </MenuItem>
                    <MenuItem value="چهارشنبه">
                      چهارشنبه
                    </MenuItem>
                    <MenuItem value="پنجشنبه">
                      پنجشنبه
                    </MenuItem>
                    <MenuItem value="جمعه">
                      جمعه
                    </MenuItem>
                  </Select>
                  <FormHelperText>{dayError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                  autoComplete="off"
                  label="عنوان"
                  required
                  inputProps={{ maxLength: 100 }}
                  error={Boolean(titleError)}
                  helperText={
                    titleError ? titleError : `${title.length}/100`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={address}
                  onChange={handleAddressChange}
                  autoComplete="off"
                  label="آدرس"
                  required
                  inputProps={{ maxLength: 100 }}
                  error={Boolean(addressError)}
                  helperText={
                    addressError ? addressError : `${address.length}/100`
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{
            justifyContent: "flex-end",
          }}>
            <UploadProgressBar />
            <LoadingButton
              variant="outlined"
              loadingPosition="start"
              size="large"
              loading={!loadingStoreButton}
              disabled={selectedDateMessageError.length > 0 || !day || !title || !address}
              startIcon={<SaveOutlined />}
              onClick={handleStore}
            >
              ذخیره
            </LoadingButton>
          </CardActions>
        </Card>
      </Fade>
    </Box>
  );
};

export default CreateEvents;
