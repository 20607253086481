import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useServices from "../../utils/useServices";
import SettingsContext from "../../context/SettingsContext";
import AuthContext from "../../context/AuthContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { tokens } from "../../theme";
import {
    Container,
    Box,
    Grid,
    useTheme,
    Avatar,
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormHelperText,
    styled,
    Tooltip,
    Fade,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import LoadingButton from "@mui/lab/LoadingButton";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { persian2english } from "../../components/ConvertDigits";

const boxStyle = (theme) => ({
    width: {
        xs: '90%',   // در اندازه موبایل (xs)، عرض 90 درصد
        sm: '70%',   // در اندازه‌های کوچک (sm)، عرض 70 درصد
        md: '50%',   // در اندازه‌های متوسط (md)، عرض 50 درصد
        lg: '35%',   // در اندازه‌های بزرگ (lg)، عرض 35 درصد
        xl: '35%',   // در اندازه‌های بسیار بزرگ (xl)، عرض 35 درصد
    },
    maxWidth: 'sm', // می‌توانید این مقدار را بر اساس نیاز تنظیم کنید
    padding: '20px',
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    /* From https://css.glass */
    background: theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.8)",
    backdropFilter: theme.palette.mode === "light" ? "blur(5px)" : "blur(3px)",
    border: theme.palette.mode === "light" ? "1px solid rgba(0, 0, 0, 0.2)" : "1px solid rgba(255, 255, 255, 0.8)",
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 1500 }} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
        maxWidth: 400,
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
        marginTop: "3px !important",
        marginBottom: "3px !important",
    },
}));

const TemporaryCode = () => {
    const { id: username } = useParams();
    const navigate = useNavigate();
    const { postMethod } = useServices();
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const { baseImageUrl, settings } = useContext(SettingsContext);
    const { newTemporaryCode, seconds, setSeconds } = useContext(AuthContext);
    const { handleOpenSnackbar } = useSnackbarContext();
    const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
    const [temporaryCode, setTemporaryCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
        // eslint-disable-next-line
    }, [seconds]);

    const handleShowNewPassword = () => setShowNewPassword((showNewPass) => !showNewPass);
    const handleShowConfirmPassword = () => setShowConfirmPassword((showConfirmPass) => !showConfirmPass);

    const handleUpdate = async () => {
        setLoadingUpdateButton(false);

        try {
            const obj = {
                username: username,
                temporary_code: temporaryCode,
                new_password: newPassword,
                confirm_password: confirmPassword,
            };

            const response = await postMethod("/temporary/password/update", obj);
            const data = await response?.data;

            if (data?.status === 200) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "success",
                    data.message
                );
                navigate("/login");
            } else {
                alert("Something went wrong!");
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    "کاربر قبلاً حذف شده است"
                );
            }
            if (ex.response && ex.response.status === 422) {
                setNewPasswordError(ex.response.data.errors.new_password);
                setConfirmPasswordError(ex.response.data.errors.confirm_password);
            }
            if (ex.response?.data?.errors?.popup) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    ex.response.data.errors.popup[0]
                );
            }
        }

        setLoadingUpdateButton(true);
    };

    const preventLetters = (to) => {
        const toRegex = /^[0-9]*$/;
        return toRegex.test(to);
    };

    const handleTemporaryCodeChange = (newValue) => {
        if (preventLetters(newValue)) {
            setTemporaryCode(persian2english(newValue));
        } else {
            handleOpenSnackbar(
                "bottom",
                "left",
                "error",
                "رمز موقت وارد شده اشتباه است"
            );
        }
    };

    const handleNewPasswordChange = (e) => {
        const currentValue = e.target.value;
        setNewPassword(persian2english(currentValue));
        if (currentValue?.length === 0) {
            setNewPasswordError("تکمیل گزینه رمز جدید الزامی است");
        } else {
            setNewPasswordError("");
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const currentValue = e.target.value;
        setConfirmPassword(persian2english(currentValue));
        if (currentValue?.length === 0) {
            setConfirmPasswordError("تکمیل گزینه تکرار رمز جدید الزامی است");
        } else {
            setConfirmPasswordError("");
        }
    };

    const handleFocus = () => {
        setTooltipOpen(true);
    };

    const handleBlur = () => {
        setTooltipOpen(false);
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${baseImageUrl + settings.main_background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={boxStyle} borderRadius="20px">
                <Grid container className="top_to_bottom_animation">
                    <Grid item xs={12} sm={12} lg={12}>
                        <Box
                            component="div"
                            style={{
                                marginTop: "20px",
                                margin: "20px",
                            }}
                        >
                            <Container maxWidth="sm">
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Avatar>
                                        <LockOutlined sx={{
                                            color: mode === "light"
                                                ? colors.black
                                                : colors.white,
                                        }} />
                                    </Avatar>
                                </Box>
                                <Box
                                    sx={{
                                        marginTop: "4px",
                                        marginBottom: "35px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography component="h1" variant="h4" sx={{
                                        color: mode === "light"
                                            ? colors.black
                                            : colors.white,
                                    }}>
                                        بازیابی و تغییر رمز عبور
                                    </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <Typography variant="h6">
                                                {seconds > 0 ? (
                                                    <Box>
                                                        زمان باقیمانده: <strong>{seconds}</strong> ثانیه
                                                    </Box>
                                                ) : (
                                                    <Typography
                                                        component="span"
                                                        variant="h6"
                                                    >
                                                        جهت ارسال مجدد رمز موقت
                                                        <Typography
                                                            component="span"
                                                            variant="h6"
                                                            sx={{
                                                                cursor: "pointer",
                                                                fontWeight: 700,
                                                                color: mode === "light"
                                                                    ? colors.blueAccent[500]
                                                                    : colors.orangeAccent[500],
                                                            }}
                                                            onClick={() => newTemporaryCode(username)}
                                                        >
                                                            &nbsp;اینجا&nbsp;
                                                        </Typography>
                                                        کلیک کنید.
                                                    </Typography>
                                                )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <MuiOtpInput length={6} value={temporaryCode} onChange={handleTemporaryCodeChange} TextFieldsProps={{ placeholder: '*' }} style={{ direction: "ltr", textAlign: "left" }} />
                                        {/* <TextField
                                            type="text"
                                            value={temporaryCode ?? ""}
                                            onChange={handleTemporaryCodeChange}
                                            required
                                            autoComplete="off"
                                            fullWidth
                                            label="رمز موقت"
                                            placeholder="رمز موقت را وارد کنید"
                                            variant="outlined"
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: "center" },
                                                maxLength: 6,
                                            }}
                                            error={Boolean(temporaryCodeError)}
                                            helperText={
                                                temporaryCodeError ? temporaryCodeError : `${temporaryCode.length}/6`
                                            }
                                        /> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CustomWidthTooltip
                                            title={
                                                <Box>
                                                    <Box>رمز عبور باید حداقل هشت کاراکتر باشد.</Box>
                                                    <Box>رمز عبور باید حداقل شامل یک حرف بزرگ باشد.</Box>
                                                    <Box>رمز عبور باید حداقل شامل یک حرف کوچک باشد.</Box>
                                                    <Box>رمز عبور باید حداقل شامل یک عدد باشد.</Box>
                                                    <Box>رمز عبور نباید شامل فاصله باشد.</Box>
                                                </Box>
                                            }
                                            open={tooltipOpen}
                                            placement="top-end"
                                        >
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(newPasswordError)}
                                                required
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                            >
                                                <InputLabel htmlFor="newPassword">رمز جدید</InputLabel>
                                                <OutlinedInput
                                                    value={newPassword ?? ""}
                                                    onChange={handleNewPasswordChange}
                                                    autoComplete="off"
                                                    placeholder="رمز جدید را وارد کنید"
                                                    inputProps={{
                                                        min: 0,
                                                        style: { textAlign: "center" },
                                                    }}
                                                    type={showNewPassword ? "text" : "password"}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle new password visibility"
                                                                onClick={handleShowNewPassword}
                                                                edge="end"
                                                            >
                                                                {showNewPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="رمز جدید"
                                                />
                                                {newPasswordError && <FormHelperText>{newPasswordError}</FormHelperText>}
                                            </FormControl>
                                        </CustomWidthTooltip>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl fullWidth variant="outlined" error={Boolean(confirmPasswordError)} required>
                                            <InputLabel htmlFor="confirmPassword">تکرار رمز جدید</InputLabel>
                                            <OutlinedInput
                                                value={confirmPassword ?? ""}
                                                onChange={handleConfirmPasswordChange}
                                                autoComplete="off"
                                                placeholder="تکرار رمز جدید را وارد کنید"
                                                inputProps={{
                                                    min: 0,
                                                    style: { textAlign: "center" },
                                                }}
                                                type={showConfirmPassword ? "text" : "password"}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password confirm visibility"
                                                            onClick={handleShowConfirmPassword}
                                                            edge="end"
                                                        >
                                                            {showConfirmPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="تکرار رمز جدید"
                                            />
                                            {confirmPasswordError && <FormHelperText>{confirmPasswordError}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <LoadingButton
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            sx={{
                                                borderRadius: 28,
                                                color: colors.white,
                                            }}
                                            loading={!loadingUpdateButton}
                                            disabled={
                                                !username ||
                                                temporaryCode.length < 6 ||
                                                !newPassword ||
                                                !confirmPassword
                                            }
                                            onClick={handleUpdate}
                                        >
                                            ثبت
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
};

export default TemporaryCode;
