import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import {
  Typography,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import logo from "../../images/Master-It.png";
import { separateNumbers } from "../../components/PriceFormat";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 20px 30px",
    marginBottom: "25px"
  },
  content: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 30px 20px",
    marginBottom: "25px"
  },
  headerRightSidebar: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "left",
    },
    fontWeight: "700",
  },
}));

const ShowInstructorsInvoices = ({ transaction }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [coursesTotal, setCoursesTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    function calcTotalCourses() {
      var coursesTotal = 0;
      var total = 0;
      transaction?.courses?.forEach((item) => {
        var percentages = 0;
        coursesTotal = coursesTotal + parseInt(item.pivot.price);
        percentages = parseInt(item.pivot.discount) + parseInt(item.pivot.membership_percentage) + parseInt(item.pivot.front_page_percentage);
        total = total + (parseInt(item.pivot.price) - (parseInt(item.pivot.price * percentages) / 100));
      });
      setCoursesTotal(coursesTotal);
      setTotal(total);
    }

    calcTotalCourses();
    // eslint-disable-next-line
  }, [transaction]);

  function invoiceItems(courses) {
    return courses?.map(function (course, index) {
      return (
        <React.Fragment key={index}>
          <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                دوره
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  دوره
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={course?.course_name ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                استاد
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  استاد
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={course?.user?.full_name ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                قیمت (تومان)
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  قیمت (تومان)
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={separateNumbers(course?.pivot?.price) ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                درصد تخفیف
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  تخفیف
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={course?.pivot?.discount + " %" ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                درصد عضویت
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  عضویت
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={course?.pivot?.membership_percentage + " %" ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                درصد صفحه اول
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  صفحه اول
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={course?.pivot?.front_page_percentage + " %" ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                جمع دوره (تومان)
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  جمع دوره (تومان)
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={separateNumbers(parseInt(course?.pivot?.price) - (parseInt(course?.pivot?.price * (parseInt(course?.pivot?.discount) + parseInt(course?.pivot?.membership_percentage) + parseInt(course?.pivot?.front_page_percentage))) / 100)) ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
        </React.Fragment>
      )
    })
  }

  return (
    <Box mb="50px" mt="50px" className="container">
      <Fade triggerOnce={true}>
        <Box>
          {isMobile ? (
            <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box
                  component="img"
                  sx={{
                    maxWidth: "150px",
                    display: "block",
                    margin: "0 auto 10px auto",
                  }}
                  alt="Master it"
                  src={logo}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box
                  component="div"
                  className={classes.headerRightSidebar}>
                  خریدار: {transaction?.user?.full_name ?? ""}
                </Box>
                <Box
                  component="div"
                  className={classes.headerRightSidebar}>
                  شماره فاکتور: {transaction?.order_id ?? ""}
                </Box>
                <Box
                  component="div"
                  className={classes.headerRightSidebar}>
                  تاریخ: {transaction?.created_at ?? ""}
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box
                  component="div"
                  className={classes.headerRightSidebar}>
                  خریدار: {transaction?.user?.full_name ?? ""}
                </Box>
                <Box
                  component="div"
                  className={classes.headerRightSidebar}>
                  شماره فاکتور: {transaction?.order_id ?? ""}
                </Box>
                <Box
                  component="div"
                  className={classes.headerRightSidebar}>
                  تاریخ: {transaction?.created_at ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box
                  component="img"
                  sx={{
                    maxWidth: "150px",
                    display: "block",
                    margin: "0 auto 10px auto",
                  }}
                  alt="Master it"
                  src={logo}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              </Grid>
            </Grid>
          )}
          <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
            {invoiceItems(transaction?.courses)}
          </Grid>
          <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {isMobile && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  جمع کل دوره ها (تومان)
                </Typography>
              )}
              <TextField
                label={!isMobile ? "جمع کل دوره ها (تومان)" : ""}
                fullWidth
                variant="outlined"
                type="text"
                value={separateNumbers(coursesTotal) + " تومان" ?? ""}
                inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {isMobile && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  جمع نهایی (تومان)
                </Typography>
              )}
              <TextField
                label={!isMobile ? "جمع نهایی (تومان)" : ""}
                fullWidth
                variant="outlined"
                type="text"
                value={separateNumbers(total) + " تومان" ?? ""}
                inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
              />
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Box>
  );
};

export default ShowInstructorsInvoices;
