import Header from '../../components/Header';
import CreateGallery from './CreateGallery';
import { Box } from "@mui/material";

const AdminGallery = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل گالری" subtitle="مدیریت پنل" backToPage="/gallery" />
      </Box>
      <CreateGallery />
    </>
  );
};

export default AdminGallery;
