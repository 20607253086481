import { useContext, useEffect, useRef } from "react";
import { Fade } from "react-awesome-reveal";
import CanContext from "../../context/CanContext";
import GalleryContext from "../../context/GalleryContext";
import Card from "@mui/material/Card";
import {
    Avatar,
    Box,
    Skeleton,
    CardMedia,
    CardContent,
    CardHeader,
    CardActionArea
} from "@mui/material";
import AdminGalleryMenu from "./AdminGalleryMenu";

function GalleryContent({ gallery }) {
    const isMounted = useRef(false);
    const {
        baseImageUrl,
        getGalleries,
        loading,
        page,
    } = useContext(GalleryContext);
    const { adminGallery } = useContext(CanContext);

    useEffect(() => {
        if (!isMounted.current) {
            getGalleries();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getGalleries();
        // eslint-disable-next-line
    }, [page]);

    return (
        <Box className="my-animation">
            <Card sx={{ marginBottom: "30px" }}>
                <CardHeader
                    avatar={
                        loading ? (
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                            />
                        ) : (
                            <Fade triggerOnce={true}>
                                {adminGallery === 1 ? (
                                    <AdminGalleryMenu gallery={gallery} />
                                ) : (
                                    <Avatar src={baseImageUrl + gallery.cover} />
                                )}
                            </Fade>
                        )
                    }
                    title={
                        loading ? (
                            <Skeleton variant="text" height={15} width="80%" />
                        ) : (
                            <Fade triggerOnce={true}>
                                <span>{gallery?.title}</span>
                            </Fade>
                        )
                    }
                    subheader={
                        loading ? (
                            <Skeleton variant="text" height={10} width="40%" />
                        ) : (
                            <Fade triggerOnce={true}>
                                <span>{gallery?.date}</span>
                            </Fade>
                        )
                    }
                />
                <CardActionArea>
                    <CardContent>
                        {loading ? (
                            <Skeleton
                                sx={{ height: 250 }}
                                animation="wave"
                                variant="rectangular"
                            />
                        ) : (
                            <Fade triggerOnce={true}>
                                <CardMedia
                                    component="img"
                                    className="zoom-in-animation"
                                    height="250"
                                    image={baseImageUrl + gallery?.cover}
                                    alt={gallery?.title}
                                />
                            </Fade>
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    );
}

export default GalleryContent;
