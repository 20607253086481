import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useServices from "../../utils/useServices";
import SettingsContext from "../../context/SettingsContext";
import InstructorsInvoicesContext from "../../context/InstructorsInvoicesContext";
import { useDialogContext } from "../../context/DialogContext";
import { Button, Stack } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import { CreditScore, ReceiptLong } from "@mui/icons-material";
import ShowInstructorsInvoices from "./ShowInstructorsInvoices";

const InstructorsInvoicesTable = () => {
    const { putMethod } = useServices();
    const navigate = useNavigate();
    const {
        getInstructorsInvoices,
        instructorsInvoices,
        selectedRow, setSelectedRow,
        loading,
        page,
        setPage,
        rows,
        setRows,
        from,
        to,
        total
    } = useContext(InstructorsInvoicesContext);
    const { handleOpenDialog } = useDialogContext();
    const { getCounts } = useContext(SettingsContext);

    useEffect(() => {
        getInstructorsInvoices();
        // eslint-disable-next-line
    }, [rows, total, page]);

    useEffect(() => {
        async function updateIsReadInstructorInvoice() {
            if (selectedRow?.id !== undefined && selectedRow?.is_read === 0) {
                try {
                    await putMethod(
                        "/update_instructor_invoice_count",
                        selectedRow?.id,
                    );

                    getCounts();
                } catch (ex) {

                }
            }
        }

        updateIsReadInstructorInvoice();
        // eslint-disable-next-line
    }, [selectedRow?.id]);

    const columns = [
        {
            field: "id",
            headerName: "ردیف",
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "full_name",
            valueGetter: (params) => params.row?.user?.full_name,
            headerName: "خریدار",
            width: 200,
        },
        {
            field: "payment_receipt",
            headerName: "رسید پرداخت",
            width: 200,
        },
        {
            field: "order_id",
            headerName: "شماره سفارش",
            width: 200,
        },
        {
            field: "created_at",
            headerName: "تاریخ ثبت سفارش",
            width: 150,
        },
        {
            field: "active",
            valueGetter: (params) => params.row.active === 1 ? "موفق" : "ناموفق",
            headerName: "تراکنش",
            width: 140,
        },
        {
            field: "packing",
            valueGetter: (params) => params.row.packing === 1 ? "تکمیل" : "ناتمام",
            headerName: "بسته بندی",
            width: 140,
        },
        {
            field: "sending",
            valueGetter: (params) => params.row.sending === 1 ? "تکمیل" : "ناتمام",
            headerName: "ارسال بسته",
            width: 140,
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "lg", // xs sm md lg xl
                            "فاکتور فروش استاد",
                            <ShowInstructorsInvoices params={selectedRow} />
                        )
                    }
                    variant="outlined"
                    startIcon={<ReceiptLong />}
                    disabled={!selectedRow}
                >
                    فاکتور فروش استاد
                </Button>
                <Button
                    onClick={() => navigate(`/receipt/show/${selectedRow?.id}`)}
                    variant="outlined"
                    startIcon={<CreditScore />}
                    disabled={!selectedRow || selectedRow?.active === 0}
                >
                    وضعیت تسویه با استاد
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={instructorsInvoices}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
            />
        </>
    );
};

export default InstructorsInvoicesTable;