import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import Header from "../../components/Header";
import InstructorsContext from './../../context/InstructorsContext';
import SectionTitle from "../../components/SectionTitle";
import InstructorPost from "./InstructorPost";
import {
  Box,
  Grid,
  Pagination,
  Button
} from "@mui/material";
import { KeyboardDoubleArrowLeftOutlined } from "@mui/icons-material";

const Instructors = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    getInstructors,
    setPage,
    lastPage,
    instructors,
  } = useContext(InstructorsContext);
  const [formatPage, setFormatPage] = useState();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getInstructors();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname === "/instructors") {
      setFormatPage(true);
    } else {
      setFormatPage(false);
    }
    // eslint-disable-next-line
  }, [location]);

  function renderFormatPage() {
    return (
      formatPage ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="اساتید" subtitle="اساتید بین المللی" backToPage="/" />
        </Box>
      ) : (
        <SectionTitle title="اساتید" subtitle="اساتید بین المللی" />
      )
    );
  }

  return (
    <>
      {renderFormatPage()}
      <Fade triggerOnce={true}>
        <Box mb="50px" mt="50px" className="container">
          <Box className="row">
            <Grid container>
              {instructors?.map((instructor, index) => (
                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <InstructorPost instructor={instructor} />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              {formatPage ? (
                lastPage !== 1 && (
                  <Pagination count={lastPage} onChange={(e, value) => setPage(value)} />
                )
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<KeyboardDoubleArrowLeftOutlined />}
                  onClick={() => navigate("/instructors")}
                >
                  مشاهده بیشتر
                </Button>
              )}
            </Box>
          </Box >
        </Box>
      </Fade>
    </>
  );
}

export default Instructors;
