import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useServices from '../../utils/useServices';
import AuthContext from '../../context/AuthContext';
import CartContext from '../../context/CartContext';
import { useSnackbarContext } from '../../context/SnackbarContext';
import CoursesContext from '../../context/CoursesContext';
import {
    Box,
    Grid,
    useTheme,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    ListItemIcon,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
} from "@mui/material";
import {
    LocalOffer,
    LocalPostOffice,
    MonetizationOn,
    CreditScore,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Close,
} from '@mui/icons-material';
import { tokens } from "../../theme";
import { separateNumbers, ex_normalNum } from './../../components/PriceFormat';
import { LoadingButton } from '@mui/lab';

function Row({ row }) {
    const { removeFromCart } = useContext(CartContext);
    const { baseImageUrl } = useContext(CoursesContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {!isMobile && (
                    <TableCell align="center">
                        <img
                            src={baseImageUrl + row?.cover}
                            title={row?.course_name}
                            loading="lazy"
                            style={{
                                cursor: "pointer",
                                borderRadius: "10px",
                                width: "300px",
                            }}
                            alt={row?.course_name}
                        />
                    </TableCell>
                )}
                <TableCell align="center">{row?.course_name}</TableCell>
                <TableCell align="center">
                    {separateNumbers(row?.total)} تومان
                </TableCell>
                <TableCell align="center">
                    <IconButton
                        size="small"
                        onClick={() => removeFromCart(row?.id)}
                    >
                        <Close sx={{ color: "red" }} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ fontWeight: "700" }}>دسته بندی</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: "700" }}>قیمت</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: "700" }}>تخفیف</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: "700" }}>قیمت نهایی</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={row?.id}>
                                        <TableCell align="center">{row?.category.category_name}</TableCell>
                                        <TableCell align="center">
                                            {separateNumbers(row?.price)} تومان
                                        </TableCell>
                                        <TableCell align="center">{row?.discount} درصد</TableCell>
                                        <TableCell align="center">
                                            {separateNumbers(row?.total)} تومان
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const CartPost = () => {
    const { user } = useContext(AuthContext);
    const { cartItems } = useContext(CartContext);
    const { handleOpenSnackbar } = useSnackbarContext();
    const { postMethod } = useServices();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [loadingStoreButton, setLoadingStoreButton] = useState(true);
    const [price, setPrice] = useState();
    const [discount, setDiscount] = useState();
    const [total, setTotal] = useState();

    useEffect(() => {
        const fillSummary = () => {
            var pr = 0;
            var disc = 0;
            var sum = 0;
            cartItems?.forEach((item) => {
                pr = pr + parseInt(ex_normalNum(item.price));
                disc = disc + parseInt(ex_normalNum(item.price) - ex_normalNum(item.total));
                sum = sum + parseInt(ex_normalNum(item.total));
            });
            setPrice(pr);
            setDiscount(disc);
            setTotal(sum);
        }

        fillSummary();
        // eslint-disable-next-line
    }, [cartItems]);

    const handleStore = async () => {
        setLoadingStoreButton(false);

        // چک کردن وضعیت ورود کاربر
        if (user === null) {
            navigate("/login", { replace: true, state: { from: location } });
            setLoadingStoreButton(true);
            return;
        }

        // چک کردن اطلاعات ناقص کاربر
        if (
            !user?.first_name ||
            !user?.last_name ||
            !user?.province_id ||
            !user?.city_id ||
            !user?.address ||
            !user?.zip_code
        ) {
            navigate("/profile");
            handleOpenSnackbar(
                "bottom",
                "left",
                "info",
                "جهت ثبت سفارش تکمیل این فرم الزامی می باشد"
            );
            setLoadingStoreButton(true);
            return;
        }

        // چک کردن سبد خرید
        if (cartItems?.length === 0) {
            handleOpenSnackbar(
                "bottom",
                "left",
                "error",
                "سبد خرید خالی است"
            );
            setLoadingStoreButton(true);
            return;
        }

        // ارسال درخواست به سرور
        try {
            const obj = {
                cartItems: cartItems,
            };
            const response = await postMethod("/transactions", obj);

            // در اینجا می‌توانید عملیات بعدی مانند هدایت به صفحه پرداخت را انجام دهید
            window.location.href = `https://bpm.shaparak.ir/pgwchannel/startpay.mellat?RefId=${response?.data?.reference}`

        } catch (ex) {
            // مدیریت خطا
            if (ex.response?.data?.errors?.message) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    ex.response.data.errors.message
                );
            } else {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    "خطا در برقراری ارتباط با سرور"
                );
            }
        } finally {
            setLoadingStoreButton(true);  // تغییر وضعیت بارگذاری در پایان تابع
        }
    };

    return (
        <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {!isMobile && (
                                    <TableCell align="center" sx={{ fontWeight: "700" }}>تصویر</TableCell>
                                )}
                                <TableCell align="center" sx={{ fontWeight: "700" }}>دوره</TableCell>
                                <TableCell align="center" sx={{ fontWeight: "700" }}>قیمت نهایی</TableCell>
                                <TableCell align="center" sx={{ fontWeight: "700" }}>حذف</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cartItems?.map((item, index) => (
                                <Row key={index} row={item} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    subheader={<ListSubheader sx={{ fontWeight: "700" }}>خلاصه پرداخت</ListSubheader>}
                >
                    <ListItem>
                        <ListItemIcon>
                            <MonetizationOn />
                        </ListItemIcon>
                        <ListItemText>
                            جمع دوره ها
                        </ListItemText>
                        {separateNumbers(price)} تومان
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LocalPostOffice />
                        </ListItemIcon>
                        <ListItemText>
                            هزینه ارسال و بیمه
                        </ListItemText>
                        0 تومان
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LocalOffer />
                        </ListItemIcon>
                        <ListItemText>
                            تخفیف
                        </ListItemText>
                        {separateNumbers(discount)} تومان
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CreditScore />
                        </ListItemIcon>
                        <ListItemText>
                            قابل پرداخت
                        </ListItemText>
                        {separateNumbers(total)} تومان
                    </ListItem>
                    {cartItems?.length !== 0 && (
                        <ListItem>
                            <LoadingButton
                                fullWidth
                                variant="outlined"
                                sx={{
                                    minHeight: "50px",
                                    background: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500],
                                    color: colors.white,
                                    '&:hover': {
                                        backgroundColor: colors.white,
                                        color: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500],
                                    },
                                    fontWeight: "700",
                                    fontSize: "20px"
                                }}
                                loading={!loadingStoreButton}
                                onClick={handleStore}
                            >
                                ورود به درگاه پرداخت
                            </LoadingButton>
                        </ListItem>
                    )}
                </List>
            </Grid>
        </Grid>
    );
};

export default CartPost;
