import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ErrorPage from "../components/ErrorPage";
import { tokens } from "../theme";

const boxStyle = (theme) => ({
  position: "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%"
});

const Error502 = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <Box sx={boxStyle}>
        <Box display="flex" justifyContent="center">
          <Typography sx={{ fontSize: "100px", fontWeight: "700", color: colors.blueAccent[500] }}>
            502
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ErrorPage title="Bad Gateway" subtitle="nginx" />
        </Box>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" onClick={() => navigate("/")}>
            بازگشت به صفحه اصلی
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Error502;
