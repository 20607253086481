import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../context/AuthContext";
import ProvinceContext from "../../context/ProvinceContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { jwtDecode } from "jwt-decode";
import {
    Alert,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "react-avatar-edit";
import useServices from "../../utils/useServices";
import { SaveOutlined } from "@mui/icons-material";

const ProfilePost = () => {
    const {
        tokenKey,
        dateKey,
        setToken,
        user,
        setUser,
        setRefreshingTokenDT,
    } = useContext(AuthContext);
    const {
        getAllProvinces,
        allProvincesloading,
        allProvinces,
        allProvincesStatus
    } = useContext(ProvinceContext);
    const { postMethod, uploadPostMethod } = useServices();
    const { handleOpenSnackbar } = useSnackbarContext();
    const isMountedProvinces = useRef(false);
    const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
    const [loadingProfileButton, setLoadingProfileButton] = useState(true);
    const [preview, setPreview] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [province, setProvince] = useState("");
    const [provinceError, setProvinceError] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");
    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [biography, setBiography] = useState("");
    const [biographyError, setBiographyError] = useState("");

    useEffect(() => {
        if (!isMountedProvinces.current) {
            getAllProvinces();
            isMountedProvinces.current = true;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getCitiesByProvinceId();
        // eslint-disable-next-line
    }, [province]);

    async function getCitiesByProvinceId() {
        setCitiesLoading(true);

        const obj = {
            provinceId: province,
        };
        const { data: citiesByProvinceId } = await postMethod("/province/cities", obj);
        setCities(citiesByProvinceId.cities);

        setCitiesLoading(false);
    }

    useEffect(() => {
        fillProfileItems();
        // eslint-disable-next-line
    }, []);

    function fillProfileItems() {
        setFirstName(user?.first_name);
        setFirstNameError("");
        setLastName(user?.last_name);
        setLastNameError("");
        setProvince(user?.province_id);
        setProvinceError("");
        setCity(user?.city_id);
        setCityError("");
        setAddress(user?.address);
        setAddressError("");
        setZipCode(user?.zip_code);
        setZipCodeError("");
        setBiography(user?.biography);
        setBiographyError("");
    }

    const onClose = () => {
        setPreview(null);
    }

    const onCrop = view => {
        setPreview(view);
    }

    const handleProfile = async () => {
        setLoadingProfileButton(false);

        try {
            const obj = {
                profile: preview
            };

            const { data: response } = await uploadPostMethod(
                "/profile/update",
                obj
            );

            setToken(response.token);
            setUser(jwtDecode(response.token));
            setRefreshingTokenDT(response.refreshingTokenDT);
            localStorage.setItem(tokenKey, response.token);
            localStorage.setItem(dateKey, response.refreshingTokenDT);

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                "تصویر پروفایل تغییر کرد"
            );

            window.location.href = "/profile";

        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    "کاربر قبلاً حذف شده است"
                );
            }
            if (ex.response && ex.response.status === 422) {
                alert("ex.response.data.errors.profile");
            }
        }

        setLoadingProfileButton(true);
    };

    const handleUpdate = async () => {
        setLoadingUpdateButton(false);

        try {
            const obj = {
                first_name: firstName,
                last_name: lastName,
                province_id: province,
                city_id: city,
                address: address,
                zip_code: zipCode,
                biography: biography.length > 0 ? biography : null,
            };

            const { data: response } = await postMethod(
                "/user/update",
                obj
            );

            setToken(response.token);
            setUser(jwtDecode(response.token));
            setRefreshingTokenDT(response.refreshingTokenDT);
            localStorage.setItem(tokenKey, response.token);
            localStorage.setItem(dateKey, response.refreshingTokenDT);

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                "مشخصات کاربر با موفقیت ثبت شد"
            );
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    "کاربر قبلاً حذف شده است"
                );
            }
            if (ex.response && ex.response.status === 422) {
                if (ex.response.data.errors.first_name &&
                    ex.response.data.errors.first_name.length > 1) {
                    setFirstNameError(ex.response.data.errors.first_name[0]);
                } else {
                    setFirstNameError(ex.response.data.errors.first_name);
                }
                if (ex.response.data.errors.last_name &&
                    ex.response.data.errors.last_name.length > 1) {
                    setLastNameError(ex.response.data.errors.last_name[0]);
                } else {
                    setLastNameError(ex.response.data.errors.last_name);
                }
                setAddressError(ex.response.data.errors.address);
                if (ex.response.data.errors.zip_code &&
                    ex.response.data.errors.zip_code.length > 1) {
                    setZipCodeError(ex.response.data.errors.zip_code[0]);
                } else {
                    setZipCodeError(ex.response.data.errors.zip_code);
                }
                setBiographyError(ex.response.data.errors.biography);
            }
        }

        setLoadingUpdateButton(true);
    };

    const handleFirstNameChange = (e) => {
        const currentValue = e.target.value;
        setFirstName(currentValue);
        if (currentValue?.length === 0) {
            setFirstNameError("تکمیل گزینه نام الزامی است");
        } else {
            setFirstNameError("");
        }
    };

    const handleLastNameChange = (e) => {
        const currentValue = e.target.value;
        setLastName(currentValue);
        if (currentValue?.length === 0) {
            setLastNameError("تکمیل گزینه نام خانوادگی الزامی است");
        } else {
            setLastNameError("");
        }
    };

    const handleProvinceChange = (e) => {
        const currentValue = e.target.value;
        setProvince(currentValue);
        if (currentValue?.length === 0) {
            setProvinceError("تکمیل گزینه استان الزامی است");
        } else {
            setProvinceError("");
        }
    };

    const handleCityChange = (e) => {
        const currentValue = e.target.value;
        setCity(currentValue);
        if (currentValue?.length === 0) {
            setCityError("تکمیل گزینه شهر الزامی است");
        } else {
            setCityError("");
        }
    };

    const handleAddressChange = (e) => {
        const currentValue = e.target.value;
        setAddress(currentValue);
        if (currentValue?.length === 0) {
            setAddressError("تکمیل گزینه آدرس الزامی است");
        } else {
            setAddressError("");
        }
    };

    const handleZipCodeChange = (e) => {
        const currentValue = e.target.value;
        setZipCode(currentValue);
        if (currentValue?.length === 0) {
            setZipCodeError("تکمیل گزینه کد پستی الزامی است");
        } else {
            setZipCodeError("");
        }
    };

    const handleBiographyChange = (e) => {
        const currentValue = e.target.value;
        setBiography(currentValue);
        if (currentValue?.length === 0) {
            setBiographyError("تکمیل گزینه زندگی نامه الزامی است");
        } else {
            setBiographyError("");
        }
    };

    return (
        <>
            <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Grid container rowSpacing={1} columnSpacing={1} sx={{ marginBottom: "20px" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
                            <Avatar
                                width={300}
                                height={300}
                                onCrop={onCrop}
                                onClose={onClose}
                                label="انتخاب تصویر"
                                labelStyle={{ fontFamily: "shabnam" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
                            <LoadingButton
                                variant="contained"
                                size="large"
                                loading={!loadingProfileButton}
                                disabled={!preview}
                                onClick={handleProfile}
                            >
                                ویرایش تصویر آواتار
                            </LoadingButton>
                            <UploadProgressBar />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Card>
                        <CardContent>
                            <Grid container rowSpacing={3} columnSpacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Alert severity="warning" sx={{ textAlign: "justify" }}>دوره‌ های شما به نشانی وارد شده ارسال خواهد شد لذا در پر کردن فیلدهای زیر دقت فرمایید. مسئولیت هرگونه مغایرت در ورود اطلاعات بر عهده شما می‌ باشد.</Alert>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={firstName ?? ""}
                                        type="text"
                                        onChange={handleFirstNameChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        label="نام"
                                        placeholder="نام را وارد کنید"
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                        }}
                                        error={Boolean(firstNameError)}
                                        helperText={firstNameError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={lastName ?? ""}
                                        type="text"
                                        onChange={handleLastNameChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        label="نام خانوادگی"
                                        placeholder="نام خانوادگی را وارد کنید"
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                        }}
                                        error={Boolean(lastNameError)}
                                        helperText={lastNameError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl required fullWidth error={Boolean(provinceError)}>
                                        <InputLabel id="province-label">استان</InputLabel>
                                        {allProvincesloading ? (
                                            <Select value="">
                                                <MenuItem>
                                                    <CircularProgress />
                                                </MenuItem>
                                            </Select>
                                        ) : (
                                            <Select
                                                labelId="province-label"
                                                value={allProvincesStatus === 200 && province ? province : ""}
                                                label="استان"
                                                onChange={handleProvinceChange}
                                            >
                                                {allProvinces?.map((item) => (
                                                    <MenuItem key={item?.id} value={item?.id}>
                                                        {item?.province_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                        <FormHelperText>{provinceError}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl required fullWidth error={Boolean(cityError)} disabled={!province}>
                                        <InputLabel id="city-label">شهر</InputLabel>
                                        {citiesLoading ? (
                                            <Select value="">
                                                <MenuItem>
                                                    <CircularProgress />
                                                </MenuItem>
                                            </Select>
                                        ) : (
                                            <Select
                                                labelId="city-label"
                                                value={cities && city ? city : ""}
                                                label="شهر"
                                                onChange={handleCityChange}
                                            >
                                                {cities?.map((item, index) => (
                                                    <MenuItem key={index} value={item?.id}>
                                                        {item?.city_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                        <FormHelperText sx={{ textAlign: "justify" }}>{cityError ? cityError : "چنانچه شهر مورد نظر در لیست نباشد آن را در بخش آدرس بنویسید"}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={address ?? ""}
                                        type="text"
                                        onChange={handleAddressChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        label="آدرس"
                                        placeholder="آدرس را وارد کنید"
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                        }}
                                        error={Boolean(addressError)}
                                        helperText={addressError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={zipCode ?? ""}
                                        type="text"
                                        onChange={handleZipCodeChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        label="کد پستی"
                                        placeholder="کد پستی را وارد کنید"
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                            maxLength: 10,
                                        }}
                                        error={Boolean(zipCodeError)}
                                        helperText={zipCodeError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={biography ?? ""}
                                        onChange={handleBiographyChange}
                                        autoComplete="off"
                                        label="زندگی نامه"
                                        multiline
                                        rows={9}
                                        inputProps={{ style: { textAlign: "justify" }, maxLength: 1000 }}
                                        error={Boolean(biographyError)}
                                        helperText={
                                            biographyError ? biographyError : `${biography?.length}/1000`
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{
                            justifyContent: "flex-end",
                        }}>
                            <LoadingButton
                                variant="outlined"
                                loadingPosition="start"
                                size="large"
                                loading={!loadingUpdateButton}
                                disabled={
                                    !firstName ||
                                    !lastName ||
                                    !province ||
                                    !city ||
                                    !address ||
                                    !zipCode
                                }
                                startIcon={<SaveOutlined />}
                                onClick={handleUpdate}
                            >
                                ثبت مشخصات
                            </LoadingButton>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default ProfilePost;
