import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ProgressBarContextProvider } from './context/ProgressBarContext';
import { SnackbarContextProvider } from './context/SnackbarContext';
import { AuthContextProvider } from './context/AuthContext';
import { CanContextProvider } from './context/CanContext';
import { SettingsContextProvider } from './context/SettingsContext';
import "./index.css";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ProgressBarContextProvider>
        <SnackbarContextProvider>
          <AuthContextProvider>
            <CanContextProvider>
              <SettingsContextProvider>
                <App />
              </SettingsContextProvider>
            </CanContextProvider>
          </AuthContextProvider>
        </SnackbarContextProvider>
      </ProgressBarContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
