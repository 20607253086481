import {
    AutoStories,
    EventOutlined,
    CastForEducationOutlined,
    CollectionsOutlined,
    HelpOutlineOutlined,
    PhoneEnabledOutlined,
    VerifiedUserOutlined,
    AccountCircle,
    LoginOutlined,
    AppRegistrationOutlined,
    LockResetOutlined,
    ShoppingCart,
    TheatersOutlined,
    CreditCardOutlined,
    ManageAccounts,
    PeopleAltOutlined,
    CategoryOutlined,
    SecurityOutlined,
    AccountCircleOutlined,
    Password,
    SendOutlined,
    InboxOutlined,
    Settings,
    Chat,
    SettingsOutlined,
    ReceiptLongOutlined,
    GridViewOutlined,
    HdrStrongOutlined,
    CreditScoreOutlined,
    TagOutlined,
} from '@mui/icons-material';

export const pages = [
    {
        "id": 1,
        "isAccess": 1,
        "icon": <AutoStories />,
        "badge": "",
        "title": "صفحات",
        "path": "pages",
        "children": [
            {
                "id": 2,
                "isAccess": 1,
                "icon": <EventOutlined />,
                "badge": "",
                "title": "رویدادها",
                "path": "events",
                "children": []
            },
            {
                "id": 3,
                "isAccess": 1,
                "icon": <CastForEducationOutlined />,
                "badge": "",
                "title": "اساتید",
                "path": "instructors",
                "children": []
            },
            {
                "id": 4,
                "isAccess": 1,
                "icon": <TheatersOutlined />,
                "badge": "",
                "title": "دوره ها",
                "path": "courses",
                "children": []
            },
            {
                "id": 5,
                "isAccess": 1,
                "icon": <CollectionsOutlined />,
                "badge": "",
                "title": "گالری",
                "path": "gallery",
                "children": []
            },
            {
                "id": 6,
                "isAccess": 1,
                "icon": <HelpOutlineOutlined />,
                "badge": "",
                "title": "سوالات متداول",
                "path": "faq",
                "children": []
            },
            {
                "id": 7,
                "isAccess": 1,
                "icon": <GridViewOutlined />,
                "badge": "",
                "title": "بلاگ",
                "path": "blog",
                "children": []
            },
            {
                "id": 8,
                "isAccess": 1,
                "icon": <PhoneEnabledOutlined />,
                "badge": "",
                "title": "تماس با ما",
                "path": "contact",
                "children": []
            },
            {
                "id": 9,
                "isAccess": 1,
                "icon": <VerifiedUserOutlined />,
                "badge": "",
                "title": "قوانین و مقررات",
                "path": "privacy",
                "children": []
            }
        ]
    },
    {
        "id": 10,
        "isAccess": 1,
        "icon": <AccountCircle />,
        "badge": "",
        "title": "حساب کاربری",
        "path": "account",
        "children": [
            {
                "id": 11,
                "isAccess": 1,
                "icon": <LoginOutlined />,
                "badge": "",
                "title": "ورود",
                "path": "login"
            },
            {
                "id": 12,
                "isAccess": 1,
                "icon": <AppRegistrationOutlined />,
                "badge": "",
                "title": "ایجاد حساب کاربری",
                "path": "register"
            },
            {
                "id": 13,
                "isAccess": 1,
                "icon": <LockResetOutlined />,
                "badge": "",
                "title": "بازیابی رمز عبور",
                "path": "recovery-password"
            }
        ]
    },
    {
        "id": 14,
        "isAccess": 1,
        "icon": <ShoppingCart />,
        "badge": "",
        "title": "فروشگاه",
        "path": "shop",
        "children": [
            {
                "id": 15,
                "isAccess": 1,
                "icon": <TheatersOutlined />,
                "badge": "",
                "title": "دوره ها",
                "path": "courses",
                "children": []
            },
            {
                "id": 16,
                "isAccess": 1,
                "icon": <CreditCardOutlined />,
                "badge": "",
                "title": "کارت",
                "path": "cart",
                "children": []
            },
            {
                "id": 17,
                "isAccess": 0,
                "icon": <HdrStrongOutlined />,
                "badge": "",
                "title": "وضعیت سفارش",
                "path": "order-status",
                "children": []
            },
            {
                "id": 18,
                "isAccess": 0,
                "icon": <ReceiptLongOutlined />,
                "badge": "",
                "title": "فاکتورهای مشتری",
                "path": "invoices",
                "children": []
            },
        ]
    },
    {
        "id": 19,
        "isAccess": 0,
        "icon": <Chat />,
        "badge": "",
        "title": "نامه الکترونیک",
        "path": "student",
        "children": [
            {
                "id": 20,
                "isAccess": 0,
                "icon": <InboxOutlined />,
                "badge": "",
                "title": "صندوق ورودی",
                "path": "inbox",
                "children": []
            },
            {
                "id": 21,
                "isAccess": 0,
                "icon": <SendOutlined />,
                "badge": "",
                "title": "پیام های ارسال شده",
                "path": "sent-emails",
                "children": []
            },
        ]
    },
    {
        "id": 22,
        "isAccess": 0,
        "icon": <Settings />,
        "badge": "",
        "title": "تنظیمات",
        "path": "settings",
        "children": [
            {
                "id": 23,
                "isAccess": 0,
                "icon": <AccountCircleOutlined />,
                "badge": "",
                "title": "پروفایل",
                "path": "profile",
                "children": []
            },
            {
                "id": 24,
                "isAccess": 0,
                "icon": <Password />,
                "badge": "",
                "title": "تغییر رمز عبور",
                "path": "change-password",
                "children": []
            },
        ]
    },
    {
        "id": 25,
        "isAccess": 0,
        "icon": <ManageAccounts />,
        "badge": "",
        "title": "مدیریت",
        "path": "admin",
        "children": [
            {
                "id": 26,
                "isAccess": 0,
                "icon": <PeopleAltOutlined />,
                "badge": "",
                "title": "کاربران",
                "path": "admin-users",
                "children": []
            },
            {
                "id": 27,
                "isAccess": 0,
                "icon": <EventOutlined />,
                "badge": "",
                "title": "رویدادها",
                "path": "admin-events",
                "children": []
            },
            {
                "id": 28,
                "isAccess": 0,
                "icon": <CollectionsOutlined />,
                "badge": "",
                "title": "گالری",
                "path": "admin-gallery",
                "children": []
            },
            {
                "id": 29,
                "isAccess": 0,
                "icon": <HelpOutlineOutlined />,
                "badge": "",
                "title": "سوالات متداول",
                "path": "admin-faq",
                "children": []
            },
            {
                "id": 30,
                "isAccess": 0,
                "icon": <TagOutlined />,
                "badge": "",
                "title": "تگ ها",
                "path": "admin-tags",
                "children": []
            },
            {
                "id": 31,
                "isAccess": 0,
                "icon": <PhoneEnabledOutlined />,
                "badge": "",
                "title": "تماس با ما",
                "path": "admin-contact",
                "children": []
            },
            {
                "id": 32,
                "isAccess": 0,
                "icon": <VerifiedUserOutlined />,
                "badge": "",
                "title": "قوانین و مقررات",
                "path": "admin-privacy",
                "children": []
            },
            {
                "id": 33,
                "isAccess": 0,
                "icon": <CategoryOutlined />,
                "badge": "",
                "title": "دسته بندی دوره ها",
                "path": "admin-categories",
                "children": []
            },
            {
                "id": 34,
                "isAccess": 0,
                "icon": <TheatersOutlined />,
                "badge": "",
                "title": "دوره ها",
                "path": "admin-courses",
                "children": []
            },
            {
                "id": 35,
                "isAccess": 0,
                "icon": <ReceiptLongOutlined />,
                "badge": "",
                "title": "تراکنش ها",
                "path": "admin-transactions",
                "children": []
            },
            {
                "id": 36,
                "isAccess": 0,
                "icon": <CreditScoreOutlined />,
                "badge": "",
                "title": "رسید بانکی اساتید",
                "path": "admin-receipts",
                "children": []
            },
            {
                "id": 37,
                "isAccess": 0,
                "icon": <ReceiptLongOutlined />,
                "badge": "",
                "title": "فاکتورهای استاد",
                "path": "admin-instructors-invoices",
                "children": []
            },
            {
                "id": 38,
                "isAccess": 0,
                "icon": <SettingsOutlined />,
                "badge": "",
                "title": "تنظیمات",
                "path": "admin-settings",
                "children": []
            },
            {
                "id": 39,
                "isAccess": 0,
                "icon": <SecurityOutlined />,
                "badge": "",
                "title": "نقش ها و سطح دسترسی",
                "path": "admin-roles",
                "children": []
            },
        ]
    },
];
