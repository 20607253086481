import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import SeasonContext from "../../context/SeasonContext";
import AdminSeasonsMenu from "./AdminSeasonsMenu";
import {
    Box,
    Card,
    Skeleton,
    CardHeader,
    CardContent,
    Typography,
    CardActionArea,
} from "@mui/material";

const SeasonPost = ({ season }) => {
    const { id: courseId } = useParams();
    const { getSeasonsByCourseId, loading } = useContext(SeasonContext);

    return (
        <Box className="my-animation">
            <Card>
                {loading ? (
                    <>
                        <CardHeader />
                        <CardContent>
                            <Skeleton variant="text" width="200px" height="20px" />
                        </CardContent>
                    </>
                ) : (
                    <>
                        <CardHeader
                            action={
                                loading ? null : (
                                    <AdminSeasonsMenu
                                        season={season}
                                        getSeasonsByCourseId={getSeasonsByCourseId}
                                        courseId={courseId}
                                    />
                                )
                            }
                        />
                        <CardActionArea>
                            <CardContent>
                                <Fade direction="up" triggerOnce={true}>
                                    {season ? (
                                        <Typography sx={{ textAlign: "justify" }}>{season.season_name}</Typography>
                                    ) : (
                                        <Skeleton variant="text" height={20} width="50%" />
                                    )}
                                </Fade>
                            </CardContent>
                        </CardActionArea>
                    </>
                )}
            </Card>
        </Box>
    );
};

export default SeasonPost;
