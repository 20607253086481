import React, { useContext, useEffect, useRef } from "react";
import { Fade } from "react-awesome-reveal";
import CategoryContext from './../../context/CategoryContext';
import CoursesContext from "../../context/CoursesContext";
import {
    useMediaQuery,
    Box,
    Grid,
    InputBase,
    IconButton,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    useTheme,
    CircularProgress
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { tokens } from "../../theme";

function CoursesToolBox() {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const {
        getAllCategories,
        allCategoriesLoading,
        allCategoriesStatus,
        allCategories,
    } = useContext(CategoryContext);
    const {
        getCourses,
        getCoursesByCategoryId,
        getCoursesByTitle,
        selectedCategory,
        setSelectedCategory,
        searchTitle,
        setSearchTitle,
        from,
        to,
        total
    } = useContext(CoursesContext);
    const hideSearchBar = useMediaQuery(theme.breakpoints.down("lg"));

    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            getAllCategories();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    const handleCategoryChange = (event) => {
        if (event.target.value === "0") {
            setSelectedCategory(0);
            getCourses();
        } else {
            getCoursesByCategoryId(event.target.value);
        }
    };

    const handleCloseSearchCourses = () => {
        setSelectedCategory(0);
        setSearchTitle("");
        getCourses();
    };

    return (
        <Grid
            container
            sx={{
                padding: "4px",
                borderradius: "10px",
            }}
        >
            <Grid item xs={6} sm={6} md={6} lg={4} xl={4} sx={{ padding: "4px" }}>
                <Fade triggerOnce={true}>
                    <Box
                        sx={{
                            backgroundColor: colors.grey[900],
                            padding: "8px",
                            borderRadius: "4px"
                        }}>
                        نمایش {to}-{from} از {total}
                    </Box>
                </Fade>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4} xl={4} sx={{ padding: "4px" }}>
                <Box>
                    {allCategoriesLoading ? (
                        <Fade triggerOnce={true}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="category-loading">دسته بندی</InputLabel>
                                <Select value="" labelId="category-loading" label="دسته بندی">
                                    <MenuItem>
                                        <CircularProgress />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Fade>
                    ) : (
                        <Fade triggerOnce={true}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="category-label">دسته بندی</InputLabel>
                                <Select
                                    labelId="category-label"
                                    id="category-small"
                                    name="category-small"
                                    value={selectedCategory}
                                    label="دسته بندی"
                                    onChange={handleCategoryChange}
                                >
                                    <MenuItem value="0">
                                        <em>همه دسته بندی ها</em>
                                    </MenuItem>
                                    {allCategoriesStatus === 200 &&
                                        allCategories?.map((item, index) => (
                                            <MenuItem key={index} value={item?.id}>
                                                {item?.category_name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Fade>
                    )}
                </Box>
            </Grid>
            {hideSearchBar ? (
                <span></span>
            ) : (
                <Grid item lg={4} xl={4} sx={{ padding: "4px" }}>
                    <Fade triggerOnce={true}>
                        <Box
                            display="flex"
                            backgroundColor={colors.grey[900]}
                            borderradius="3px"
                        >
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                onChange={(e) => setSearchTitle(e.target.value)}
                                value={searchTitle}
                                placeholder="جستجو ..."
                                autoComplete="off"
                            />
                            <IconButton type="button" sx={{ p: 1 }} onClick={() => getCoursesByTitle()}>
                                <Search />
                            </IconButton>
                            <IconButton type="button" sx={{ p: 1 }} onClick={handleCloseSearchCourses}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Fade>
                </Grid>
            )}
        </Grid>
    );
}

export default CoursesToolBox;
