import Header from '../../components/Header';
import { Box } from "@mui/material";
import PrivacyTable from "./PrivacyTable";

const AdminPrivacy = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل قوانین و مقررات" subtitle="مدیریت پنل" backToPage="/privacy" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <PrivacyTable />
      </Box>
    </>
  );
};

export default AdminPrivacy;
