import { useState } from "react";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import useServices from "../../utils/useServices";

const CreateFaq = (props) => {
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const { postMethod } = useServices();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [question, setQuestion] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerError, setAnswerError] = useState("");

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        question: question,
        answer: answer,
      };
      await postMethod("/faqs", obj);
      props?.getFaqs();
      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "سوال و جواب با موفقیت ثبت شد"
      );
      resetDialogItems();
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        setQuestionError(ex.response.data.errors.question);
        setAnswerError(ex.response.data.errors.answer);
      }
    }

    setLoadingStoreButton(true);
  };

  const resetDialogItems = () => {
    handleCloseDialog();
    setQuestion("");
    setQuestionError("");
    setAnswer("");
    setAnswerError("");
  };

  const handleQuestionChange = (e) => {
    const currentValue = e.target.value;
    setQuestion(currentValue);
    if (currentValue?.length === 0) {
      setQuestionError("تکمیل گزینه سوال الزامی است");
    } else {
      setQuestionError("");
    }
    // if (!validateEmail(currentValue)) {
    //   setEmailError("Please enter valid email");
    // } else {
    //   setEmailError("");
    // }
  };

  // const validateEmail = (email) => {
  //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   return emailRegex.test(email);
  // };

  const handleAnswerChange = (e) => {
    const currentValue = e.target.value;
    setAnswer(currentValue);
    if (currentValue?.length === 0) {
      setAnswerError("تکمیل گزینه جواب الزامی است");
    } else {
      setAnswerError("");
    }
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={question ?? ""}
            onChange={handleQuestionChange}
            autoComplete="off"
            label="سوال"
            multiline
            rows={3}
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 200 }}
            error={Boolean(questionError)}
            helperText={
              questionError ? questionError : `${question.length}/200`
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={answer ?? ""}
            onChange={handleAnswerChange}
            autoComplete="off"
            label="جواب"
            multiline
            rows={4}
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 300 }}
            error={Boolean(answerError)}
            helperText={answerError ? answerError : `${answer.length}/300`}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={resetDialogItems}
        >
          انصراف
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingStoreButton}
          disabled={!question || !answer}
          startIcon={<SaveOutlined />}
          onClick={handleStore}
        >
          ذخیره
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreateFaq;
