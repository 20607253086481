import React, { useEffect, useState, useRef } from "react";
import { useDialogContext } from "../../context/DialogContext";
import {
    DialogContent,
    Typography,
    TextField,
    Grid,
    useMediaQuery,
    useTheme,
    Button,
    Box,
    DialogActions,
} from "@mui/material";
import logo from "../../images/Master-It.png";
import { separateNumbers } from "../../components/PriceFormat";
import { CancelOutlined, PrintOutlined } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    header: {
        borderRadius: "10px",
        backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
        padding: "20px 30px 20px 30px",
        marginBottom: "25px"
    },
    content: {
        borderRadius: "10px",
        backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
        padding: "20px 30px 30px 20px",
        marginBottom: "25px"
    },
    headerRightSidebar: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            justifyContent: "left",
        },
        fontWeight: "700",
    },
}));

const ShowInstructorsInvoices = (props) => {
    const { handleCloseDialog } = useDialogContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [coursesTotal, setCoursesTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const componentRef = useRef();
    const classes = useStyles();

    useEffect(() => {
        function calcTotalCourses() {
            var coursesTotal = 0;
            var total = 0;
            props?.params?.courses.forEach((item) => {
                var percentages = 0;
                coursesTotal = coursesTotal + parseInt(item.pivot.price);
                percentages = parseInt(item.pivot.discount) + parseInt(item.pivot.membership_percentage) + parseInt(item.pivot.front_page_percentage);
                total = total + (parseInt(item.pivot.price) - (parseInt(item.pivot.price * percentages) / 100));
            });
            setCoursesTotal(coursesTotal);
            setTotal(total);
        }

        calcTotalCourses();
        // eslint-disable-next-line
    }, [props?.params]);

    const handleExportPDF = useReactToPrint({
        content: () => componentRef.current,
    });

    function invoiceItems(courses) {
        return courses?.map(function (course, index) {
            return (
                <React.Fragment key={index}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                دوره
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    دوره
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={course.course_name ?? ""}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                قیمت (تومان)
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    قیمت (تومان)
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={separateNumbers(course.pivot.price) ?? ""}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1.3} lg={1.3} xl={1.3}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                درصد تخفیف
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    تخفیف
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={course.pivot.discount + " %" ?? ""}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1.3} lg={1.3} xl={1.3}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                درصد عضویت
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    عضویت
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={course.pivot.membership_percentage + " %" ?? ""}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1.4} lg={1.4} xl={1.4}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                درصد صفحه اول
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    صفحه اول
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={course.pivot.front_page_percentage + " %" ?? ""}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                جمع دوره (تومان)
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    جمع دوره (تومان)
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={separateNumbers(parseInt(course.pivot.price) - (parseInt(course.pivot.price * (parseInt(course.pivot.discount) + parseInt(course.pivot.membership_percentage) + parseInt(course.pivot.front_page_percentage))) / 100)) ?? ""}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                </React.Fragment>
            )
        })
    }

    return (
        <>
            <DialogContent>
                <Box ref={componentRef}>
                    {isMobile ? (
                        <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "150px",
                                        display: "block",
                                        margin: "0 auto 10px auto",
                                    }}
                                    alt="Master it"
                                    src={logo}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box
                                    component="div"
                                    className={classes.headerRightSidebar}>
                                    خریدار: {props?.params?.user?.full_name ?? ""}
                                </Box>
                                <Box
                                    component="div"
                                    className={classes.headerRightSidebar}>
                                    شماره فاکتور: {props?.params?.order_id ?? ""}
                                </Box>
                                <Box
                                    component="div"
                                    className={classes.headerRightSidebar}>
                                    تاریخ: {props?.params?.created_at ?? ""}
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box
                                    component="div"
                                    className={classes.headerRightSidebar}>
                                    خریدار: {props?.params?.user?.full_name ?? ""}
                                </Box>
                                <Box
                                    component="div"
                                    className={classes.headerRightSidebar}>
                                    شماره فاکتور: {props?.params?.order_id ?? ""}
                                </Box>
                                <Box
                                    component="div"
                                    className={classes.headerRightSidebar}>
                                    تاریخ: {props?.params?.created_at ?? ""}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "150px",
                                        display: "block",
                                        margin: "0 auto 10px auto",
                                    }}
                                    alt="Master it"
                                    src={logo}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
                        {invoiceItems(props?.params?.courses)}
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            {isMobile && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    جمع کل دوره ها (تومان)
                                </Typography>
                            )}
                            <TextField
                                label={!isMobile ? "جمع کل دوره ها (تومان)" : ""}
                                fullWidth
                                variant="outlined"
                                type="text"
                                value={separateNumbers(coursesTotal) + " تومان" ?? ""}
                                inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            {isMobile && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    جمع نهایی (تومان)
                                </Typography>
                            )}
                            <TextField
                                label={!isMobile ? "جمع نهایی (تومان)" : ""}
                                fullWidth
                                variant="outlined"
                                type="text"
                                value={separateNumbers(total) + " تومان" ?? ""}
                                inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    startIcon={<CancelOutlined />}
                    onClick={handleCloseDialog}
                >
                    بستن
                </Button>
                <Button
                    onClick={handleExportPDF}
                    variant="outlined"
                    startIcon={<PrintOutlined />}
                >
                    دانلود / پرینت
                </Button>
            </DialogActions>
        </>
    );
};

export default ShowInstructorsInvoices;