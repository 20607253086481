import { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Header from '../../components/Header';
import TransactionsContext from "../../context/TransactionsContext";
import { Box } from "@mui/material";
import ShowInstructorsInvoices from './ShowInstructorsInvoices';

const InstructorsInvoices = () => {
  const { id: transactionId } = useParams();
  const { getTransactionById, transaction } = useContext(TransactionsContext);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getTransactionById(transactionId);
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [transactionId]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="فاکتور فروش استاد" subtitle="مشاهده فاکتور" backToPage="/admin-transactions" />
      </Box>
      <ShowInstructorsInvoices transaction={transaction} />
    </>
  );
};

export default InstructorsInvoices;
