import React, { useContext, useState } from "react";
import { Fade } from "react-awesome-reveal";
import AuthContext from "../../context/AuthContext";
import CanContext from "../../context/CanContext";
import BlogsContext from "../../context/BlogsContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import useServices from "../../utils/useServices";
import AnswerBlog from "./AnswerBlog";
import AdminBlogMenu from "./AdminBlogMenu";
import {
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Typography,
    Chip,
    Skeleton,
    TextField,
    CardHeader,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { SendOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(5),
    },
    postText: {
        textAlign: "justify"
    },
}));

const BlogPost = ({ blog }) => {
    const classes = useStyles();
    const { postMethod } = useServices();
    const { user } = useContext(AuthContext);
    const { adminBlog } = useContext(CanContext);
    const { loading, getBlogs } = useContext(BlogsContext);
    const { handleOpenSnackbar } = useSnackbarContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [loadingStoreButton, setLoadingStoreButton] = useState(true);
    const [answer, setAnswer] = useState("");
    const [answerError, setAnswerError] = useState("");

    const handleStore = async () => {
        if (user) {
            setLoadingStoreButton(false);

            try {
                const obj = {
                    blog_id: blog?.id,
                    answer: answer,
                };
                await postMethod("/answer_blogs", obj);

                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "success",
                    "پاسخ شما با موفقیت ثبت شد"
                );

                getBlogs();
                handleResetItems();

            } catch (ex) {
                if (ex.response && ex.response.status === 422) {
                    setAnswerError(ex.response.data.errors.answer);
                }
            }

            setLoadingStoreButton(true);
        } else {
            handleOpenSnackbar(
                "bottom",
                "left",
                "info",
                "لطفاً به حساب کاربری خود وارد شوید"
            );
        }
    }

    const handleResetItems = () => {
        setAnswer("");
        setAnswerError("");
    }

    const handleAnswerChange = (e) => {
        const currentValue = e.target.value;
        setAnswer(currentValue);
        if (currentValue?.length === 0) {
            setAnswerError("تکمیل گزینه پاسخ الزامی است");
        } else {
            setAnswerError("");
        }
    };

    return (
        loading ? (
            <Card className={classes.card}>
                <CardActionArea>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            <Skeleton variant="text" />
                        </Typography>
                        <Typography variant="body2" className={classes.postText}>
                            <Skeleton variant="text" sx={{ height: 80 }} />
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions sx={{ float: "right" }}>
                    <Skeleton variant="text" />
                </CardActions>
            </Card>
        ) : (
            <Card className={classes.card}>
                <Fade triggerOnce={true}>
                    <CardHeader
                        sx={{ textAlign: "right" }}
                        title=""
                        action={
                            adminBlog === 1 && (
                                <Fade triggerOnce={true}>
                                    <AdminBlogMenu blog={blog} />
                                </Fade>
                            )
                        }
                    />
                    <CardActionArea>
                        <CardContent>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: "700" }}>{blog?.title}</Typography>
                            <Typography variant="body2" className={classes.postText}>
                                {blog?.details}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ float: "right" }}>
                            <Box dir="ltr">
                                <Stack direction={isMobile ? "column" : "row"}>
                                    {blog?.tags?.map((tag, index2) => (
                                        <Chip
                                            key={index2}
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            label={tag?.tag_name}
                                            sx={{ margin: "2px" }}
                                        />
                                    ))}
                                </Stack>
                            </Box>
                        </CardActions>
                    </CardActionArea>
                    <AnswerBlog answerBlogs={blog?.answer_blogs} />
                    <Box
                        component="form"
                        sx={{ p: '10px' }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            multiline
                            rows={5}
                            placeholder="پاسخ خود را اینجا بنویسید ..."
                            value={answer ?? ""}
                            onChange={handleAnswerChange}
                            autoComplete="off"
                            required
                            inputProps={{
                                'aria-label': 'پاسخ خود را اینجا بنویسید ...',
                                style: { textAlign: "justify" },
                                maxLength: 500
                            }}
                            error={Boolean(answerError)}
                            helperText={
                                answerError
                                    ? answerError
                                    : `${answer.length}/500`
                            }
                        />
                    </Box>
                    <Box
                        component="form"
                        sx={{ p: '0 10px 10px 10px', float: 'right' }}
                    >
                        <LoadingButton
                            fullWidth
                            size="small"
                            color="primary"
                            variant="contained"
                            loadingPosition="start"
                            loading={!loadingStoreButton}
                            disabled={!answer}
                            startIcon={<SendOutlined />}
                            onClick={handleStore}
                        >
                            ارسال
                        </LoadingButton>
                    </Box>
                </Fade>
            </Card>
        )
    );
};

export default BlogPost;
