import Header from './../../components/Header';
import { Box } from "@mui/material";
import SettingsPost from "./SettingsPost";

const AdminSettings = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل تنظیمات" subtitle="مدیریت پنل" backToPage="/" />
      </Box>
      <SettingsPost />
    </>
  );
};

export default AdminSettings;
