export const accessibility = [
    {
        "id": 1,
        "title": "فعال سازی",
    },
    {
        "id": 2,
        "title": "کاربران",
    },
    {
        "id": 3,
        "title": "رویدادها",
    },
    {
        "id": 4,
        "title": "اساتید",
    },
    {
        "id": 5,
        "title": "گالری",
    },
    {
        "id": 6,
        "title": "سوالات متداول",
    },
    {
        "id": 7,
        "title": "بلاگ",
    },
    {
        "id": 8,
        "title": "پاسخ به بلاگ",
    },
    {
        "id": 9,
        "title": "تگ ها",
    },
    {
        "id": 10,
        "title": "تماس با ما",
    },
    {
        "id": 11,
        "title": "قوانین و مقررات",
    },
    {
        "id": 12,
        "title": "دسته بندی دوره ها",
    },
    {
        "id": 13,
        "title": "دوره ها",
    },
    {
        "id": 14,
        "title": "تراکنش ها",
    },
    {
        "id": 15,
        "title": "حساب های بانکی اساتید",
    },
    {
        "id": 16,
        "title": "رسید بانکی اساتید",
    },
    {
        "id": 17,
        "title": "فاکتورهای استاد",
    },
    {
        "id": 18,
        "title": "تنظیمات",
    },
    {
        "id": 19,
        "title": "نقش ها و سطح دسترسی",
    },
];
