import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import useServices from "../../utils/useServices";
import Header from "../../components/Header";
import { useSnackbarContext } from "../../context/SnackbarContext";
import CategoryContext from './../../context/CategoryContext';
import UsersContext from "../../context/UsersContext";
import ShowUserInfo from './../users/ShowUserInfo';
import {
  InputAdornment,
  TextField,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  FormHelperText,
  Box,
  Grid,
  CardContent,
  Card,
  CardHeader,
  CardActions
} from "@mui/material";
import UploadProgressBar from './../../components/UploadProgressBar';
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined, CloudUpload } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditInstructors = () => {
  const { id: instructorId } = useParams();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { getAllCategories, allCategories } = useContext(CategoryContext);
  const { getUserById, user } = useContext(UsersContext);
  const isMountedAllCategories = useRef(false);
  const { putMethod, uploadPostMethod } = useServices();
  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [categories, setCategories] = useState([]);
  const [loadingCoverStore, setLoadingCoverStore] = useState(true);
  const [cover, setCover] = useState([]);
  const [coverError, setCoverError] = useState("");
  const [categoriesError, setCategoriesError] = useState("");
  const [membershipPercentage, setMembershipPercentage] = useState("");
  const [membershipPercentageError, setMembershipPercentageError] = useState("");

  useEffect(() => {
    getUserById(instructorId);
    // eslint-disable-next-line
  }, [instructorId]);

  useEffect(() => {
    fillInstructors();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (!isMountedAllCategories.current) {
      getAllCategories();
      isMountedAllCategories.current = true;
    }
    // eslint-disable-next-line
  }, []);

  function fillInstructors() {
    var list = [];
    user?.categories?.forEach((item) => {
      list.push(item.category_name);
    });
    setCategories(list);
    setCategoriesError("");
    setMembershipPercentage(user?.membership_percentage)
    setMembershipPercentageError("");
  }

  const handleCoverStore = async () => {
    setLoadingCoverStore(false);

    try {
      const obj = {
        cover: cover,
        user_id: instructorId,
      };

      await uploadPostMethod("/instructor/cover", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "تصویر استاد با موفقیت ثبت شد"
      );

    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.cover &&
          ex.response.data.errors.cover.length > 1) {
          setCoverError(ex.response.data.errors.cover[0]);
        } else {
          setCoverError(ex.response.data.errors.cover);
        }
      }
    }

    setLoadingCoverStore(true);
  };

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    const categoriesIDs = [];

    categories.forEach(function (category) {
      const item = allCategories?.find((item) => item.category_name === category);
      categoriesIDs.push(item.id);
    });

    try {
      const obj = {
        categories: categoriesIDs,
        membership_percentage: membershipPercentage
      };

      await putMethod(
        "/instructor/update",
        instructorId,
        obj
      );

      // getInstructors();
      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "مشخصات کاربری استاد با موفقیت ویرایش شد"
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          "رکورد مورد نظر قبلاً حذف شده است"
        );
      }
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.membership_percentage &&
          ex.response.data.errors.membership_percentage.length > 1) {
          setMembershipPercentageError(ex.response.data.errors.membership_percentage[0]);
        } else {
          setMembershipPercentageError(ex.response.data.errors.membership_percentage);
        }
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setCover(currentValue);
    // console.log(cover);
  };

  const handleCategoriesChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategories(
      typeof value === 'string' ? value.split(',') : value,
    );
    if (value.length === 0) {
      setCategoriesError("تکمیل گزینه نوع آموزش الزامی است");
    } else {
      setCategoriesError("");
    }
  };

  const handleMembershipPercentageChange = (e) => {
    const currentValue = e.target.value;
    setMembershipPercentage(currentValue);
    if (currentValue?.length === 0) {
      setMembershipPercentageError("تکمیل گزینه درصد عضویت الزامی است");
    } else {
      setMembershipPercentageError("");
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="تنظیمات استاد" subtitle="مدیریت پنل" backToPage="/instructors" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Box mb="50px">
          <ShowUserInfo />
        </Box>
        <Box mb="50px">
          <Fade triggerOnce={true}>
            <Card>
              <CardHeader title="تغییر تصویر" />
              <CardContent>
                <Grid container rowSpacing={2} columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                    <TextField
                      fullWidth
                      type="file"
                      label="تصویر استاد"
                      onChange={handleCoverChange}
                      accept="image/*"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CloudUpload />
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(coverError)}
                      helperText={coverError ? coverError : "620px × 600px"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <LoadingButton
                      fullWidth
                      variant="outlined"
                      loadingPosition="start"
                      loading={!loadingCoverStore}
                      disabled={cover?.length === 0}
                      startIcon={<SaveOutlined />}
                      onClick={handleCoverStore}
                      sx={{ height: "56px" }}
                    >
                      ذخیره تصویر
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <UploadProgressBar />
              </CardActions>
            </Card>
          </Fade>
        </Box>
        <Box mb="50px">
          <Fade triggerOnce={true}>
            <Card>
              <CardHeader title="ویرایش استاد" />
              <CardContent>
                <Grid container rowSpacing={2} columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControl required fullWidth error={Boolean(categoriesError)}>
                      <InputLabel id="multiple-checkbox-label">نوع آموزش</InputLabel>
                      <Select
                        labelId="multiple-checkbox-label"
                        id="multiple-checkbox"
                        multiple
                        value={categories ?? []}
                        onChange={handleCategoriesChange}
                        input={<OutlinedInput label="نوع آموزش" />}
                        renderValue={(selected) => selected.join('، ')}
                        MenuProps={MenuProps}
                      >
                        {allCategories?.map((item) => (
                          <MenuItem key={item?.id} value={item?.category_name}>
                            <Checkbox checked={categories?.indexOf(item?.category_name) > -1} />
                            <ListItemText primary={item?.category_name} />
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{categoriesError}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      value={membershipPercentage ?? ""}
                      onChange={handleMembershipPercentageChange}
                      autoComplete="off"
                      label="درصد عضویت"
                      required
                      inputProps={{ maxLength: 3 }}
                      error={Boolean(membershipPercentageError)}
                      helperText={membershipPercentageError}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <LoadingButton
                  variant="outlined"
                  loadingPosition="start"
                  size="large"
                  loading={!loadingUpdateButton}
                  disabled={
                    categories.length === 0 ||
                    !membershipPercentage
                  }
                  startIcon={<SaveOutlined />}
                  onClick={handleUpdate}
                >
                  ذخیره
                </LoadingButton>
              </CardActions>
            </Card>
          </Fade>
        </Box>
      </Box>
    </>
  );
};

export default EditInstructors;
