import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import SeasonContext from "../../context/SeasonContext";
import {
  TextField,
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";

const CreateSeasons = () => {
  const { id: courseId } = useParams();
  const { postMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { getSeasonsByCourseId } = useContext(SeasonContext);
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [seasonName, setSeasonName] = useState("");
  const [seasonNameError, setSeasonNameError] = useState("");

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        course_id: courseId,
        season_name: seasonName
      };

      await postMethod("/seasons", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "فصل با موفقیت ثبت شد"
      );

      getSeasonsByCourseId(courseId);

    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.season &&
          ex.response.data.errors.season.length > 1) {
          setSeasonNameError(ex.response.data.errors.season_name[0]);
        } else {
          setSeasonNameError(ex.response.data.errors.season_name);
        }
      }
    }

    setLoadingStoreButton(true);
  };

  const handleSeasonNameChange = (e) => {
    const currentValue = e.target.value;
    setSeasonName(currentValue);
    if (currentValue?.length === 0) {
      setSeasonNameError("تکمیل گزینه نام فصل الزامی است");
    } else {
      setSeasonNameError("");
    }
  };

  return (
    <Box mb="50px" mt="50px" className="container">
      <Box mb="50px">
        <Fade triggerOnce={true}>
          <Card>
            <CardHeader title="افزودن فصل" />
            <CardContent>
              <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={seasonName ?? ""}
                    onChange={handleSeasonNameChange}
                    autoComplete="off"
                    label="نام فصل"
                    required
                    inputProps={{ maxLength: 40 }}
                    error={Boolean(seasonNameError)}
                    helperText={seasonNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <LoadingButton
                    fullWidth
                    variant="outlined"
                    loadingPosition="start"
                    size="large"
                    loading={!loadingStoreButton}
                    disabled={
                      !seasonName
                    }
                    startIcon={<SaveOutlined />}
                    onClick={handleStore}
                    sx={{ height: "56px" }}
                  >
                    ذخیره
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Fade>
      </Box>
    </Box >
  );
};

export default CreateSeasons;
