import Header from '../../components/Header';
import { Box } from "@mui/material";
import RolesTable from "./RolesTable";

const AdminRoles = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="پنل نقش ها و سطح دسترسی" subtitle="مدیریت پنل" backToPage="" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <RolesTable />
      </Box>
    </>
  );
};

export default AdminRoles;
