import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import Header from "../../components/Header";
import EventsContext from "../../context/EventsContext";
import SectionTitle from './../../components/SectionTitle';
import EventsPost from "./EventsPost";
import {
  Box,
  Grid,
  Pagination,
  Button
} from "@mui/material";
import { KeyboardDoubleArrowLeftOutlined } from "@mui/icons-material";

const Events = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    getEvents,
    setPage,
    lastPage,
    events
  } = useContext(EventsContext);
  const [formatPage, setFormatPage] = useState();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getEvents();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname === "/events") {
      setFormatPage(true);
    } else {
      setFormatPage(false);
    }
    // eslint-disable-next-line
  }, [location]);

  function renderFormatPage() {
    return (
      formatPage ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="رویدادها" subtitle="رویدادهای آینده" backToPage="/" />
        </Box>
      ) : (
        <SectionTitle title="رویدادها" subtitle="رویدادهای آینده" />
      )
    );
  }

  return (
    <>
      {renderFormatPage()}
      <Fade triggerOnce={true}>
        <Box mb="50px" mt="50px" className="container">
          <Box className="row">
            <Grid container>
              {events?.map((event, index) => (
                <Grid key={index} item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <EventsPost event={event} />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              {formatPage ? (
                lastPage !== 1 && (
                  <Pagination count={lastPage} onChange={(e, value) => setPage(value)} />
                )
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<KeyboardDoubleArrowLeftOutlined />}
                  onClick={() => navigate("/events")}
                >
                  مشاهده بیشتر
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
};

export default Events;
