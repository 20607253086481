import Header from "../../components/Header";
import ShowUserInfo from "./ShowUserInfo";
import { Box } from "@mui/material";

const ShowUser = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="کاربران" subtitle="مشخصات کاربری" backToPage="/admin-users" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <ShowUserInfo />
      </Box>
    </>
  );
};

export default ShowUser;
