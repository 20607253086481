import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import ErrorPage from "../../components/ErrorPage";

const boxStyle = (theme) => ({
  position: "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%"
});

const TransactionFailed = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const storedMessage = queryParams.get('message');
    if (storedMessage) {
      setMessage(storedMessage);
    }
    // eslint-disable-next-line
  }, [location.search]);

  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <Box sx={boxStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ErrorPage title="خطا" subtitle={message} />
        </Box>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" onClick={() => navigate("/")}>
            بازگشت به صفحه اصلی
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionFailed;
