import { useState } from "react";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import useServices from "../../utils/useServices";

const CreatePrivacy = (props) => {
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const { postMethod } = useServices();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [law, setLaw] = useState("");
  const [lawError, setLawError] = useState("");

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        title: title,
        law: law,
      };
      await postMethod("/privacy", obj);
      props?.getPrivacy();
      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "قانون با موفقیت ثبت شد"
      );
      resetDialogItems();
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        setTitleError(ex.response.data.errors.title);
        setLawError(ex.response.data.errors.law);
      }
    }

    setLoadingStoreButton(true);
  };

  const resetDialogItems = () => {
    handleCloseDialog();
    setTitle("");
    setTitleError("");
    setLaw("");
    setLawError("");
  };

  const handleTitleChange = (e) => {
    const currentValue = e.target.value;
    setTitle(currentValue);
    if (currentValue?.length === 0) {
      setTitleError("تکمیل گزینه عنوان الزامی است");
    } else {
      setTitleError("");
    }
  };

  const handleLawChange = (e) => {
    const currentValue = e.target.value;
    setLaw(currentValue);
    if (currentValue?.length === 0) {
      setLawError("تکمیل گزینه قانون الزامی است");
    } else {
      setLawError("");
    }
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={title ?? ""}
            onChange={handleTitleChange}
            autoComplete="off"
            label="عنوان"
            multiline
            rows={3}
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 200 }}
            error={Boolean(titleError)}
            helperText={
              titleError ? titleError : `${title.length}/200`
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={law ?? ""}
            onChange={handleLawChange}
            autoComplete="off"
            label="قانون"
            multiline
            rows={4}
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 300 }}
            error={Boolean(lawError)}
            helperText={lawError ? lawError : `${law.length}/300`}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={resetDialogItems}
        >
          انصراف
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingStoreButton}
          disabled={!title || !law}
          startIcon={<SaveOutlined />}
          onClick={handleStore}
        >
          ذخیره
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreatePrivacy;
