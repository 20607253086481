import Header from "../../components/Header";
import ShowInstructorInfo from './ShowInstructorInfo';
import { Box } from "@mui/material";

const ShowInstructor = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="اساتید" subtitle="درباره استاد" backToPage="/instructors" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <ShowInstructorInfo />
      </Box>
    </>
  );
};

export default ShowInstructor;
