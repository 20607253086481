import { useState, useEffect } from "react";
import { ExpandLess } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import { tokens } from "../theme";

const useStyles = makeStyles((theme) => ({
  "@keyframes myEffect": {
    "0%": {
      opacity: 1,
      scale: 1,
    },
    "50%": {
      opacity: 0.8,
      scale: 1.5,
    },
    "100%": {
      opacity: 1,
      scale: 1,
    },
  },
  toTop: {
    zIndex: 1050,
    position: "fixed !important",
    bottom: "2vh",
    backgroundColor: `${tokens(theme.palette.mode).blueAccent[500]}!important`,
    color: `${tokens(theme.palette.mode).white}!important`,
    "&:hover, &.Mui-focusVisible": {
      animation: `$myEffect 1.6s ${theme.transitions.easing.easeInOut}`,
    },
    left: "2%",
  },
}));

const BackToTop = ({ showBelow }) => {
  const classes = useStyles();
  const [show, setShow] = useState(showBelow ? false : true);
  const handleScroll = () => {
    if (window.scrollY > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <div>
      {show && (
        <IconButton
          size="large"
          onClick={handleClick}
          className={classes.toTop}
          sx={{
            boxShadow: "inset 0 0 0 2px #dfd8fe",
          }}
        >
          <ExpandLess />
        </IconButton>
      )}
    </div>
  );
};

export default BackToTop;
