import { Fade } from "react-awesome-reveal";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import PrivacyPost from './PrivacyPost';

const Privacy = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="قوانین و مقررات" subtitle="شرایط و ضوابط" backToPage="/" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Fade triggerOnce={true}>
          <PrivacyPost />
        </Fade>
      </Box>
    </>
  );
};

export default Privacy;

