import Header from "../../components/Header";
import { Box } from "@mui/material";
import SentEmailsTable from "./SentEmailsTable";

const SentEmails = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="صندوق خروجی" subtitle="پیام های ارسال شده" backToPage="" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <SentEmailsTable />
      </Box>
    </>
  );
};

export default SentEmails;
