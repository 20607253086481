import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TransactionsContext from "../../context/TransactionsContext";
import { Button, Stack } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import { ReceiptLong } from "@mui/icons-material";

const InvoicesTable = () => {
    const navigate = useNavigate();
    const {
        getUserTransactions,
        userTransactions,
        loading,
        page,
        setPage,
        rows,
        setRows,
        from,
        to,
        total
    } = useContext(TransactionsContext);
    const [selectedRow, setSelectedRow] = useState("");

    useEffect(() => {
        getUserTransactions();
        // eslint-disable-next-line
    }, [rows, total, page]);

    const columns = [
        {
            field: "id",
            headerName: "ردیف",
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "payment_receipt",
            headerName: "رسید پرداخت",
            width: 200,
        },
        {
            field: "order_id",
            headerName: "شماره سفارش",
            width: 200,
        },
        {
            field: "created_at",
            headerName: "تاریخ ثبت سفارش",
            width: 200,
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() => navigate(`/transactions/show/${selectedRow?.id}`)}
                    variant="outlined"
                    startIcon={<ReceiptLong />}
                    disabled={!selectedRow}
                >
                    فاکتور فروش مشتری
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={userTransactions}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
                toolbarFilter={false}
                toolbarExport={false}
            />
        </>
    );
};

export default InvoicesTable;
