import React, { useContext } from "react";
import { Fade } from "react-awesome-reveal";
import ContactContext from "../../context/ContactContext";
import SettingsContext from "../../context/SettingsContext";
import AdminContactMenu from "./AdminContactMenu";
import useServices from "../../utils/useServices";
import {
    Button,
    Box,
    Card,
    CardHeader,
    CardContent,
    CardActionArea,
    Stack,
    CardActions,
} from "@mui/material";
import ContactInfoLoading from "./ContactInfoLoading";
import ContactInfo from "./ContactInfo";

const ContactPost = ({ contact }) => {
    const { putMethod } = useServices();
    const { loading } = useContext(ContactContext);
    const { getCounts } = useContext(SettingsContext);

    async function updateIsReadContact() {
        if (contact?.id !== undefined && contact?.is_read === 0) {
            try {
                await putMethod(
                    "/update_contact_count",
                    contact?.id,
                );
                getCounts();
            } catch (ex) {

            }
        }
    }

    return (
        <Box className="my-animation">
            <Fade>
                <Card sx={{ marginBottom: "30px" }}>
                    <CardHeader
                        action={
                            loading ? null : (
                                <AdminContactMenu contact={contact} />
                            )
                        }
                    />
                    <CardActionArea>
                        {loading ? (
                            <CardContent>
                                <Fade direction="up" triggerOnce={true}>
                                    <Stack direction="column" spacing={2}>
                                        <ContactInfoLoading titleWidth="60%" titleHeight={20} valueWidth="20%" valueHeight={20} />
                                        <ContactInfoLoading titleWidth="20%" titleHeight={20} valueWidth="30%" valueHeight={20} />
                                        <ContactInfoLoading titleWidth="30%" titleHeight={20} valueWidth="60%" valueHeight={20} />
                                        <ContactInfoLoading titleWidth="40%" titleHeight={20} valueWidth="95%" valueHeight={20} />
                                    </Stack>
                                </Fade>
                            </CardContent>
                        ) : (
                            <CardContent>
                                <Fade direction="up" triggerOnce={true}>
                                    <Stack direction="column" spacing={2}>
                                        <ContactInfo title="نام و نام خانوادگی:" value={contact?.full_name} />
                                        <ContactInfo title="ایمیل:" value={contact?.email} />
                                        <ContactInfo title="موضوع:" value={contact?.subject} />
                                        <ContactInfo title="متن پیام:" value={contact?.message} />
                                    </Stack>
                                </Fade>
                            </CardContent>
                        )}
                    </CardActionArea>
                    <CardActions
                        sx={{ justifyContent: "flex-end" }}
                    >
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            disabled={contact?.is_read === 1}
                            onClick={updateIsReadContact}
                        >
                            مشاهده شد
                        </Button>
                    </CardActions>
                </Card>
            </Fade>
        </Box>
    );
};

export default ContactPost;
