import { useState, useEffect } from "react";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import useServices from "../../utils/useServices";

const EditFaq = (props) => {
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const { putMethod } = useServices();
  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [question, setQuestion] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerError, setAnswerError] = useState("");

  useEffect(() => {
    fillRow();
    // eslint-disable-next-line
  }, [props?.params]);

  function fillRow() {
    setQuestion(props?.params.question);
    setQuestionError("");
    setAnswer(props?.params.answer);
    setAnswerError("");
  }

  const handleQuestionChange = (e) => {
    const currentValue = e.target.value;
    setQuestion(currentValue);
    if (currentValue?.length === 0) {
      setQuestionError("تکمیل گزینه سوال الزامی است");
    } else {
      setQuestionError("");
    }
    // if (!validateEmail(currentValue)) {
    //   setEmailError("Please enter valid email");
    // } else {
    //   setEmailError("");
    // }
  };

  // const validateEmail = (email) => {
  //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   return emailRegex.test(email);
  // };

  const handleAnswerChange = (e) => {
    const currentValue = e.target.value;
    setAnswer(currentValue);
    if (currentValue?.length === 0) {
      setAnswerError("تکمیل گزینه جواب الزامی است");
    } else {
      setAnswerError("");
    }
  };

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    try {
      const obj = {
        question: question,
        answer: answer,
      };

      await putMethod(
        "/faqs",
        props?.params.id,
        obj
      );

      props?.getFaqs();
      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        "سوال و جواب با موفقیت ویرایش شد"
      );
      handleCloseDialog();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          "رکورد مورد نظر قبلاً حذف شده است"
        );
      }
      if (ex.response && ex.response.status === 422) {
        setQuestionError(ex.response.data.errors.question);
        setAnswerError(ex.response.data.errors.answer);
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleResetDialogItems = () => {
    handleCloseDialog();
    fillRow();
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={question ?? ""}
            onChange={handleQuestionChange}
            autoComplete="off"
            label="سوال"
            multiline
            rows={3}
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 200 }}
            error={Boolean(questionError)}
            helperText={
              questionError ? questionError : `${question.length}/200`
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={answer ?? ""}
            onChange={handleAnswerChange}
            autoComplete="off"
            label="جواب"
            multiline
            rows={4}
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 300 }}
            error={Boolean(answerError)}
            helperText={answerError ? answerError : `${answer.length}/300`}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleResetDialogItems}
        >
          انصراف
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingUpdateButton}
          disabled={!question || !answer}
          startIcon={<SaveOutlined />}
          onClick={handleUpdate}
        >
          ذخیره
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EditFaq;
