import { useEffect, useState, useContext } from "react";
import InboxContext from "../../context/InboxContext";
import SettingsContext from "../../context/SettingsContext";
import { Button, Stack } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import ComposeDialog from './../../components/ComposeDialog';
import { EditOutlined, DeleteOutline, VisibilityOutlined } from "@mui/icons-material";
import Compose from "./Compose";
import ShowInbox from "./ShowInbox";
import DeleteInbox from "./DeleteInbox";
import useServices from "../../utils/useServices";

const InboxTable = () => {
    const { putMethod } = useServices();
    const {
        getInboxes,
        inboxes,
        selectedRow, setSelectedRow,
        loading,
        rows,
        setRows,
        page,
        setPage,
        from,
        to,
        total
    } = useContext(InboxContext);
    const { getCounts } = useContext(SettingsContext);
    const [maxWidthDialog, setMaxWidthDialog] = useState("sm");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogActions, setDialogActions] = useState("");

    useEffect(() => {
        getInboxes();
        // eslint-disable-next-line
    }, [rows, total, page]);

    useEffect(() => {
        async function updateIsReadInbox() {
            if (selectedRow?.id !== undefined && selectedRow?.pivot?.is_read === 0) {
                try {
                    await putMethod(
                        "/update_inbox_count",
                        selectedRow?.pivot?.id,
                    );
                    getCounts();
                    // Object.assign(selectedRow?.pivot);
                } catch (ex) {

                }
            }
        }

        updateIsReadInbox();
        // eslint-disable-next-line
    }, [selectedRow?.id]);

    const handleOpenDialog = (widthDialog, title, content) => {
        setMaxWidthDialog(widthDialog);
        setDialogTitle(title);
        setDialogActions(content);
        setOpenDialog(true);
    };

    const columns = [
        {
            field: "id",
            headerName: "ردیف",
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "sender",
            valueGetter: (params) => params.row?.sender?.full_name,
            headerName: "از",
            width: 250,
            // Customize Cell
            // renderCell: (params) => {
            //   if (params.row.is_read === 0) {
            //     return (
            //       <strong>{params.value}</strong>
            //     )
            //   }
            // }
        },
        {
            field: "subject",
            headerName: "موضوع",
            width: 300,
        },
        {
            field: "message",
            headerName: "پیام",
            width: 445,
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "ایمیل جدید",
                            <Compose
                                getInboxes={getInboxes}
                                setOpenDialog={setOpenDialog}
                            />
                        )
                    }
                    sx={{
                        borderRadius: "16px",
                        color: "#5f6368",
                        height: "56px",
                        padding: "0 24px 0 24px",
                        minWidth: "96px"
                    }}
                    variant="outlined"
                    startIcon={<EditOutlined />}
                >
                    نوشتن پیام
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "",
                            <DeleteInbox
                                params={selectedRow}
                                getInboxes={getInboxes}
                                setOpenDialog={setOpenDialog}
                            />
                        )
                    }
                    sx={{
                        borderRadius: "16px",
                        color: "#5f6368",
                        height: "56px",
                        padding: "0 24px 0 24px",
                        minWidth: "96px"
                    }}
                    variant="outlined"
                    startIcon={<DeleteOutline />}
                    disabled={!selectedRow}
                >
                    حذف
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "مشاهده پیام",
                            <ShowInbox params={selectedRow} />
                        )
                    }
                    sx={{
                        borderRadius: "16px",
                        color: "#5f6368",
                        height: "56px",
                        padding: "0 24px 0 24px",
                        minWidth: "96px"
                    }}
                    variant="outlined"
                    startIcon={<VisibilityOutlined />}
                    disabled={!selectedRow}
                >
                    مشاهده
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={inboxes}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
                toolbarFilter={false}
                toolbarExport={false}
            />
            <ComposeDialog
                maxWidthDialog={maxWidthDialog} // xs sm md lg xl
                setMaxWidthDialog={setMaxWidthDialog} // xs sm md lg xl
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                dialogTitle={dialogTitle}
                dialogActions={dialogActions}
            />
        </>
    );
};

export default InboxTable;
