import { useContext, useEffect, useState } from "react";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import RoleContext from './../../context/RoleContext';
import UsersContext from "../../context/UsersContext";
import {
    TextField,
    DialogContent,
    DialogActions,
    Grid,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import useServices from "../../utils/useServices";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const UserSettings = (props) => {
    const { handleOpenSnackbar } = useSnackbarContext();
    const { handleCloseDialog } = useDialogContext();
    const { getAllRoles, allRoles } = useContext(RoleContext);
    const { getUsers } = useContext(UsersContext);
    const { putMethod } = useServices();
    const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
    const [roles, setRoles] = useState([]);
    const [rolesError, setRolesError] = useState("");

    useEffect(() => {
        getAllRoles();
        fillRoles();
        // eslint-disable-next-line
    }, [props?.user?.id]);

    function fillRoles() {
        var list = [];
        props?.user?.roles.forEach((item) => {
            list.push(item.role_name);
            setRoles(list);
        });
        setRolesError("");
    }

    const handleResetDialogItems = () => {
        fillRoles();
        handleCloseDialog();
    };

    const handleUpdate = async () => {
        setLoadingUpdateButton(false);

        try {
            const obj = {
                roles: roles,
            };

            await putMethod(
                "/user/roles/update",
                props?.user?.id,
                obj
            );

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                "تنظیمات با موفقیت انجام شد"
            );

            handleCloseDialog();
            getUsers();
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    "رکورد مورد نظر قبلاً حذف شده است"
                );
            }
        }

        setLoadingUpdateButton(true);
    };

    const handleRolesChange = (event) => {
        const {
            target: { value },
        } = event;
        setRoles(
            typeof value === 'string' ? value.split(',') : value,
        );
        if (value.length === 0) {
            setRolesError("تکمیل گزینه نقش الزامی است");
        } else {
            setRolesError("");
        }
    };

    const handleSuspend = (e) => {
        // const currentValue = e.target.value;
    };

    const handleBlackList = (e) => {
        // const currentValue = e.target.value;
    };

    return (
        <>
            <DialogContent>
                <Grid container rowSpacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={props?.user?.full_name}
                            autoComplete="off"
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl required fullWidth error={Boolean(rolesError)}>
                            <InputLabel id="roles-label">نقش</InputLabel>
                            <Select
                                labelId="roles-label"
                                id="roles-multiple-checkbox"
                                multiple
                                value={roles ?? []}
                                onChange={handleRolesChange}
                                input={<OutlinedInput label="نقش" />}
                                renderValue={(selected) => selected.join('، ')}
                                MenuProps={MenuProps}
                            >
                                {allRoles?.map((item) => (
                                    <MenuItem key={item?.id} value={item?.role_name}>
                                        <Checkbox checked={roles.indexOf(item?.role_name) > -1} />
                                        <ListItemText primary={item?.role_name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{rolesError}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                            control={<Switch onClick={handleSuspend} />}
                            label="تعلیق کاربر"
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                            control={<Switch onClick={handleBlackList} />}
                            label="قرار دادن کاربر در لیست سیاه"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    startIcon={<CancelOutlined />}
                    onClick={handleResetDialogItems}
                >
                    انصراف
                </Button>
                <LoadingButton
                    variant="outlined"
                    loadingPosition="start"
                    loading={!loadingUpdateButton}
                    startIcon={<SaveOutlined />}
                    onClick={handleUpdate}
                >
                    ذخیره
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default UserSettings;
