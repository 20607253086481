import { useContext, useEffect, useRef } from "react";
import Header from "../../components/Header";
import SettingsContext from "../../context/SettingsContext";
import AuthContext from "../../context/AuthContext";
import { Box } from "@mui/material";
import DashboardPost from './DashboardPost';

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const { getCounts } = useContext(SettingsContext);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      if (user !== null) {
        getCounts();
      }
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="داشبورد" subtitle="داشبورد" backToPage="" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <DashboardPost />
      </Box>
    </>
  );
};

export default Dashboard;
