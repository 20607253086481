import { Fade } from "react-awesome-reveal";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import FaqPost from "./FaqPost";

const FAQ = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="سوالات متداول" subtitle="سوالاتی که دائم پرسیده می شود" backToPage="/" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Fade triggerOnce={true}>
          <FaqPost />
        </Fade>
      </Box>
    </>
  );
};

export default FAQ;
